import React, { useCallback, useContext } from 'react';

import {
  ResourceList,
  Stack,
  Button,
  Avatar,
  TextStyle,
  DisplayText,
  Tooltip,
} from '@shopify/polaris';
import { HorizontalDotsMinor, DeleteMinor } from '@shopify/polaris-icons';
import { navigate } from '@reach/router';
import { User, Role } from '../../Graph/generatedTypes';
import { AuthContext } from '../AuthGate';
import { noop } from '../../Utils';

interface UserRowProps extends User {
  /**
   * Allows referencing formik value
   */
  index: number;

  /**
   * If the inputs should be disabled
   */
  disabled: boolean;

  /**
   * If this user is currently being deleted
   */
  deleting: boolean;

  /**
   * When the delete button is pressed
   */
  onDelete: (id: string) => void;
}

function UserRow(props: UserRowProps) {
  const { user: loggedInUser } = useContext(AuthContext);

  const { id, email, role, disabled, deleting, onDelete } = props;

  const media = <Avatar customer name={email} />;

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const me = loggedInUser.id === id;
  const dis = disabled || deleting || me;

  return (
    <ResourceList.Item id={id} media={media} onClick={noop}>
      <Stack alignment="center" spacing="extraLoose">
        <Stack.Item>
          <div style={{ minWidth: 200 }}>
            {/* Name will go here */}
            <TextStyle variation="strong">{`${''} ${''}`}</TextStyle>
          </div>
        </Stack.Item>
        <Stack.Item fill>{email}</Stack.Item>
        <Stack.Item>{role === Role.Admin ? 'Admin' : 'Staff'}</Stack.Item>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <DisplayText size="small">
                <Tooltip content={`Edit User ${email}`} active={false} light>
                  <Button
                    icon={HorizontalDotsMinor}
                    accessibilityLabel={`Edit User ${email}`}
                    outline
                    onClick={() => navigate(`/admin/users/${id}`)}
                    disabled={disabled}
                  />
                </Tooltip>
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                <Tooltip
                  content={me ? 'Cannot delete yourself' : 'Delete user'}
                  active={false}
                  light
                >
                  <Button
                    icon={DeleteMinor}
                    accessibilityLabel={`Delete User ${email}`}
                    outline={!deleting}
                    onClick={handleDelete}
                    destructive
                    disabled={dis}
                    loading={deleting}
                  />
                </Tooltip>
              </DisplayText>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </ResourceList.Item>
  );
}

export default UserRow;
