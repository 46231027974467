import React from 'react';

import { TitleCell } from './Cells';

import defaultThumbnail from '../../Assets/default.jpg';
import { BulkProductRow } from '../../Graph/generatedTypes';

interface TitleRendererProps {
  rows: BulkProductRow[];
}

const titleRenderer = ({ rows }: TitleRendererProps) => (y: number) => {
  const { thumbnail, title, type, id } = rows[y];

  return (
    <TitleCell
      id={id}
      type={type}
      title={title}
      thumbnail={thumbnail || defaultThumbnail}
    />
  );
};

export default titleRenderer;
