import React from 'react';
import { FormLayout, Button, Tooltip } from '@shopify/polaris';
import { withFormik, Form, FormikProps } from 'formik';
import { TextField } from '../FormikPolaris';
import VVSCHEMA from './VVSchema';
import { FormValues } from './FieldForm';
import { pick } from 'lodash-es';

const DEFAULT_FIELD_DEFINITION: FormValues = {
  viceversa_id: undefined,
  instock_threshold: undefined,
  backinstock_template_id: '12345'
};

interface FormProps {
  /**
   * Shows the form loading state
   */
  loading?: boolean;

  /**
   * Initial form values
   */
  initialFieldDefinition?: FormValues;

  /**
   * Callback for valid submissions
   */
  onSubmit: (values: FormValues) => void;
  toolTipContent: string;
}

function VVFieldForm(props: FormProps & FormikProps<FormValues>) {
  const {
    loading = false,
    dirty,
    toolTipContent,
  } = props;

  return (
    <Form>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            name="viceversa_id"
            autoComplete={false}
            label="Vice Versa Code"
            placeholder=""
            disabled={loading}
          />
         <TextField
            name="instock_threshold"
            autoComplete={false}
            label="InStock Threshold"
            placeholder=""
            type="number"
            disabled={loading}
          />
          </FormLayout.Group>
        <Tooltip content={toolTipContent} active={false} light>
          <Button submit primary disabled={loading || !dirty}>
            Save
          </Button>
        </Tooltip>
      </FormLayout>
    </Form>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ initialFieldDefinition }) =>
    initialFieldDefinition || DEFAULT_FIELD_DEFINITION,
  handleSubmit: (values, { props }) => {
    const filterValues = pick(values, Object.keys(DEFAULT_FIELD_DEFINITION))
    filterValues.backinstock_template_id = DEFAULT_FIELD_DEFINITION.backinstock_template_id;
    props.onSubmit(filterValues)},
  validationSchema: VVSCHEMA,
  validateOnChange: false,
  validateOnBlur: true,
  enableReinitialize: true,
})(VVFieldForm);
