import Home from './Home';
import Bundles from './Bundles';
import EditBundle from './EditBundle';
import BundlesVerify from './BundlesVerify';
import BundlesNotification from './BundlesNotification';
import NotFound from './NotFound';
import Fulfillment from './Fulfillment';
import ColorSwatch from './ColorSwatch';
import BackInStock from './BackInStock/BackInStock';

const App = {
  Home,
  Bundles,
  EditBundle,
  BundlesVerify,
  BundlesNotification,
  NotFound,
  Fulfillment,
  ColorSwatch,
  BackInStock,
};

export default App;
