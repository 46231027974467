import React, { Component } from 'react';
import { Modal, AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';

import ModalMetaContent from './ModalMetaContent';

interface Option {
  value: string;
  label: string;
}

interface Props {
  open: boolean;
  options: Option[];
  selected: string[];
  onSelect: (fields: string[]) => void;
  onCancel: () => void;
}

interface State {
  selected: string[];
}

/**
 * @todo since this updates the fields it should probably be renamed?
 */
class FulfillmentModalAdd extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    selected: [],
  };

  componentDidUpdate({ open: prevOpen }) {
    const { open, selected } = this.props;

    if (!prevOpen && open) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected });
    }
  }

  handleSelection = value => {
    this.setState({ selected: value });
  };

  render() {
    const { open, options, onCancel, onSelect } = this.props;
    const { selected } = this.state;

    return (
      <AppProvider i18n={en}>
        <Modal
          open={open}
          title="Add Fields"
          onClose={onCancel}
          primaryAction={{
            content: 'Add',
            // eslint-disable-next-line react/destructuring-assignment
            onAction: () => onSelect(this.state.selected),
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: onCancel,
            },
          ]}
        >
          <div className="metafield-modal-body">
            <Modal.Section>
              <ModalMetaContent
                options={options}
                selected={selected}
                handleSelection={this.handleSelection}
              />
            </Modal.Section>
          </div>
        </Modal>
      </AppProvider>
    );
  }
}

export default FulfillmentModalAdd;
