import config from '../config';

const serverFetch = async (
  url: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {
  const user = sessionStorage.getItem('USER');
  const token = localStorage.getItem(user);
  const authHeaders = {
    'Shopify-Domain': config.SHOPIFY_DOMAIN,
    'Authentication-Token': token,
  };
  return fetch(url, {
    ...(options
      ? {
          ...options,
          headers: {
            ...(options.headers ? options.headers : {}),
            ...authHeaders,
          },
        }
      : { headers: authHeaders }),
    credentials: 'include',
  });
};

export default serverFetch;
