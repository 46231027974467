export function getMinuteTZ(date: Date, timezone?: string): number {
  const options = { minute: 'numeric', timeZone: timezone };
  return Number(new Intl.DateTimeFormat('en-US', options).format(date));
}

export function getHourTZ(date: Date, timezone?: string): number {
  const options = { hour: 'numeric', hour12: false, timeZone: timezone };
  return Number(new Intl.DateTimeFormat('en-US', options).format(date));
}

export function getDayTZ(date: Date, timezone?: string): number {
  const options = { day: 'numeric', timeZone: timezone };
  return Number(new Intl.DateTimeFormat('en-US', options).format(date));
}

export function getMonthTZ(date: Date, timezone?: string): number {
  const options = { month: 'numeric', timeZone: timezone };
  return Number(new Intl.DateTimeFormat('en-US', options).format(date));
}

export function getYearTZ(date: Date, timezone?: string): number {
  const options = { year: 'numeric', timeZone: timezone };
  return Number(new Intl.DateTimeFormat('en-US', options).format(date));
}

// Actual ms
const HOUR = 60 * 60 * 1000;

// Approximations to find magnitude
const DAY = 24;
const MONTH = DAY * 40;
const YEAR = MONTH * 12;

export function getOffset(timezone: string, date?: Date): number {
  const d = new Date(date || Date.now());

  const tzHour = getHourTZ(d, timezone);
  const tzDay = getDayTZ(d, timezone);
  const tzMonth = getMonthTZ(d, timezone);
  const tzYear = getYearTZ(d, timezone);
  const tzMins = getMinuteTZ(d, timezone);

  const hour = getHourTZ(d);
  const mins = getMinuteTZ(d);
  const day = getDayTZ(d);
  const month = getMonthTZ(d);
  const year = getYearTZ(d);

  const tzTotal = tzYear * YEAR + tzMonth * MONTH + tzDay * DAY + tzHour;
  const total = year * YEAR + month * MONTH + day * DAY + hour;

  // This is gross, I know. Probably a better way but works fine

  // As some countries like India using the timezone in mins too (+5:30),
  // Changed the offset increment value from 1 to .5
  if (total <= tzTotal) {
    let offset = 0;
    let h = hour + (mins / 60);

    while (h !== (tzHour + (tzMins / 60))) {
      offset += 0.5;

      h -= 0.5;

      if (h < 0) {
        h = 23.5;
      }
    }

    return offset * HOUR;
  }

  let offset = 0;
  let h = hour + (mins / 60);

  while (h !== (tzHour + (tzMins / 60))) {
    offset += 0.5;

    h += 0.5;

    if (h > 23.5) {
      h = 0;
    }
  }

  return offset * -HOUR;
}

export function timestampToTZ(timestamp: number, timezone: string): number {
  // return timestamp + offset
  return getISODateTimestamp(timestamp, timezone);
}

/**
 * On Shopify Datepicker, When we select a date it gives browser's local timezone.
 * We need to convert it to Store timezone. then need to find the ISO timestamp
 *
 * @param {number} timestamp - from date picker
 * @param {string} timezone - store timezone
 * @return {*} - ISO converted timestamp
 */
export function getISODateTimestamp(
  timestamp: number,
  timezone: string
): number {
  const d = new Date(timestamp);
  const tzHour = getHourTZ(d, timezone);
  const tzMins = getMinuteTZ(d, timezone);
  let offset = 0

  const options = {timeZone: timezone, dateStyle: 'short', timeStyle: 'short' };
  const date = new Date(timestamp).getTime()
  const timeZoneDate = new Date(new Intl.DateTimeFormat('en-US', options).format(date)).getTime()
  const hourOffset = tzHour + (tzMins / 60) // converting the mins to hour
  if (timeZoneDate < date) {
    // TimeZone is behind GMT
    offset = (24 - hourOffset) * HOUR;
  } else {
    // TimeZone ahead GMT
    offset = hourOffset * -HOUR;
  }
  return timestamp + offset
}

/**
 * From shopify metafield timestamp value to local calendar date.
 *
 * @param {string} timezone
 * @param {number} [timestamp]
 * @return {*}  {(number | undefined)} - time
 */
export function getTimeStampFromISO(timezone: string, timestamp?: number): number | undefined {
  if (!timestamp) {
    return undefined
  }
  const options = {timeZone: timezone, dateStyle: 'short', timeStyle: 'short' };
  const date = new Date(timestamp)
  return new Date(new Intl.DateTimeFormat('en-US', options).format(date)).getTime()
}

export function formatTZ(ts: number, timezone: string): string {
  const options = {
    year: 'numeric',
    month: 'short',
    weekday: 'short',
    day: 'numeric'
  };

  const timeZoneOptions = {
    timeZoneName: 'short',
    timeZone: timezone
  }
  var dateTimeFormat = new Intl.DateTimeFormat('en-US', Object.assign(timeZoneOptions, options)).format(ts);
  return `${new Intl.DateTimeFormat('en-US', options).format(ts)}, ${dateTimeFormat.split(',')[3]}`
}

export const noop = () => {};
