import gql from 'graphql-tag';
import { AsyncAction, loadFieldDefinitions } from './index';
import {
  LoadBulkProductsQuery,
  LoadBulkProductsQueryVariables,
} from '../Graph/generatedTypes';

const LoadBulkProducts = gql`
  query loadBulkProducts($query: String, $after: String, $before: String) {
    bulkProducts(query: $query, after: $after, before: $before) {
      edges {
        cursor
        node {
          ... on Row {
            id
            type
          }
          ... on SingleProductRow {
            variantId
            thumbnail
            title
            sku
          }
          ... on ProductRow {
            thumbnail
            title
          }
          ... on VariantRow {
            thumbnail
            title
            sku
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const loadBulkProducts: AsyncAction = (
  variables: LoadBulkProductsQueryVariables,
) => async (dispatch, getState, { graphqlClient }) => {
  dispatch({
    type: 'LOAD_BULK_PRODUCTS_START',
    payload: null,
  });

  // Check if we have field definitions yet
  let { fieldDefinitions } = getState();

  if (!fieldDefinitions.length) {
    await dispatch(loadFieldDefinitions());
    fieldDefinitions = getState().fieldDefinitions;
  }

  try {
    const {
      data: { bulkProducts },
    } = await graphqlClient.query<
      LoadBulkProductsQuery,
      LoadBulkProductsQueryVariables
    >({
      query: LoadBulkProducts,
      variables,
    });

    const rows = bulkProducts.edges.map(({ node }) => {
      // Strip the typename gql returns
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      delete (node as any).__typename;
      return node;
    });

    return dispatch({
      type: 'LOAD_BULK_PRODUCTS_COMPLETE',
      payload: {
        rows,
        nextPageCursor: bulkProducts.pageInfo.hasNextPage
          ? bulkProducts.edges[bulkProducts.edges.length - 1].cursor
          : undefined,
        previousPageCursor: bulkProducts.pageInfo.hasPreviousPage
          ? bulkProducts.edges[0].cursor
          : undefined,
      },
    });
  } catch (e) {
    return dispatch({
      type: 'LOAD_BULK_PRODUCTS_ERROR',
      payload: {
        error: e,
      },
    });
  }
};

export default loadBulkProducts;
