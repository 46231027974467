import React from 'react';
import { Card, Pagination } from '@shopify/polaris';

import FieldSelectionHeader, {
  FieldSelectionOption,
} from './FieldSelectionHeader';
import ProductSearchBar from './ProductSearchBar';
import FulfillmentTable, { FieldColumn } from './FulfillmentTable';

import styles from './Fulfillment.module.css';
import { BulkProductRow } from '../../Graph/generatedTypes';
import { StagedField } from '../../Routes/App/Fulfillment';

function BulkEdit(props: BulkEditProps) {
  const {
    loading,
    fields,
    onUpdateFields,
    onUpdateQuery,
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
    rows,
    fieldColumns,
    onFieldsUpdate,
  } = props;

  return (
    <>
      <Card>
        <FieldSelectionHeader
          loading={loading}
          fieldOptions={fields}
          onUpdateSelection={onUpdateFields}
        />
      </Card>

      <Card>
        <ProductSearchBar onQueryUpdate={onUpdateQuery} />
      </Card>
      <div className={styles.cardSpacingTop}>
        <Card subdued>
          <FulfillmentTable
            loading={loading}
            rows={rows}
            fieldColumns={fieldColumns}
            onFieldsUpdate={onFieldsUpdate}
          />

          <div className={styles.pagination}>
            <Pagination
              hasNext={!loading && hasNextPage}
              onNext={onNextPage}
              hasPrevious={!loading && hasPreviousPage}
              onPrevious={onPreviousPage}
            />
          </div>
        </Card>
      </div>
    </>
  );
}

export default BulkEdit;

// TYPES

export interface BulkEditProps {
  /**
   * Indicates if the global loading spinner should be shown
   */
  loading: boolean;

  /**
   * Definition of the fields that are going to be shown
   */
  fields: FieldSelectionOption[];

  /**
   * Callback for when the fields change
   */
  onUpdateFields: (fields: FieldSelectionOption[]) => void;

  /**
   * Callback for when the query string is updated
   */
  onUpdateQuery: (query: string) => void;

  // Pagination

  /**
   * If there is another page after the current one
   */
  hasNextPage: boolean;

  /**
   * Callback to request the next page
   */
  onNextPage: () => void;

  /**
   * If there is another page before the current one
   */
  hasPreviousPage: boolean;

  /**
   * Callback to request the previous page
   */
  onPreviousPage: () => void;

  // Legacy (@todo refactor)
  rows: BulkProductRow[];
  fieldColumns: FieldColumn[];
  onFieldsUpdate: (fields: StagedField[]) => void;
}
