import React from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';
import { differenceInDays } from 'date-fns';

import { Popover } from '@shopify/polaris';
import { TimeCellPicker } from '../Pickers';

import styles from '../Fulfillment.module.css';

// @todo consider making this dynamic
// @todo consider loading with fieldDefinitions
const defaultInterval = 1000 * 60 * 60 * 24 * 3; // 3 Days

interface TimeCellProps {
  /**
   * Interval value
   */
  value: {
    interval?: number; // ms
    unit?: 'DAY' | 'WEEK';
  };

  /**
   * If this cell is the one currently being edited
   */
  editing: boolean;

  /**
   * Fires when the user updates the time interval
   */
  onTimeUpdate: (interval: number, unit: 'DAY' | 'WEEK') => void;

  /**
   * If the edit is cancelled
   */
  onCancelEdit: () => void;
}

function TimeCell(props: TimeCellProps & ICellProps) {
  const { value, editing, onTimeUpdate, onCancelEdit, ...cellProps } = props;

  const { interval = defaultInterval, unit = 'DAY' } = value || {};

  const defaultUsed = (value.interval === undefined || isNaN(value.interval));
  const days = differenceInDays(
    !defaultUsed ? interval : defaultInterval,
    0,
  );
  const formattedDays = `${days} ${days === 1 ? 'day' : 'days'}`;

  const descriptiveInterval =
    unit === 'WEEK'
      ? `${days / 7} ${days / 7 === 1 ? 'week' : 'weeks'}`
      : formattedDays;

  const content = (
    <div className={styles.cellSpacing}>
      <div
        className={styles.singleTextCell}
        style={defaultUsed ? { color: 'grey' } : {}}
      >
        {descriptiveInterval}
      </div>
    </div>
  );

  return (
    <Cell {...cellProps} truncated interactive>
      <Popover
        activator={content}
        active={editing}
        onClose={onCancelEdit}
        preferredPosition="below"
        fullWidth
      >
        <TimeCellPicker
          initialInterval={interval}
          initialUnit={unit}
          onTimeUpdate={onTimeUpdate}
        />
      </Popover>
    </Cell>
  );
}

export default React.memo(TimeCell);
