import { object, string, ObjectSchema } from 'yup';
import { FormValues } from './FieldForm';

const SCHEMA = object({
  title: string()
    .min(3, 'Too short')
    .required('Required field'),
  fieldType: string().required('Required field'),
  namespace: string()
    .required('Required field')
    .min(3, 'Shopify requires at least 3 character')
    .lowercase('All characters should be lowercase')
    .matches(/^[a-z0-9_]*$/, 'Letters, numbers, or underscores only'),
  key: string()
    .required('Required field')
    .min(3, 'Shopify requires at least 3 character')
    .lowercase('All characters should be lowercase')
    .matches(/^[a-z0-9_]*$/, 'Letters, numbers, or underscores only'),
  resourceType: string().required('Required field'),
}) as ObjectSchema<FormValues>;

export default SCHEMA;
