import React, { Component } from 'react';
import { ChoiceList, TextField, Icon, Tag } from '@shopify/polaris';

interface Props {
  options: { value: string; label: string }[];
  selected: string[];
  handleSelection(value): any;
}

interface State {
  inputText: string;
  options: any;
}

class ModalMetaList extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    inputText: '',
    // eslint-disable-next-line react/destructuring-assignment
    options: this.props.options,
  };

  updateList = (value: string) => {
    this.setState({ inputText: value });
    if (value === '') {
      this.setState({
        // eslint-disable-next-line react/destructuring-assignment
        options: this.props.options,
      });
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    // eslint-disable-next-line react/destructuring-assignment
    const resultOptions = this.props.options.filter(option =>
      option.label.match(filterRegex),
    );
    this.setState({
      options: resultOptions,
    });
  };

  onTagRemove = item => {
    // eslint-disable-next-line react/destructuring-assignment
    const newSelected = this.props.selected.filter(ele => ele !== item);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleSelection(newSelected);
  };

  render() {
    const { inputText } = this.state;
    const { selected, handleSelection, options } = this.props;
    const selectedTags = selected.map(item => (
      <Tag key={item} onRemove={() => this.onTagRemove(item)}>
        {options.find(({ value }) => value === item).label}
      </Tag>
    ));
    return (
      <div className="metafield-modal-container">
        <div className="metafield-modal-searchbar">
          <TextField
            label=""
            type="search"
            value={inputText}
            prefix={<Icon source="search" color="inkLighter" />}
            placeholder="Search"
            onChange={this.updateList}
          />
        </div>
        {selected.length > 0 && (
          <div className="metafield-modal-tags">{selectedTags}</div>
        )}
        <div className="metafield-modal-checkboxes">
          <ChoiceList
            allowMultiple
            title=""
            // eslint-disable-next-line react/destructuring-assignment
            choices={this.state.options}
            selected={selected}
            onChange={handleSelection}
          />
        </div>
      </div>
    );
  }
}

export default ModalMetaList;
