import { number, object, string, ObjectSchema } from 'yup';
import { FormValues } from './FieldForm';

const SCHEMA = object({
  dotdigital_account_id: string()
    .required('Required field')
    .min(3, 'Shopify requires at least 3 character')
    .lowercase('All characters should be lowercase')
    .matches(/^[a-z0-9_]*$/, 'Letters, numbers, or underscores only'),
  confirmation_template_id: string()
    .required('Required field')
    .min(3, 'Shopify requires at least 3 character')
    .lowercase('All characters should be lowercase')
    .matches(/^[a-z0-9_]*$/, 'Letters, numbers, or underscores only'),
  backinstock_template_id: string()
    .required('Required field')
    .min(3, 'Shopify requires at least 3 character')
    .lowercase('All characters should be lowercase')
    .matches(/^[a-z0-9_]*$/, 'Letters, numbers, or underscores only'),
  instock_threshold: number()
    .required('Required field')
}) as ObjectSchema<FormValues>;

export default SCHEMA;
