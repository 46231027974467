import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';
import { Field } from '../Graph/generatedTypes';

export interface State {
  types: {
    [id: string]: Field[];
  };
  loading: boolean;
}

const defaultState: State = {
  types: {},
  loading: true,
};

const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_BULK_PRODUCTS_START': {
      return defaultState;
    }
    case 'UPDATE_BULK_FIELDS_START': {
      return defaultState;
    }
    case 'LOAD_BULK_FIELDS_START': {
      return defaultState;
    }
    case 'LOAD_BULK_FIELDS_COMPLETE': {
      const { types } = action.payload;

      return {
        loading: false,
        types,
      };
    }
    default:
      return state;
  }
};

export default reducer;
