import React, { Component } from 'react';
import {
  FormikProps,
  withFormik,
  getIn,
  validateYupSchema,
  setIn,
  FormikErrors,
} from 'formik';
import {
  Stack,
  DisplayText,
  Button,
  Card,
  Banner,
  Layout,
} from '@shopify/polaris';

import { Bundle } from '../../types';
import BundleItems from './BundleItems';
import schema from './schema';
import BundleSummary from './BundleSummary';
import ComponentPicker from './ComponentPicker';

export interface BundleFormProps {
  saving?: boolean;
  intitialBundle: Bundle;
  onSubmit: (bundle: Bundle) => void;
  onSavableChange?: (dirty: boolean) => void;
  submit?: (fn: () => void) => void;
  onFeedback?: (content: string) => void;
}

type Props = BundleFormProps & FormikProps<Bundle>;

interface State {
  addingProducts: boolean;
  dragging: boolean;
  showBundleComponents:boolean;
}

class BundleForm extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    addingProducts: false,
    dragging: false,
    showBundleComponents:true
  };

  componentWillMount(): void {
    const { intitialBundle, values } = this.props;
    if(values.contents.length === 0) {
      this.setState({showBundleComponents : true })
      return;
    }
    if(intitialBundle.showBundleComponents !== null) {
      this.setState({showBundleComponents : intitialBundle.showBundleComponents })
    }
  }

  componentDidMount() {
    // Pass the submission ability to parent
    const { submit } = this.props;
    if (submit) {
      submit(this.submit);
    }
  }

  componentDidUpdate({
    dirty: wasDirty,
    isValid: wasValid,
    saving: wasSaving,
  }: Props) {
    const {
      dirty,
      isValid,
      onSavableChange,
      saving,
      status,
      setStatus,
    } = this.props;

    const wasSavable = wasDirty && wasValid;
    const isSavable = dirty && isValid;

    // Tell parent if form is savable
    if (isSavable !== wasSavable) {
      if (onSavableChange) {
        onSavableChange(isSavable);
      }
    }

    // Update form status
    if (saving !== wasSaving) {
      setStatus({ ...status, saving });
    }
  }

  submit = () => {
    // Dispatch a submission event
    // eslint-disable-next-line react/destructuring-assignment
    this.props.submitForm();
  };

  render() {
    const {
      intitialBundle,
      values,
      handleReset,
      handleSubmit,
      setFieldValue,
      status,
      errors,
      onFeedback,
    } = this.props;
    const { addingProducts, dragging, showBundleComponents } = this.state;


    let errorMarkup;
    try {
      const error = getIn(errors, `contents_error`);
      errorMarkup = typeof error === 'string' && (
        <Banner title="Invalid bundle configuration" status="critical">
          <p>{error}</p>
        </Banner>
      );
    } catch (e) {
      // intentionally empty
    }

    // Generate list of child ids
    // @todo clean this up if possible
    const childIds = values.contents
      ? values.contents.reduce<string[]>((acc, item) => {
          if (item.type === 'product') {
            return [...acc, item.id];
          }

          return [...acc, ...item.contents.map(({ id }) => id)];
        }, [])
      : [];

    return (
      <Layout sectioned>
        <Stack distribution="equalSpacing">
          <DisplayText size="large">{intitialBundle.title}</DisplayText>
          <Button
            disabled={status.saving}
            onClick={() => this.setState({ addingProducts: true })}
          >
            Add Component
          </Button>
        </Stack>
        <br />
        {intitialBundle.error && (
          <>
            <Banner
              title="Encountered error while loading bundle"
              status="critical"
            >
              <p>
                While loading this bundle, an error was encountered and
                recovered from. This bundle likely need to be updated and saved
                again before it will work correctly.
              </p>
              <pre>{intitialBundle.errorMessage ?? 'No details provided'}</pre>
            </Banner>
            <br />
          </>
        )}
        {!intitialBundle.publishedAt && intitialBundle.contents.length > 0 && (
          <>
            <Banner
              title="Bundle not available"
              status="warning"
              action={{
                content: 'Open product in Shopify',
                url: intitialBundle.adminUrl,
                external: true,
              }}
            >
              This bundle is currently not available for sale on the online
              store. This may be because one of the contained products has been
              deleted. Please verify the bundle is correct and save before
              publishing again.
            </Banner>
            <br />
          </>
        )}
        <Card>
        <form onReset={handleReset} onSubmit={handleSubmit}>
          <Card.Section>
            <BundleSummary items={values.contents} checked={showBundleComponents} onSelectionChange={(checkValue) => {
              setFieldValue('showBundleComponents',checkValue)
              this.setState({showBundleComponents: checkValue})

            }} />
          </Card.Section>
          <Card.Section subdued>
              <BundleItems
                title={intitialBundle.title}
                name="contents"
                items={values.contents}
                requestAddingProducts={() =>
                  this.setState({ addingProducts: true })
                }
                dragging={dragging}
                setDragging={d => this.setState({ dragging: d })}
              />

          </Card.Section>
          </form>
          <ComponentPicker
            open={addingProducts}
            parentId={values.id}
            existingChildIds={childIds}
            onCancel={() => this.setState({ addingProducts: false })}
            onSelection={(newChildren, errs) => {
              this.setState({ addingProducts: false });
              if (errs) {
                if (errs.length === 1) {
                  onFeedback(errs[0].message);
                } else {
                  onFeedback(`Unable to add ${errs.length} components`);
                }
              }

              // Must use current values
              setFieldValue(
                'contents',
                // eslint-disable-next-line react/destructuring-assignment
                this.props.values.contents.concat(newChildren),
              );
            }}
          />
          {errorMarkup}
        </Card>
      </Layout>
    );
  }
}

function yupToFormErrors<Values>(yupError: any): FormikErrors<Values> {
  let errors: any = {} as FormikErrors<Values>;
  if (yupError.inner.length === 0) {
    return setIn(errors, yupError.path, yupError.message);
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const err of yupError.inner) {
    if (!errors[err.path]) {
      errors = setIn(errors, `${err.path}_error`, err.message);
    }
  }
  return errors;
}

export default withFormik<BundleFormProps, Bundle>({
  mapPropsToValues: ({ intitialBundle }) => intitialBundle,
  mapPropsToStatus: ({ saving = false }) => ({ saving, dragging: false }),
  handleSubmit: (bundle, { props }) => props.onSubmit(bundle),
  // This is essentially the same as
  // validationSchema it just prepends `_error`
  // to errors to avoid having Formik throw a fit
  validate: async values => {
    let errors = {};

    try {
      await validateYupSchema(values, schema);
    } catch (e) {
      // @todo check if validation error

      try {
        errors = yupToFormErrors(e);
      } catch (err) {
        // @todo find better way to handle this
        // maybe fix it upstream
        // eslint-disable-next-line no-console
        console.error(err);
        errors = {};
      }
    }

    if (Object.keys(errors).length) {
      return errors;
    }
  },
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
})(BundleForm);
