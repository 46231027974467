import { useAppBridge } from '@shopify/app-bridge-react';
import { SelectPayload } from '@shopify/app-bridge/actions/ResourcePicker';

import defaultThumbnail from '../../../Assets/default.jpg';
import { BundleProduct } from '../../../types';
import config from '../../../config';
import { useEffect } from 'react';

const shopifyThumbnail = (url: string) => url.replace('.jpg', '_60x60.jpg');

export type ExtendedProduct = BundleProduct & { tags: string[] };

interface Props {
  open: boolean;
  onCancel: () => void;
  onSelection: (products: ExtendedProduct[]) => void;
}

function ProductPicker({ open, onCancel, onSelection }: Props) {
  const shopify = useAppBridge();
  useEffect(() => {
    if(open){
      shopify.resourcePicker({
        type: "product",
        multiple: true,
        filter: {
          hidden: true,
          variants: false,
        }
      }).then((selected: SelectPayload) => {
        if(selected){
          const { selection } = selected;
          const newProducts: ExtendedProduct[] = selection.map<ExtendedProduct>(
            ({ id, title, tags, images, variants }: any) => {
              const thumbnail = images.length
                ? shopifyThumbnail(images[0].originalSrc)
                : defaultThumbnail;

              const bundleOnly = Boolean(
                tags.find(tag => tag === '#.bundle:bundle_only'),
              );

              return {
                type: 'product',
                id: id.replace('gid://shopify/Product/', ''),
                tags,
                title,
                thumbnail,
                bundleOnly,
                reportable: true,
                adminUrl: `https://admin.shopify.com/store/${config.SHOPIFY_DOMAIN.replace('.myshopify.com', '')}/products/${id.replace('gid://shopify/Product/', '')}`,
                variants: variants.map(({ id: i, title: t, price, sku }) => ({
                  id: i.replace('gid://shopify/ProductVariant/', ''),
                  sku,
                  title: t,
                  salePrice: Number(price.replace('.', '')),
                  bundlePrice: Number(price.replace('.', '')),
                })),
              };
            },
          );
          onSelection(newProducts);
        }else{
          onCancel();
        }
      })
    }
  },[open, shopify, onSelection, onCancel]);

  return null;
}

export default ProductPicker;
