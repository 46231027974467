import React from 'react';
import ReactDOM from 'react-dom';

// import '@blueprintjs/core/lib/css/blueprint.css';
// I don't think we can include this. It messes up the polaris fonts
import '@blueprintjs/table/lib/css/table.css';
import '@shopify/polaris/styles.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faObjectGroup,
  faEdit,
  faCogs,
} from '@fortawesome/free-solid-svg-icons';

import App from './App';
import Web from './Web';

import './Components/styles.scss';

import config from './config';

library.add(faObjectGroup);
library.add(faEdit);
library.add(faCogs);

if (config.IN_IFRAME) {
  // Authentication stuff
  // @todo move
  let token;

  const url = new URL(window.location.href);
  const urlParamToken = url.searchParams.get('token');
  const urlParamId = url.searchParams.get('id');
  const lsToken = window.localStorage.getItem(config.SHOPIFY_DOMAIN);

  if (urlParamId) {
    window.localStorage.setItem('id', urlParamId);
  }
  if (urlParamToken) {
    token = urlParamToken;
  } else if (lsToken) {
    token = lsToken;
  } else {
    token = '';
  }

  window.localStorage.setItem(config.SHOPIFY_DOMAIN, token);

  if (process.env.NODE_ENV === 'development') {
    const headers = JSON.stringify(
      {
        'Shopify-Domain': config.SHOPIFY_DOMAIN,
        'Authentication-Token': token,
      },
      null,
      2,
    );

    /* eslint-disable no-console */
    console.info(`Graphql Playground for ${config.SHOPIFY_DOMAIN}:`);
    console.info(`https://${window.location.host}/graphql`);
    console.info(`Paste following into "HTTP HEADERS":`);
    console.info(headers);
    /* eslint-enable no-console */
  }

  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  ReactDOM.render(<Web />, document.getElementById('root'));
}
