import gql from 'graphql-tag';
import { graphqlClient } from '../Graph';
import {
  User,
  LoadUsersQuery,
  LoadUsersQueryVariables,
} from '../Graph/generatedTypes';

const USERS_QUERY = gql`
  query LoadUsers {
    users {
      edges {
        node {
          id
          email
          role
        }
      }
    }
  }
`;

async function usersLoad(): Promise<User[]> {
  const { data } = await graphqlClient.query<
    LoadUsersQuery,
    LoadUsersQueryVariables
  >({
    query: USERS_QUERY,
    fetchPolicy: 'no-cache',
  });

  return data.users.edges.map(edge => edge.node);
}

export default usersLoad;
