import gql from 'graphql-tag';
import { graphqlClient } from '../Graph';
import {
  User,
  LoadUserQuery,
  LoadUserQueryVariables,
} from '../Graph/generatedTypes';

const LOAD_USER_QUERY = gql`
  query LoadUser($id: ID!) {
    user(id: $id) {
      id
      email
      role
    }
  }
`;

async function userLoad(id: string): Promise<User> {
  const { data } = await graphqlClient.query<
    LoadUserQuery,
    LoadUserQueryVariables
  >({
    query: LOAD_USER_QUERY,
    variables: { id },
  });

  return data.user;
}

export default userLoad;
