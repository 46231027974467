import React from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';
import { Popover } from '@shopify/polaris';
import styles from '../Fulfillment.module.css';
import { BooleanCellPicker } from '../Pickers';

interface Props {
  initialValue: boolean;
  editing: boolean;
  onCancelEdit: () => void;
  onChange: (bravado: boolean) => void;
}
function BooleanCell(props: ICellProps & Props) {
  const { initialValue, editing, onCancelEdit, onChange, ...cellProps } = props;
  const content = (
    <div className={styles.cellSpacing}>
      <div className={styles.singleTextCell}>{`${initialValue}`}</div>
    </div>
  );

  return (
    <Cell {...cellProps}>
      <Popover
        activator={content}
        active={editing}
        onClose={onCancelEdit}
        preferredPosition="below"
      >
        <BooleanCellPicker initialValue={initialValue} onChange={onChange} />
      </Popover>
    </Cell>
  );
}

export default React.memo(BooleanCell);
