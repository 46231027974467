import React from 'react';
import { Link as ReachLink } from '@reach/router';
import { AppProviderProps } from '@shopify/polaris';

/**
 * Link component that converts from @shopify/polaris to @reach/router
 */
const Link: AppProviderProps['linkComponent'] = ({
  url,
  external,
  children,
}: any) => {
  const target = external ? '_blank' : undefined;
  const rel = external ? 'noopener noreferrer' : undefined;

  if (external) {
    return (
      <a href={url} target={target} rel={rel}>
        {children}
      </a>
    );
  }

  return (
    <ReachLink to={url} target={target} rel={rel}>
      {children}
    </ReachLink>
  );
};

export default Link;
