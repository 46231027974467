import React from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Page, Banner, Card, SelectOption } from '@shopify/polaris';

import gql from 'graphql-tag';
import FieldForm from '../../Components/Admin/FieldForm';
import { FormValues } from '../../Components/Admin/FieldForm/FieldForm';
import { graphqlClient, loadFieldTypeOptions } from '../../Graph';
import {
  CreateFieldDefinitionMutation,
  CreateFieldDefinitionMutationVariables,
  FieldType,
  ResourceType,
} from '../../Graph/generatedTypes';
import { StatusContext } from '../../Context';

const TOOL_TIP = 'Create a new Field Definition';

const CREATE_FIELD_DEFINITION = gql`
  mutation createFieldDefinition($fieldDefinition: FieldDefinitionNew!) {
    fieldDefinitionCreate(fieldDefinition: $fieldDefinition) {
      id
      type
      title
      resourceType
      shownByDefault
    }
  }
`;

interface State {
  saving: boolean;
  loading: boolean;
  fieldTypeOptions: SelectOption[];
}

class NewField extends React.Component<RouteComponentProps, State> {
  static contextType = StatusContext;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    saving: false,
    loading: true,
    fieldTypeOptions: [],
  };

  shopify = useAppBridge();

  async componentDidMount() {
    try {
      const fieldTypeOptions = await loadFieldTypeOptions();
      this.setState({
        fieldTypeOptions: fieldTypeOptions.map(option => ({
          label: option.title,
          value: option.type,
        })),
        loading: false,
      });
      shopify.loading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to load fieldTypeOptions. ${e}`);
      this.setState({ loading: false });
      shopify.loading(false);
    }
  }

  handleSubmit = (values: FormValues) => {
    const { showToast } = this.context;

    this.setState({ saving: true });

    this.createFieldDefinition(values)
      .then(f => {
        showToast('New field was successfully created');
        navigate(`/admin/fields/${f.id}`);
      })
      .catch(() =>
        this.setState({ saving: false }, () =>
          showToast('Error Creating New Field', true),
        ),
      );
  };

  createFieldDefinition = async (values: FormValues) => {
    const { data } = await graphqlClient.mutate<
      CreateFieldDefinitionMutation,
      CreateFieldDefinitionMutationVariables
    >({
      mutation: CREATE_FIELD_DEFINITION,
      variables: {
        fieldDefinition: {
          title: values.title,
          type: values.fieldType as FieldType,
          resourceType: values.resourceType as ResourceType,
          key: values.key,
          namespace: values.namespace,
          shownByDefault: values.shownByDefault || false,
          options: values.dropDownValues,
        },
      },
    });

    return data.fieldDefinitionCreate;
  };

  render() {
    const { saving, loading, fieldTypeOptions } = this.state;

    return (
      <Page
        title="New Field Definition"
        breadcrumbs={[
          {
            content: 'Field Definitions',
            onAction: () => navigate('/admin/fields')
          },
        ]}
      >
        <Banner
          title="Create a new column in your FH-App Metafield Bulk Editor"
          status="info"
        >
          <p>Use the following form to add a metafield to your store.</p>
        </Banner>
        <Card>
          <Card.Section>
            <FieldForm
              onSubmit={this.handleSubmit}
              loading={saving || loading}
              fieldTypeOptions={fieldTypeOptions}
              toolTipContent={TOOL_TIP}
            />
          </Card.Section>
        </Card>
      </Page>
    );
  }
}

export default NewField;
