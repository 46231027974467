import { AsyncAction, showToast } from './index';
import { Bundle, BundleProduct } from '../types';
import { removeDiscarded } from '../persist';
import { API } from './types';
import { parseAPIBundle } from './utils';

const formatBundleProduct = (
  product: BundleProduct,
): API.InputBundleProduct => ({
  type: 'product',
  id: product.id,
  bundleOnly: product.bundleOnly,
  reportable: product.reportable,
  variants: product.variants.map(variant => ({
    id: variant.id,
    bundlePrice: variant.bundlePrice,
  })),
});

export const updateBundle: AsyncAction = (bundle: Bundle) => async (
  dispatch,
  getState,
  { serverFetch, config },
) => {
  dispatch({
    type: 'UPDATE_BUNDLE_START',
    payload: null,
  });

  const body: API.InputBundle = {
    id: bundle.id,
    showBundleComponents: !!bundle.showBundleComponents,
    contents: bundle.contents.map<API.InputBundleItem>(item => {
      if (item.type === 'product') {
        return formatBundleProduct(item);
      }

      // Assume set
      return {
        type: 'set',
        label: item.label,
        contents: item.contents.map(formatBundleProduct),
      };
    }),
  };

  // @todo use a URL object instead of string coercion
  // @todo add the status checking and parsing to server fetch
  const response = await serverFetch(`/api/bundles/${bundle.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    dispatch({
      type: 'UPDATE_BUNDLE_ERROR',
      payload: null,
    });
    return dispatch(showToast({ content: 'Error saving bundle', error: true }));
  }

  // @todo error handling
  const payload: { bundle: API.Bundle } = await response.json();

  // Remove from discarded list
  removeDiscarded(bundle.id);

  // @todo better format when #19 is complete
  dispatch({
    type: 'UPDATE_BUNDLE_COMPLETE',
    payload: {
      bundle: parseAPIBundle(payload.bundle, config),
    },
  });

  return dispatch(showToast({ content: 'Bundle was successfully saved' }));
};

export default updateBundle;
