// Graph queries and mutations for BackInStock
import gql from 'graphql-tag';
import { graphqlClient } from '../../../Graph';
import {
  MetafieldType,
  MetafieldDefinitionConnection,
  OwnerType,
  ShopMetafield,
  ShopMetafieldConnection,
} from '../../../Graph/generatedTypes';

// Begin Metafield Definitions
const LOAD_METAFIELD_DEFINITIONS = gql`
  query loadMetafieldDefinitions($first: Int!, $namespace: String!, $ownerType: String!) {
    metafieldDefinitions(first: $first, namespace: $namespace, ownerType: $ownerType) {
      edges {
        node {
          id
          key
          name
          namespace
          ownerType
          type {
            name
          }
        }
      }
    }
  }
`;

const CREATE_METAFIELD_DEFINITION = gql`
  mutation createMetafieldDefinition($metafieldDefinition: MetafieldDefinitionNew!) {
    metafieldDefinitionCreate(metafieldDefinition: $metafieldDefinition) {
      key
      name
      namespace
      ownerType
      type
    }
  }
`;

const loadMetafieldDefinitions = async (first: number, namespace: string, ownerType: string): Promise<MetafieldDefinitionConnection> => {
  const { data } = await graphqlClient.query({
    query: LOAD_METAFIELD_DEFINITIONS,
    variables: { first, namespace, ownerType },
    fetchPolicy: 'no-cache',
  });

  return data.metafieldDefinitions;
};

const createMetafieldDefinition = async (definition) => {
  const { data } = await graphqlClient.mutate({
    mutation: CREATE_METAFIELD_DEFINITION,
    variables: {
      metafieldDefinition: {
        key: definition.key,
        name: definition.name,
        namespace: definition.namespace,
        ownerType: definition.ownerType as OwnerType,
        type: definition.type as MetafieldType
      },
    },
  });

  return data.metafieldDefinitionCreate;
};

// Begin Shop Metafields
const CREATE_SHOP_METAFIELD = gql`
  mutation createShopMetafield($shopMetafield: ShopMetafieldNew!) {
    shopMetafieldCreate(shopMetafield: $shopMetafield) {
      id
      key
      value
      type
      description
      namespace
      owner_resource
    }
  }
`;

const UPDATE_SHOP_METAFIELD = gql`
  mutation updateShopMetafield($shopMetafield: ShopMetafieldUpdate!) {
    shopMetafieldUpdate(shopMetafield: $shopMetafield) {
      id
      key
      value
      type
      description
      namespace
      owner_resource
    }
  }
`;

const LOAD_SHOP_METAFIELDS = gql`
  query loadShopMetafields {
    shopMetafields {
      edges {
        node {
          id
          key
          value
          type
          description
          namespace
          owner_resource
        }
      }
    }
  }
`;

const DELETE_SHOP_METAFIELD = gql`
  mutation updateShopMetafield($shopMetafield: ShopMetafieldDelete!) {
    shopMetafieldDelete(shopMetafield: $shopMetafield) {
      deletedId
    }
  }
`;

const createShopMetafield = async (metafield) => {
  const { data } = await graphqlClient.mutate({
    mutation: CREATE_SHOP_METAFIELD,
    variables: {
      shopMetafield: {
        key: metafield.key,
        value: metafield.value,
        type: metafield.type as MetafieldType,
        description: metafield.description,
        namespace: metafield.namespace,
        owner_resource: metafield.owner_resource
      },
    },
  });

  return data.shopMetafieldCreate;
};

const updateShopMetafield = async (
  metafield
): Promise<ShopMetafield> => {
  const { data } = await graphqlClient.mutate({
    mutation: UPDATE_SHOP_METAFIELD,
    variables: {
      shopMetafield: {
        id: metafield.id,
        key: metafield.key,
        value: metafield.value,
        type: metafield.type as MetafieldType,
        description: metafield.description,
        namespace: metafield.namespace,
        owner_resource: metafield.owner_resource
      },
    },
  });

  return data.shopMetafieldUpdate;
};

const deleteShopMetafield = async (
  metafield
): Promise<ShopMetafield> => {
  const { data } = await graphqlClient.mutate({
    mutation: DELETE_SHOP_METAFIELD,
    variables: {
      shopMetafield: {
        id: metafield.id,
      },
    },
  });

  return data.shopMetafieldDelete;
};

const loadShopMetafields = async (): Promise<ShopMetafieldConnection> => {
  const { data } = await graphqlClient.query({
    query: LOAD_SHOP_METAFIELDS,
    fetchPolicy: 'no-cache',
  });

  return {
    edges: data.shopMetafields.edges.map(node => node),
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  };
};

export default {
  createMetafieldDefinition,
  createShopMetafield,
  loadMetafieldDefinitions,
  loadShopMetafields,
  updateShopMetafield,
  deleteShopMetafield
};
