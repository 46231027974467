import React, { useEffect, useCallback } from 'react';
import {
  Page,
  SkeletonBodyText,
  Card,
  FormLayout,
  Button,
} from '@shopify/polaris';
import { RouteComponentProps, navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { TextField } from '@satel/formik-polaris';
import { useFormik, FormikProvider, Form, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import { useToast } from '../../BridgeHooks';

const NOTIFCATION_EMAIL = gql`
  query NotificationEmail {
    notificationEmail
  }
`;
const NOTIFCATION_EMAIL_UPDATE = gql`
  mutation NotificatonEmailUpdate($email: String!) {
    notificationEmailUpdate(email: $email)
  }
`;

function NotificationEmail({ email }: { email: string }) {
  const showToast = useToast();

  const [start] = useMutation(NOTIFCATION_EMAIL_UPDATE);

  const handleSubmit = useCallback(
    async (values: any, helpers: FormikHelpers<any>) => {
      try {
        const { data: d } = await start({
          variables: {
            email: values.email,
          },
        });

        showToast({ message: 'Notification email updated' });
        helpers.resetForm({ values: { email: d.notificationEmailUpdate } });
      } catch (e) {
        showToast({ message: 'Notification email update field', error: true });
      }
    },
    [showToast, start],
  );

  const formik = useFormik({
    initialValues: { email },
    onSubmit: handleSubmit,
    validationSchema: object({
      email: string()
        .label('Email')
        .email()
        .required(),
    }),
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Card title="Update notification email" sectioned>
          <FormLayout>
            <TextField name="email" type="email" label="Email" />
            <Button submit primary loading={formik.isSubmitting}>
              Update
            </Button>
          </FormLayout>
        </Card>
      </Form>
    </FormikProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BundlesVerify(props: RouteComponentProps) {
  // Hook into old redux
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'DONE_LOADING',
    });
  });

  const { data, loading } = useQuery(NOTIFCATION_EMAIL, {
    fetchPolicy: 'network-only',
  });

  return (
    <Page
      title="Notificaton email"
      breadcrumbs={[
        { onAction: () => navigate('/bundles'), content: 'Bundles' },
      ]}
    >
      {loading ? (
        <SkeletonBodyText lines={2} />
      ) : (
        <NotificationEmail email={data.notificationEmail} />
      )}
    </Page>
  );
}

export default BundlesVerify;
