import React, { useState, useCallback, useContext } from 'react';

import { Card, ResourceList, Modal, AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';

import { StatusContext } from '../../Context';
import UserRow from './UserRow';
import { User } from '../../Graph/generatedTypes';

interface UsersListProps {
  /**
   * Indicates if the users are loading
   */
  loading: boolean;

  /**
   * List of users
   */
  users: User[];

  /**
   * Deletes a user from backend
   */
  onDelete: (id: string) => Promise<void> | void;
}

function UsersList(props: UsersListProps) {
  const { showToast } = useContext(StatusContext);

  const { loading, users, onDelete } = props;

  const [deletingUser, setDeletingUser] = useState<User | undefined>();
  const [modalOpen, setModalOpen] = useState(false);

  const handleRequestDeleteUser = useCallback(
    (id: string) => {
      const user = users.find(u => u.id === id);
      setDeletingUser(user);
      setModalOpen(true);
    },
    [users],
  );

  const handleCancelDeleteUser = useCallback(() => {
    setDeletingUser(undefined);
    setModalOpen(false);
  }, []);

  const handleDeleteUser = useCallback(() => {
    setModalOpen(false);

    Promise.resolve(onDelete(deletingUser.id))
      .then(() => {
        setDeletingUser(undefined);
        showToast('User deleted');
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.warn(e);
        setDeletingUser(undefined);
        showToast('Failed to delete user', true);
      });
  }, [deletingUser, onDelete, showToast]);

  const items = users.map((user, index) => [user, index]);

  return (
    <>
      <Card>
        <ResourceList
          items={items}
          loading={loading}
          renderItem={([user, index]) => (
            <UserRow
              {...user}
              index={index}
              disabled={loading || !!deletingUser}
              deleting={deletingUser && user.id === deletingUser.id}
              onDelete={handleRequestDeleteUser}
            />
          )}
        />
      </Card>
      <AppProvider i18n={en}>
        <Modal
          open={modalOpen}
          primaryAction={{
            destructive: true,
            content: 'Delete user',
            onAction: handleDeleteUser,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCancelDeleteUser,
            },
          ]}
          onClose={handleCancelDeleteUser}
          title="Delete User?"
        >
          <Modal.Section>
            Are you sure you want to delete the user with email{' '}
            <code> {deletingUser ? deletingUser.email : ''}</code>
          </Modal.Section>
        </Modal>
      </AppProvider>
    </>
  );
}

export default UsersList;
