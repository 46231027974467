import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import BundleForm, { BundleFormProps } from './BundleForm';
import SkeletonBundleForm from './SkeletonBundleForm';

export { SkeletonBundleForm };

function SupportLegacyContextAPI(props: BundleFormProps) {
  return (
    <DndProvider backend={HTML5Backend}>
      <BundleForm {...props} />
    </DndProvider>
  );
}

export default SupportLegacyContextAPI;
