import React from 'react';
import gql from 'graphql-tag';
import {
  Card,
  ResourceList,
  FilterType,
  Filter,
  AppliedFilter,
} from '@shopify/polaris';
import { graphqlClient } from '../../Graph';
import {
  LoadFilterOptionsQuery,
  LoadFilterOptionsQueryVariables,
} from '../../Graph/generatedTypes';

const LoadFilterOptions = gql`
  query loadFilterOptions {
    productTypes
    productVendors
  }
`;

function generateFilterDefinitions(
  productTypes: string[],
  productVendors: string[],
): Filter[] {
  return [
    {
      key: 'availability',
      label: 'Availability',
      operatorText: 'is',
      type: FilterType.Select,
      options: [
        { label: 'available on Online Store', value: 'available' },
        { label: 'unavailable on Online Store', value: 'unavailable' },
      ],
    },
    {
      key: 'type',
      label: 'Product Type',
      operatorText: 'is',
      type: FilterType.Select,
      options: productTypes,
    },
    {
      key: 'vendor',
      label: 'Product Vendor',
      operatorText: 'is',
      type: FilterType.Select,
      options: productVendors,
    },
    {
      key: 'tag',
      label: 'Tagged with',
      type: FilterType.TextField,
    },
  ];
}

function generateFilterLabel(filter: AppliedFilter): AppliedFilter {
  let label: string | undefined;

  switch (filter.key) {
    case 'availability': {
      label = `Availability is ${
        filter.value === 'available'
          ? 'available on Online Store'
          : 'unavailable on Online Store'
      }`;
      break;
    }
    case 'type': {
      label = `Product type is ${filter.value}`;
      break;
    }
    case 'vendor': {
      label = `Product vendor is ${filter.value}`;
      break;
    }
    case 'tag': {
      label = `Tagged with ${filter.value}`;
      break;
    }
    default:
      label = undefined;
  }

  return {
    ...filter,
    label,
  };
}

function generateFilteredQueryString(
  query: string,
  filters: AppliedFilter[],
): string {
  const filtersQuery = filters.reduce((acc, filter) => {
    const encodedValue = JSON.stringify(filter.value);

    const q = acc ? `${acc} ` : '';

    switch (filter.key) {
      case 'availability': {
        if (filter.value === 'available') {
          return `${q}published_status:"online_store:visible"`;
        }
        return `${q}published_status:"online_store:hidden"`;
      }
      case 'type': {
        return `${q}product_type:${encodedValue}`;
      }
      case 'vendor': {
        return `${q}vendor:${encodedValue}`;
      }
      case 'tag': {
        return `${q}tag:${encodedValue}`;
      }
      default:
        throw new Error(`Unsupported filter type "${filter.key}"`);
    }
  }, '');

  return `${filters.length ? `${filtersQuery} ` : ''}${query}`;
}

interface ProductSearchBarProps {
  onQueryUpdate: (query: string) => void;
}

function ProductSearchBar({ onQueryUpdate }: ProductSearchBarProps) {
  function handleSearchUpdate(query, filters) {
    onQueryUpdate(generateFilteredQueryString(query, filters));
  }

  const [{ productTypes, productVendors }, setData] = React.useState<{
    productTypes: string[];
    productVendors: string[];
  }>({ productTypes: ['Loading...'], productVendors: ['Loading...'] });

  async function loadFilterOptions() {
    const { data } = await graphqlClient.query<
      LoadFilterOptionsQuery,
      LoadFilterOptionsQueryVariables
    >({
      query: LoadFilterOptions,
    });

    setData({ ...data });
  }

  React.useEffect(() => {
    loadFilterOptions();
  }, []);

  const [query, setQuery] = React.useState('');
  const [selectedFilters, setSelectedFilters] = React.useState<AppliedFilter[]>(
    [],
  );

  return (
    <div className="fulfillment__filter">
      <Card>
        <Card.Section>
          <ResourceList
            resourceName={{ singular: 'product', plural: 'products' }}
            items={['']}
            renderItem={() => {
              return null;
            }}
            filterControl={
              <ResourceList.FilterControl
                filters={generateFilterDefinitions(
                  productTypes,
                  productVendors,
                )}
                appliedFilters={selectedFilters}
                onFiltersChange={filters => {
                  const newFilters = filters.map(generateFilterLabel);
                  setSelectedFilters(newFilters);
                  handleSearchUpdate(query, newFilters);
                }}
                searchValue={query}
                onSearchChange={q => {
                  setQuery(q);
                  handleSearchUpdate(q, selectedFilters);
                }}
              />
            }
          />
        </Card.Section>
      </Card>
    </div>
  );
}

export default ProductSearchBar;
