import { IRegion, ICellInterval } from '@blueprintjs/table';

export function normalizeSelections(selections: IRegion[]): IRegion[] {
  let col: number | null = null;

  // @todo consider reversing first? Recent selections come last
  const s = selections.reduce((acc, selection) => {
    const { cols, rows } = selection;

    // eslint-disable-next-line prefer-const
    let [fromCol, toCol] = cols;

    // Whole thing is in product rows
    if (toCol < 2) {
      return acc;
    }

    if (fromCol < 2) {
      fromCol = 2;
    }

    if (!col) {
      if (fromCol === toCol) {
        col = fromCol;
      } else {
        // Best I can do for now
        col = fromCol;
      }
    }

    // Selection is in a different column
    if (fromCol > col || toCol < col) {
      return acc;
    }

    // @todo if end of selection is on a never field make smaller

    return acc.concat({
      cols: [col, col],
      rows,
    });
  }, []);

  return s;
}

export function isSingleSelection(selections: IRegion[]): boolean {
  if (selections.length !== 1) {
    return false;
  }

  const { rows, cols } = selections[0];

  return rows[0] === rows[1] && cols[0] === cols[1];
}

export function selectionsContainCell(
  selections: IRegion[],
  cell: ICellInterval,
): boolean {
  return selections.some(region => {
    const [col, row] = cell;

    if (region.cols[0] > col || region.cols[1] < col) {
      return false;
    }

    if (region.rows[0] > row || region.rows[1] < row) {
      return false;
    }

    return true;
  });
}
