import React, { useEffect } from 'react';
import { Router as ReachRouter, RouteComponentProps } from '@reach/router';
import { DispatchProp, connect } from 'react-redux';
import { Admin as X } from './Routes';
import { Protect } from './Components/AuthGate';
import { Role } from './Graph/generatedTypes';
import { Action } from './Actions/actionTypes';

function Admin(props: RouteComponentProps & DispatchProp<Action>) {
  const { dispatch } = props;

  useEffect(() => {
    dispatch({
      type: 'DONE_LOADING',
    });
  }, [dispatch]);

  return (
    <Protect fallback={<X.NotFound />} requiredRole={Role.Admin}>
      <ReachRouter>
        <X.Users path="/users" />
        <X.User path="/users/:id" />
        <X.NewUser path="/users/new" />
        <X.Fields path="/fields" />
        <X.EditField path="/fields/:id" />
        <X.NewField path="/fields/new" />
        <X.NotFound default />
      </ReachRouter>
    </Protect>
  );
}

export default connect()(Admin);
