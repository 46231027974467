import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { EmptyState } from '@shopify/polaris';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NotFound(props: RouteComponentProps): JSX.Element {
  return (
    <EmptyState
      heading="The page you're looking for couldn't be found"
      action={{
        content: 'Home',
        onAction: () => navigate('/'),
      }}
      image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
    >
      <p>This page does not exist</p>
    </EmptyState>
  );
}

export default NotFound;
