import gql from 'graphql-tag';
import { graphqlClient } from '../Graph';
import {
  User,
  UserCreateInput,
  CreateUserMutation,
  CreateUserMutationVariables,
} from '../Graph/generatedTypes';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($user: UserCreateInput!) {
    userCreate(user: $user) {
      id
      email
      role
    }
  }
`;

async function userCreate(user: UserCreateInput): Promise<User> {
  const { data } = await graphqlClient.mutate<
    CreateUserMutation,
    CreateUserMutationVariables
  >({
    mutation: CREATE_USER_MUTATION,
    variables: { user },
  });

  return data.userCreate;
}

export default userCreate;
