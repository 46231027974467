import serverFetch from '../Graph/serverFetch';
import { ProductColorSwatchResult } from '../types';

export const saveColorSwatchProduct = async (
  id: string,
  objectJson: any,
): Promise<ProductColorSwatchResult> => {
  // @todo use a URL object instead of string coercion
  // @todo add the status checking and parsing to server fetch
  // eslint-disable-next-line no-console
  const response = await serverFetch(`/api/colorswatch/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(objectJson),
  });
  try {
    return await response.json();
  } catch (error) {
    return error;
  }
};

export default saveColorSwatchProduct;
