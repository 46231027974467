import React from 'react';
import { Pagination, Card, Stack } from '@shopify/polaris';

interface Props {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  onRequestPageChange: (previous?: boolean) => void;
}

function BundlePagination({
  hasNextPage,
  hasPrevPage,
  onRequestPageChange,
}: Props) {
  return (
    <Card.Section>
      <Stack distribution="center">
        <Pagination
          hasNext={hasNextPage}
          hasPrevious={hasPrevPage}
          onNext={() => onRequestPageChange()}
          onPrevious={() => onRequestPageChange(true)}
        />
      </Stack>
    </Card.Section>
  );
}

export default BundlePagination;
