import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';

export interface State {
  open: boolean;
  error: boolean;
  content: string;
}

const defaultState: State = {
  open: false,
  error: false,
  content: '',
};

// @todo make more resilient by adding queue for multiple toasts
const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'SHOW_TOAST': {
      const error = action.payload.error === true;
      return { ...action.payload, open: true, error };
    }

    case 'HIDE_TOAST': {
      return defaultState;
    }

    default:
      return state;
  }
};

export default reducer;
