import React, { Component } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { connect } from 'react-redux';
import { Page, Modal, AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';

import { debounce } from 'lodash-es';

import { State as ReduxState } from '../../Reducers';

import BulkEdit from '../../Components/BulkEdit';
import { FieldColumn } from '../../Components/BulkEdit/FulfillmentTable';
import {
  loadBulkProducts as loadBulkProductsAction,
  loadBulkFields as loadBulkFieldsAction,
  updateBulkFields as updateBulkFieldsAction,
  deleteBulkFields as deleteBulkFieldsAction,
  showToast as showToastAction,
} from '../../Actions';
import {
  Field,
  BulkProductRow,
  FieldInput,
  FieldDefinition,
} from '../../Graph/generatedTypes';

interface Props {
  loading: boolean;
  productRows: BulkProductRow[];
  nextPageCursor: string | undefined;
  previousPageCursor: string | undefined;
  fieldDefinitions: FieldDefinition[];
  fieldsLoading: boolean;
  fieldsData: { [id: string]: Field[] };
  loadBulkProducts: typeof loadBulkProductsAction;
  loadBulkFields: typeof loadBulkFieldsAction;
  updateBulkFields: typeof updateBulkFieldsAction;
  deleteBulkFields: typeof deleteBulkFieldsAction;
  showToast: typeof showToastAction;
}

export type StagedField = Field & { fid: string; definitionId: string };

interface State {
  query: string;
  fields: string[];
  stagedFields: StagedField[];
  discardingChanges: boolean;
}

class Fulfillment extends Component<Props & RouteComponentProps, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    query: '',
    fields: [],
    stagedFields: [],
    discardingChanges: false,
  };

  componentDidMount() {
    // Load the default definitions (cached)
    const { fieldDefinitions } = this.props;
    if (fieldDefinitions && fieldDefinitions.length) {
      this.setDefaultFieldsSelected();
    }

    this.loadProducts();
  }

  componentDidUpdate({ fieldDefinitions: prevDefs }) {
    const { fieldDefinitions, loadBulkFields } = this.props;

    // Load the default definitions
    if (!prevDefs.length && fieldDefinitions.length) {
      loadBulkFields(this.setDefaultFieldsSelected());
    }
  }

  // eslint-disable-next-line react/sort-comp
  setDefaultFieldsSelected = (): string[] => {
    const { fieldDefinitions } = this.props;
    const fields = fieldDefinitions
      .filter(({ shownByDefault: d }) => d)
      .map(({ id }) => id);
    this.setState({ fields });
    return fields;
  };

  nextPage = () => {
    const { nextPageCursor } = this.props;

    if (!nextPageCursor) {
      // @todo error or warn logging
      return;
    }

    this.debouncedLoadProducts.cancel();
    this.loadProducts({ after: nextPageCursor });
  };

  prevPage = () => {
    const { previousPageCursor } = this.props;

    if (!previousPageCursor) {
      // @todo error or warn logging
      return;
    }

    this.debouncedLoadProducts.cancel();
    this.loadProducts({ before: previousPageCursor });
  };

  async loadProducts(props: { after?: string; before?: string } = {}) {
    const { loadBulkProducts, loadBulkFields } = this.props;
    const { query, fields } = this.state;

    this.setState({ stagedFields: [] });

    await loadBulkProducts({
      ...props,
      query,
      fieldIds: fields,
    });

    // eslint-disable-next-line react/destructuring-assignment
    await loadBulkFields(this.state.fields);
  }

  debouncedLoadProducts = debounce(this.loadProducts, 200);

  updateSelectedFields = (fields: string[]) => {
    const { loadBulkFields } = this.props;
    this.setState(({ stagedFields: oldFields }) => ({
      fields,
      stagedFields: oldFields.filter(({ definitionId }) => {
        return fields.includes(definitionId);
      }),
    }));

    loadBulkFields(fields);
  };

  updateQuery = (query: string) => {
    this.setState(
      {
        query,
      },
      this.debouncedLoadProducts,
    );
  };

  saveStagedFields = async () => {
    const { updateBulkFields, deleteBulkFields } = this.props;
    const { stagedFields, fields: idsToLoad }: State = this.state;

    const fields: FieldInput[] = stagedFields.map<FieldInput>(field => {
      return {
        metafieldId: field.id,
        definitionId: field.definitionId,
        parent: {
          id: field.parent.id,
          type: field.parent.type,
        },
        type: field.type,
        value: (field as any).value,
      };
    });
    const empty_fields:FieldInput[] = []
    const non_empty_fields:FieldInput[] = []

    fields.forEach(field => {
      //need to catch the case where 0 days is shown...
      if ((field as any).type === "TIME"){
        if (isNaN((field as any).value["interval"])){
          empty_fields.push(field)
        }
        else{
          non_empty_fields.push(field)
        }
      }
      else{
        if ((field as any).value === "" || (field as any).value == null) {
          empty_fields.push(field)
        }
        else{
          non_empty_fields.push(field)
        }
      }
    });
    
    /*Delete any empty fields as Shopify does not allow 
    for them to be updated with empty strings*/
    if (empty_fields.length > 0){
      await deleteBulkFields({ fields: empty_fields, idsToLoad });
    }
    if (non_empty_fields.length > 0){
      await updateBulkFields({ fields: non_empty_fields, idsToLoad });
    }
    this.setState({ stagedFields: [] });
  };

  handleDiscard = () => {
    const { showToast } = this.props;
    this.setState({ discardingChanges: false, stagedFields: [] });
    showToast({ content: 'Changes were successfully discarded' });
  };

  render() {
    const {
      loading,
      productRows,
      nextPageCursor,
      previousPageCursor,
      fieldDefinitions,
      fieldsLoading,
      fieldsData,
    } = this.props;
    const { fields, stagedFields, discardingChanges } = this.state;

    let fieldColumns: FieldColumn[] = [];
    if (!loading) {
      fieldColumns = fields.map(fieldId => {
        const definition = fieldDefinitions.find(({ id }) => id === fieldId);

        if (!definition) {
          // @todo handle
          throw new Error(`No definition found for "${fieldId}"`);
        }

        const values = fieldsData[fieldId] || [];
        // @todo this will break with two fields of the same type
        const stagedValues = values.map<Field>((field, i) => {
          const sf = stagedFields.find(({ fid }) => {
            return fid === `${definition.id}-${i}`;
          });

          if (!sf) {
            return field;
          }

          return sf;
        });

        return {
          loading: fieldsLoading,
          definition,
          values: stagedValues,
        };
      });
    }

    const canDiscard = !loading && !fieldsLoading && stagedFields.length > 0;

    return (
      <Page
        title="Metafields"
        fullWidth
        primaryAction={{
          content: 'Save',
          disabled: !canDiscard,
          onAction: this.saveStagedFields,
        }}
        secondaryActions={[
          {
            content: 'Discard Changes',
            onAction: () => this.setState({ discardingChanges: true }),
            disabled: !canDiscard,
          },
        ]}
        breadcrumbs={[{ onAction: () => navigate('/'), content: 'Dashboard' }]}
      >
        <BulkEdit
          loading={loading}
          fields={fieldDefinitions.map(def => ({
            id: def.id,
            title: def.title,
            selected: fields.includes(def.id),
          }))}
          onUpdateFields={newOptions =>
            this.updateSelectedFields(
              newOptions.filter(({ selected }) => selected).map(({ id }) => id),
            )
          }
          onUpdateQuery={this.updateQuery}
          hasNextPage={!!nextPageCursor}
          onNextPage={this.nextPage}
          hasPreviousPage={!!previousPageCursor}
          onPreviousPage={this.prevPage}
          // Legacy props @todo refactor
          rows={productRows}
          fieldColumns={fieldColumns}
          onFieldsUpdate={newFields => {
            this.setState(({ stagedFields: oldFields }) => {
              const newFids = newFields.map(({ fid }) => fid);
              const dedupedOldFields = oldFields.filter(
                ({ fid }) => !newFids.includes(fid),
              );

              return {
                stagedFields: dedupedOldFields.concat(newFields),
              };
            });
          }}
        />
        <AppProvider i18n={en}>
          <Modal
            open={discardingChanges}
            title="Discard all unsaved changes"
            onClose={() => this.setState({ discardingChanges: false })}
            primaryAction={{
              content: 'Discard Changes',
              onAction: () => this.handleDiscard(),
              destructive: true,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => this.setState({ discardingChanges: false }),
              },
            ]}
          >
            <Modal.Section>
              Are you sure you would like to discard all unsaved changes to
              metafields? This cannot be reversed.
            </Modal.Section>
          </Modal>
        </AppProvider>
      </Page>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  loading: state.status.loading,
  productRows: state.bulkProducts.rows,
  nextPageCursor: state.bulkProducts.nextPageCursor,
  previousPageCursor: state.bulkProducts.previousPageCursor,
  fieldDefinitions: state.fieldDefinitions,
  fieldsLoading: state.bulkFields.loading,
  fieldsData: state.bulkFields.types,
});

const mapDispatch = dispatch => ({
  loadBulkProducts: props => dispatch(loadBulkProductsAction(props)),
  loadBulkFields: props => dispatch(loadBulkFieldsAction(props)),
  updateBulkFields: props => dispatch(updateBulkFieldsAction(props)),
  deleteBulkFields: props => dispatch(deleteBulkFieldsAction(props)), 
  showToast: props => dispatch(showToastAction(props)),
});

export default connect(mapStateToProps, mapDispatch)(Fulfillment);
