import React from 'react';
import { Card, ResourceList } from '@shopify/polaris';

import BundleListItem from './BundleListItem';
import BundleListPagination from './BundleListPagination';

import { resourceName, filters } from './constants';

import { BundleOnly } from '../../types';

interface Props {
  loading?: boolean;
  items: BundleOnly[];
  onQueryChange: (newQuery: string) => void;
  onRequestPageChange: (previous?: boolean) => void;
  onRequestDiscard: (id: string) => void;
  onRequestEdit: (url: string) => void;
  onRequestView: (e: any, adminUrl: string) => void;
  query?: string;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
}

function BundlesTable({
  loading = false,
  items,
  query = '',
  onQueryChange,
  onRequestPageChange,
  onRequestDiscard,
  onRequestEdit,
  onRequestView,
  hasNextPage = false,
  hasPrevPage = false,
}: Props) {
  const filterControl = (
    <ResourceList.FilterControl
      filters={filters}
      appliedFilters={[]}
      onFiltersChange={() => undefined}
      searchValue={query}
      onSearchChange={onQueryChange}
    />
  );

  return (
    <Card>
      <ResourceList
        loading={loading}
        resourceName={resourceName}
        items={items}
        renderItem={props => (
          <BundleListItem
            {...props}
            onRequestDiscard={id => onRequestDiscard(id)}
            onRequestView={onRequestView}
            onRequestEdit={onRequestEdit}
          />
        )}
        filterControl={filterControl}
      />
      <BundleListPagination
        hasNextPage={!loading && hasNextPage}
        hasPrevPage={!loading && hasPrevPage}
        onRequestPageChange={onRequestPageChange}
      />
    </Card>
  );
}

export default BundlesTable;
