import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useReducer,
  Dispatch,
  useMemo,
} from 'react';
import { AppBridgeState, ClientApplication } from '@shopify/app-bridge';
import { Toast, TitleBar } from '@shopify/app-bridge/actions';

import reducer, { initialState, Action, ActionType } from './reducer';
import useCreateApp from './useCreateApp';
import { useAppBridge } from '@shopify/app-bridge-react';

interface BridgeHooksContext {
  dispatch: Dispatch<Action>;
  app: ClientApplication<AppBridgeState>;
}

export const BridgeHooksContext = createContext<BridgeHooksContext>({} as any);

interface ProviderProps {
  children: ReactNode;
}

function Provider(props: ProviderProps) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const shopify = useAppBridge();
  const app = useCreateApp();
  const [titleBar] = useState(() =>
    TitleBar.create(app, state.titleBarOptions),
  );

  useEffect(
    () =>
      app.error(({ type, message }) => {
        // Temporary workaround for issue with saveBar and titleBar
        // conflict. Not really a big deal and low priority to fix
        if (type !== 'APP::ERROR::PERMISSION') {
          // eslint-disable-next-line no-console
          console.warn('App bridge error', type, message);
        }
      }),
    [app],
  );

  // Loading
  useEffect(() => {
    if (state.loading) {
      shopify.loading(true);
    } else {
      shopify.loading(false);
    }
  }, [shopify, state.loading]);

  // Toast
  const [toast, setToast] = useState<Toast.Toast>();
  useEffect(() => {
    if (app && !toast && state.toastQueue.length) {
      const t = state.toastQueue[0];

      const polarisToast = Toast.create(app, {
        message: t.message,
        duration: t.duration,
        isError: t.error,
      });

      polarisToast.dispatch(Toast.Action.SHOW);

      setToast(polarisToast);

      dispatch({ type: ActionType.ToastPop, payload: { id: t.id } });
    }

    if (toast) {
      return toast.subscribe(Toast.Action.CLEAR, () => {
        setToast(undefined);
      });
    }
  }, [app, toast, state.toastQueue]);

  // Title bar
  const { titleBarOptions } = state;
  useEffect(() => {
    // interact with save bar as per note at bottom of this page
    // https://help.shopify.com/en/api/embedded-apps/app-bridge/actions/titlebar#update-title-bar-primary-secondary-buttons
    titleBar.set(titleBarOptions);
  }, [titleBar, titleBarOptions]);

  const response = useMemo(
    () => (
      <BridgeHooksContext.Provider value={{ dispatch, app }}>
        {children}
      </BridgeHooksContext.Provider>
    ),
    [app, children],
  );

  return response;
}

export default Provider;
