import React from 'react';
import {
  Page,
  FormLayout,
  Button,
  Banner,
  Card,
  Tooltip,
} from '@shopify/polaris';
import { withFormik, Form, FormikProps } from 'formik';
import { object, string } from 'yup';
import { TextField } from '../FormikPolaris';

interface FormValues {
  domain: string;
}

interface Props {
  loading: boolean;
  error: boolean;
  onSubmit: (values: any) => Promise<void> | void;

  /**
   * Domain to start the form with
   */
  initialDomain?: string;
  placeholder: string;
  messaging: {
    title: string;
    submitButton: string;
    infoBanner: string;
    errorBannerContent: string;
    toolTipContent: string;
  };
  // making above an object so that we can send more messaging in from routes as this compoenent gets used more widely.
}

const schema = object({
  domain: string()
    .min(2, 'Must be at least two characters long')
    .matches(/^[a-zA-Z0-9][-a-zA-Z0-9.]{0,}[a-zA-Z0-9]$/, 'Invalid character')
    .required('Required'),
});

function AuthenticationForm(props: Props & FormikProps<FormValues>) {
  const { loading, error, messaging, placeholder } = props;
  const {
    title,
    submitButton,
    infoBanner,
    errorBannerContent,
    toolTipContent,
  } = messaging;

  const errorBanner = error ? (
    <Banner title="Error" status="critical">
      {errorBannerContent}
    </Banner>
  ) : null;

  return (
    <Page title={title}>
      {errorBanner}
      <Banner title="Directions" status="info">
        <p>{infoBanner}</p>
      </Banner>
      <br />
      <br />
      <Card>
        <Card.Section>
          <Form>
            <FormLayout>
              <TextField
                name="domain"
                autoComplete={false}
                label="Store Domain"
                disabled={loading || error}
                placeholder={placeholder || 'my-shop'}
              />

              <Tooltip content={toolTipContent} active={false} light>
                <Button
                  submit
                  primary
                  loading={loading}
                  disabled={loading || error}
                >
                  {submitButton}
                </Button>
              </Tooltip>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>
    </Page>
  );
}

export default withFormik<Props, { domain: string }>({
  mapPropsToValues: ({ initialDomain: i }) => ({
    domain: i || '',
  }),
  handleSubmit: (values: FormValues, { props }) => props.onSubmit(values),
  validationSchema: schema,
  validateOnChange: false,
  validateOnBlur: true,
  enableReinitialize: true,
})(AuthenticationForm);
