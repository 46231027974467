const errorDefinitions = {
  missingParams: {
    title: 'Something Went Wrong',
    message:
      'Something went wrong while installing the App. Please try again. If the problem persists, please contact support.',
    primaryContent: 'Install',
    primaryUrl: '/',
    secondaryContent: '',
    secondaryUrl: '',
  },
  invalidDomain: {
    title: 'Invalid Domain',
    message:
      'The provided domain must be a valid *.myshopify.com shop. Please try again. If the problem persists, please contact support.',
    primaryContent: 'Install',
    primaryUrl: '/',
    secondaryContent: '',
    secondaryUrl: '',
  },
  invalidHMAC: {
    title: 'Invalid HMAC',
    message:
      'The provided HMAC key was invalid. Please try again. If the problem persists, please contact support.',
    primaryContent: 'Install',
    primaryUrl: '/',
    secondaryContent: '',
    secondaryUrl: '',
  },
  unregisteredDomain: {
    title: 'Unregistered Domain',
    message:
      'The provided domain has not been registered. Please try again. If the problem persists, please contact support.',
    primaryContent: 'Install',
    primaryUrl: '/',
    secondaryContent: '',
    secondaryUrl: '',
  },
  invalidEmail: {
    title: 'Invalid Email',
    message:
      'The provided email has not been whitelisted. If the problem persists, please contact support.',
    primaryContent: 'Install',
    primaryUrl: '/',
    secondaryContent: '',
    secondaryUrl: '',
  },
  invalidNonce: {
    title: 'Invalid Nonce',
    message:
      'There was a problem with your login. To reset your session, please navigate back to your admin panel and try again. If the problem persists, please contact support.',
    primaryContent: 'Shopify Admin',
    primaryUrl: '',
    secondaryContent: '',
    secondaryUrl: '',
  },
  expiredNonce: {
    title: 'Expired Nonce',
    message:
      'There was a problem with your login. To reset your session, please navigate back to your Shopify admin panel and try again. If the problem persists, please contact support.',
    primaryContent: 'Shopify Admin',
    primaryUrl: '',
    secondaryContent: '',
    secondaryUrl: '',
  },
  shopifyRejected: {
    title: 'Problem with Shopify',
    message:
      "Shopify rejected our request for installation. Please navigate back to your Shopify admin panel and try again later. You can keep up to date on Shopify's status from their status page",
    primaryContent: 'Shopify Admin',
    primaryUrl: '',
    secondaryContent: 'Shopify Status',
    secondaryUrl: 'https://status.shopify.com/',
  },
  shopifyMalformed: {
    title: 'Problem with Shopify',
    message:
      "Shopify sent back a malformed response. Please navigate back to your Shopify admin panel and try again later. You can keep up to date on Shopify's status from their status page",
    primaryContent: 'Shopify Admin',
    primaryUrl: '',
    secondaryContent: 'Shopify Status',
    secondaryUrl: 'https://status.shopify.com/',
  },
  'verify-email': {
    title: 'Unverified email',
    message:
      'This app can only be installed by a Shopify staff account with a verified email',
    primaryContent: 'Verify Now',
    primaryUrl: '',
    secondaryContent: '',
    secondaryUrl: '',
  },
};

export default errorDefinitions;
