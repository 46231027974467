import React from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';

import { Thumbnail } from '@shopify/polaris';
import styles from '../Fulfillment.module.css';
import { RowType } from '../../../Graph/generatedTypes';
import config from '../../../../src/config';

interface TitleCellProps {
  type: RowType;
  title: string;
  thumbnail?: string;
  id: string;
}

function TitleCell(props: TitleCellProps & ICellProps) {
  const { id, type, title, thumbnail, ...cellProps } = props;
  const adminUrl = `https://admin.shopify.com/store/${config.SHOPIFY_DOMAIN.replace('.myshopify.com', '')}/products/${id}`;

  if (type === RowType.Variant) {
    return (
      <Cell
        {...cellProps}
        style={{ ...cellProps.style, background: '#f5f6f7', color: '#798c9c' }}
        truncated
      >
        <>
          <div className={styles.cellSpacing}>
            <div className={styles.variableCellText}>{title}</div>
          </div>
        </>
      </Cell>
    );
  }

  return (
    <Cell
      {...cellProps}
      style={{ ...cellProps.style, background: '#f5f6f7' }}
      truncated
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => {
          window.open(adminUrl, '_blank');
        }}
      >
        <div className={styles.thumbnail}>
          <Thumbnail source={thumbnail} alt="" size="small" />
        </div>
        <div className={styles.cellText}>{title}</div>
      </a>
      {/* <span className={styles.productAvailability}> is product availabe at point of sale </span > */}
    </Cell>
  );
}

export default React.memo(TitleCell);
