import React from 'react';

import ProductPicker, { ExtendedProduct } from './ProductPicker';
import { BundleProduct } from '../../../types';

interface ComponentError {
  message: string;
  id: string;
  title: string;
}

interface Props {
  open: boolean;
  parentId: string;
  existingChildIds: string[];
  onCancel: () => void;
  onSelection: (added: BundleProduct[], errors?: ComponentError[]) => void;
}

class ComponentPicker extends React.Component<Props> {
  handleSelection = (products: ExtendedProduct[]) => {
    const { parentId, existingChildIds, onSelection } = this.props;

    const errors = [];

    const validProducts = products.filter(({ id, title, tags }) => {
      // Current parent
      if (id === parentId) {
        errors.push({
          id,
          title,
          message: 'A bundle may not contain itself',
        });
        return false;
      }

      // Other parent
      if (tags.includes('#.bundle:parent')) {
        errors.push({
          id,
          title,
          message: 'A bundle may not another bundle',
        });
        return false;
      }

      // Existing child
      if (existingChildIds.includes(id)) {
        errors.push({
          id,
          title,
          message: 'Component already exists in this bundle',
        });
        return false;
      }

      return true;
    });

    onSelection(validProducts, errors.length ? errors : undefined);
  };

  render() {
    const { open, onCancel } = this.props;

    return (
      <ProductPicker
        open={open}
        onSelection={this.handleSelection}
        onCancel={onCancel}
      />
    );
  }
}

export default ComponentPicker;
