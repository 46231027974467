import React, { Component } from 'react';
import { TextField, TextFieldProps } from '@shopify/polaris';

interface Props {
  amount: number;
  onAmountUpdate(number): void;
  currency?: string;
}

function priceFormat(value: number): string {
  const amountMoney = value !== 0 ? (value / 100).toFixed(2) : '0.00';
  return amountMoney.toString();
}

function parseNumber(value: string): { valid: boolean; value: number } {
  const [rawDollars, rawCents] = value.split('.');

  const dollars = Number(rawDollars);
  const cents = Number((rawCents || '0').substring(0, 2));

  if (Number.isNaN(dollars) || Number.isNaN(cents)) {
    return {
      valid: false,
      value: NaN,
    };
  }
  return {
    valid: true,
    value: dollars * 100 + cents,
  };
}

// @todo
class MoneyInput extends Component<Props & TextFieldProps & any> {
  static defaultProps = {
    currency: 'USD',
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: priceFormat(this.props.amount),
    valid: true,
  };

  updateValue = newValue => {
    const { onAmountUpdate } = this.props;
    const { valid, value } = parseNumber(newValue);

    if (!valid) {
      this.setState({ value: newValue, valid: false });
    } else {
      this.setState({ value: newValue, valid: true });
      onAmountUpdate(value);
    }
  };

  render() {
    const { value, valid } = this.state;
    const { onBlur, ...props } = this.props;

    // @todo convert currency to prefix / suffix

    return (
      <TextField
        {...(props as any)} // @todo
        value={value}
        onChange={this.updateValue}
        type="number"
        prefix="$"
        onBlur={(...p) => {
          if (valid) {
            const { value: blurValue } = parseNumber(value);
            this.setState({
              value: priceFormat(blurValue),
            });
          }
          // eslint-disable-next-line no-unused-expressions
          onBlur && onBlur(...p);
        }}
      />
    );
  }
}

export default MoneyInput;
