import React from 'react';
import { Field, getIn, FieldProps } from 'formik';
import {
  Checkbox as PolarisCheckbox,
  CheckboxProps as PolarisCheckboxProps,
} from '@shopify/polaris';
import { Omit } from '@reach/router';

interface CheckboxProps<V = any> {
  /**
   * The field identifier that formik can use to
   * connect this field to the data. Will also be
   * used as the polaris id
   */
  name: string;

  /**
   * Parses the formik value into a type that can be
   * recognized by the checkbox
   */
  decode?: (value: V) => boolean;

  /**
   * Takes the new checked and encodes it
   * into the type stored in formik
   */
  encode?: (checked: boolean) => V;
}

type PolarisProps = Omit<
  PolarisCheckboxProps,
  'checked' | 'onChange' | 'onBlur' | 'error'
>;

function CheckboxField<V = any>(props: CheckboxProps<V> & PolarisProps) {
  const { name, decode, encode, ...polarisProps } = props;

  return (
    <Field
      name={name}
      render={({
        field,
        form: { setFieldValue, setFieldError, errors, touched },
      }: FieldProps) => {
        let error;
        try {
          if (getIn(touched, name)) {
            error = getIn(errors, name);
          }
        } catch (e) {
          throw new Error(
            `Formik errors object is in an abnormal state, Checkbox "${name}" could not check it's error state`,
          );
        }

        return (
          <PolarisCheckbox
            {...polarisProps}
            id={name}
            checked={decode ? decode(field.value) : field.value}
            onFocus={() => setFieldError(name, undefined)}
            onBlur={() => field.onBlur({ target: { name } })}
            onChange={value => {
              if (polarisProps.disabled) {
                return;
              }
              setFieldValue(name, encode ? encode(value) : value);
            }}
            error={error}
          />
        );
      }}
    />
  );
}

export default CheckboxField;
