import React from 'react';
import {
  DisplayText,
  Tooltip,
  Stack,
  Card,
  Button,
  ButtonGroup,
  TextField,
  Banner,
} from '@shopify/polaris';
import { TickSmallMinor } from '@shopify/polaris-icons';
import { FieldProps, Field, getIn } from 'formik';

import ConfirmedButton from '../Common/ConfirmedButton';
import BundleItems from './BundleItems';

import '../BundlesList/ButtonGroups.css';
import styles from './BundleSet.module.css';

import { BundleSet as BS } from '../../types';
import DropZone from './DropZone';

interface Props {
  parent: string;
  index: number;
  last: boolean;
  set: BS;
  dragging: boolean;
  errors: any;
  setDragging: (d: boolean) => void;
  onRequestDelete: () => void;
  onCollapse: (index: number) => void;
  move: (originId: string, target: [number, number]) => void;
}

interface State {
  editingLabel: boolean;
}

class BundleSet extends React.Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    editingLabel: false,
  };

  render() {
    const {
      parent,
      index,
      last,
      dragging,
      set: { label, contents },
      onRequestDelete,
      onCollapse,
      move,
      setDragging,
      errors,
    } = this.props;
    const { editingLabel } = this.state;

    const name = `${parent}.${index}`;
    const collapsible = contents.length === 1;

    let errorMarkup;
    try {
      const error = getIn(errors, `${name}.contents_error`);
      errorMarkup = typeof error === 'string' && (
        <Banner title="Invalid set configuration" status="critical">
          <p>{error}</p>
        </Banner>
      );
    } catch (e) {
      // Intentionally empty
    }

    return (
      <>
        {dragging && index === 0 && (
          <DropZone target={[index, 0]} move={move} />
        )}
        <div className="setWrapper">
          <div className={styles.header}>
            <Card.Section>
              <Stack distribution="equalSpacing" alignment="center">
                {editingLabel ? (
                  <Stack.Item>
                    <Field name={`${name}.label`}>
                      {({
                        field,
                        form: { setFieldValue, status, errors: errs },
                      }: FieldProps<string>) => {
                        let error;

                        try {
                          error = getIn(errs, `${field.name}_error`);
                        } catch (e) {
                          // Intentionally empty
                        }

                        return (
                          <>
                            <TextField
                              id={field.name}
                              value={field.value}
                              onChange={val => {
                                setFieldValue(
                                  field.name as any, // @todo
                                  val,
                                );
                              }}
                              label="Set Name"
                              labelHidden
                              disabled={status.saving}
                              error={error}
                              onBlur={() =>
                                field.onBlur({ target: { name: field.name } })
                              }
                              connectedRight={
                                <Button
                                  icon={TickSmallMinor}
                                  outline
                                  disabled={error}
                                  onClick={() =>
                                    this.setState({ editingLabel: false })
                                  }
                                />
                              }
                            />
                          </>
                        );
                      }}
                    </Field>
                  </Stack.Item>
                ) : (
                  <Stack.Item>
                    <DisplayText size="medium">
                      <span className={styles.setTitle}>
                        {label}&nbsp;&nbsp;
                      </span>
                      <Button
                        outline
                        onClick={() => this.setState({ editingLabel: true })}
                      >
                        Edit
                      </Button>
                    </DisplayText>
                  </Stack.Item>
                )}
                <ButtonGroup segmented>
                  <Tooltip
                    content={
                      collapsible
                        ? 'Convert back into a product'
                        : 'You may only collapse a set if it contains 1 product only'
                    }
                    light
                  >
                    <div className="segmentedButtonLeft">
                      <Button
                        outline
                        disabled={!collapsible}
                        onClick={() => onCollapse(index)}
                      >
                        Collapse Set
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip content="Discard Bundle Set" active={false} light>
                    <div className="segmentedButtonRight">
                      <ConfirmedButton
                        saving={false}
                        type="SET"
                        handleDelete={() => {
                          onRequestDelete();
                        }}
                      />
                    </div>
                  </Tooltip>
                </ButtonGroup>
              </Stack>
            </Card.Section>
            {errorMarkup}
          </div>
          {dragging && contents.length === 0 && (
            <DropZone target={[index, 1]} move={move} />
          )}
          <BundleItems
            name={`${name}.contents`}
            items={contents}
            inSet
            dragging={dragging}
            setDragging={setDragging}
          />
        </div>
        {dragging && last && <DropZone target={[index + 1, 0]} move={move} />}
      </>
    );
  }
}

export default BundleSet;
