import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';
import { Page, PageActions, EmptyState } from '@shopify/polaris';

import {
  loadBundle as loadBundleAction,
  updateBundle as updateBundleAction,
  showToast as showToastAction,
} from '../../Actions';
import { State as ReduxState } from '../../Reducers';
import { Bundle } from '../../types';
import BundleForm, { SkeletonBundleForm } from '../../Components/BundleForm';

interface Props {
  loadBundle: typeof loadBundleAction;
  saveBundle: typeof updateBundleAction;
  showToast: typeof showToastAction;
  bundle: Bundle;
  loading: boolean;
  saving: boolean;
  error: boolean;
}

interface State {
  disableSave: boolean;
}

class EditBundle extends Component<
  Props & RouteComponentProps<{ bundleId: string }>,
  State
> {
  // eslint-disable-next-line react/sort-comp
  submit = () => {};

  // eslint-disable-next-line react/state-in-constructor
  state = {
    disableSave: true,
  };

  async componentDidMount() {
    const { loadBundle, bundleId } = this.props;
    await loadBundle({ id: bundleId });
  }

  render() {
    const {
      loading,
      error,
      bundle,
      saveBundle,
      saving,
      showToast,
    } = this.props;
    const { disableSave } = this.state;

    // @todo extract and make more useful
    if (error) {
      return (
        <EmptyState
          heading="Error loading bundle"
          action={{
            content: 'Try again',
            onAction: () => window.location.reload(),
          }}
          image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          <p>An error occurred while attempting to load the bundle</p>
        </EmptyState>
      );
    }

    if (loading && !saving) {
      // @todo remove the page from this
      // component and render it here
      return <SkeletonBundleForm />;
    }

    return (
      <Page
        title={bundle.title}
        primaryAction={{
          content: 'Save',
          disabled: saving,
          onAction: () => this.submit(),
        }}
        secondaryActions={[
          { content: 'Cancel', onAction: () => navigate('/') },
        ]}
        breadcrumbs={[
          { onAction: () => navigate('/bundles'), content: 'Bundles' },
        ]}
      >
        <BundleForm
          intitialBundle={bundle}
          submit={submit => {
            this.submit = submit;
          }}
          onSubmit={saveBundle}
          onSavableChange={dirty => this.setState({ disableSave: !dirty })}
          saving={saving}
          onFeedback={content => showToast({ content })}
        />
        <PageActions
          primaryAction={{
            content: 'Save',
            disabled: disableSave,
            loading: saving,
            onAction: () => this.submit(),
          }}
        />
      </Page>
    );
  }
}

const mapStateToProps = ({
  bundle: { current, saving },
  status: { loading, error },
}: ReduxState) => ({
  bundle: current,
  loading,
  saving,
  error,
});

const mapDispatch = dispatch => ({
  loadBundle: props => dispatch(loadBundleAction(props)),
  saveBundle: props => dispatch(updateBundleAction(props)),
  showToast: props => dispatch(showToastAction(props)),
});

export default connect(mapStateToProps, mapDispatch)(EditBundle);
