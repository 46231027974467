import { AsyncAction } from './index';

import { addDiscarded } from '../persist';
import { loadBundles, showToast } from '.';

interface Props {
  id: string;
}

export const discardBundle: AsyncAction = ({ id }: Props) => async (
  dispatch,
  getState,
  { serverFetch },
) => {
  // @todo define action types with typescript
  dispatch({
    type: 'DISCARD_BUNDLE_START',
    payload: null,
  });

  // @todo add the status checking and parsing to server fetch
  const response = await serverFetch(`/api/bundles/${id}`, {
    method: 'delete',
  });

  if (!response.ok) {
    dispatch({
      type: 'DISCARD_BUNDLE_ERROR',
      payload: null,
    });
    return dispatch(
      showToast({
        content: 'Error discarding bundle',
        error: true,
      }),
    );
  }

  dispatch({
    type: 'DISCARD_BUNDLE_COMPLETE',
    payload: { id },
  });

  dispatch(
    showToast({
      content: 'Bundle was successfully discarded',
    }),
  );

  // Make sure this bundle doesn't show up again
  addDiscarded(id);

  // Load bundles
  return dispatch(loadBundles({ query: '' }));
};

export default discardBundle;
