import gql from 'graphql-tag';
import { AsyncAction } from './index';
import {
  DeleteBulkFieldsMutation,
  DeleteBulkFieldsMutationVariables
} from '../Graph/generatedTypes';
import loadBulkFields from './loadBulkFields';

const DeleteBulkFields = gql`
  mutation deleteBulkFields($fields: [FieldInput!]!) {
    deleteBulkFields(fields: $fields)
  }
`;

export const deleteBulkFields: AsyncAction = ({
  idsToLoad,
  ...args
}: DeleteBulkFieldsMutationVariables & { idsToLoad: string[] }) => async (
  dispatch,
  getState,
  { graphqlClient },
) => {
  dispatch({
    type: 'DELETE_BULK_FIELDS_START',
  });

  try {
    // @todo handle response
    await graphqlClient.mutate<
      DeleteBulkFieldsMutation,
      DeleteBulkFieldsMutationVariables
    >({
      mutation: DeleteBulkFields,
      variables: args,
    });

    dispatch(loadBulkFields(idsToLoad));
  } catch (e) {
    return dispatch({
      type: 'DELETE_BULK_FIELDS_ERROR',
      payload: {
        error: e,
      },
    });
  }
};

export default deleteBulkFields;
