import React, { Component } from 'react';
import { Button, Modal } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';

// @todo move to props
const TYPES = {
  BUNDLE: {
    title: 'You are about to discard a bundle',
    message:
      'Are you sure you want to remove all configuration for this bundle?',
    confirmation: 'Discard Bundle',
  },
  PRODUCT: {
    title: 'You are about to remove a product',
    message:
      'Are you sure you want to remove this product from your bundle configuration?',
    confirmation: 'Remove Product',
  },
  SET: {
    title: 'You are about to remove a set',
    message:
      'Are you sure you want to remove this set from your bundle configuration? All products in this set will also be removed',
    confirmation: 'Remove Set',
  },
};

interface Props {
  saving?: boolean;
  type: string;
  handleDelete: (id: string) => void;
  id?: string;
  onCancel?: () => void;
}

interface State {
  modalOpen: boolean;
}

class ConfirmedButton extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    modalOpen: false,
  };

  delete = () => {
    const { handleDelete, id } = this.props;
    handleDelete(id);
  };

  render() {
    const { saving, type } = this.props;
    const { modalOpen } = this.state;
    const { title, message, confirmation } = TYPES[type];

    return (
      <div>
        <div className="segmentedButtonRight">
          <Button
            icon={DeleteMinor}
            destructive
            disabled={saving || false}
            outline
            onClick={() => this.setState({ modalOpen: true })}
          />
        </div>
        <Modal
          open={modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          title={title}
          message={message}
          primaryAction={{
            content: confirmation,
            onAction: () => {
              this.setState({ modalOpen: false });
              this.delete();
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => this.setState({ modalOpen: false }),
            },
          ]}
        />
      </div>
    );
  }
}

export default ConfirmedButton;
