import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';

export interface State {
  // @todo
  current: any;
  saving: boolean;
}

const defaultState: State = {
  current: {},
  saving: false,
};

const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_BUNDLE_COMPLETE': {
      return {
        current: action.payload.bundle,
        saving: false,
      };
    }
    case 'UPDATE_BUNDLE_COMPLETE': {
      return {
        current: action.payload.bundle,
        saving: false,
      };
    }
    case 'UPDATE_BUNDLE': {
      return {
        current: action.payload.bundle,
        saving: false,
      };
    }
    case 'UPDATE_BUNDLE_START': {
      return {
        ...state,
        saving: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
