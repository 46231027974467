import gql from 'graphql-tag';
import { AsyncAction } from './index';
import {
  UpdateBulkFieldsMutation,
  UpdateBulkFieldsMutationVariables,
} from '../Graph/generatedTypes';
import loadBulkFields from './loadBulkFields';

// updateBulkEdits are called when we save the values on the metafileds product table.
// Adding DropdownField values as we added one more option.
const UpdateBulkFields = gql`
  mutation updateBulkFields($fields: [FieldInput!]!) {
    updateBulkFields(fields: $fields) {
      ... on IField {
        id
        parent {
          id
          type
        }
        type
      }
      ... on TimeField {
        value
      }
      ... on DateField {
        value
      }
      ... on DropdownField {
        value
      }
    }
  }
`;

export const updateBulkFields: AsyncAction = ({
  idsToLoad,
  ...args
}: UpdateBulkFieldsMutationVariables & { idsToLoad: string[] }) => async (
  dispatch,
  getState,
  { graphqlClient },
) => {
  dispatch({
    type: 'UPDATE_BULK_FIELDS_START',
  });

  try {
    // @todo handle response
    await graphqlClient.mutate<
      UpdateBulkFieldsMutation,
      UpdateBulkFieldsMutationVariables
    >({
      mutation: UpdateBulkFields,
      variables: args,
    });

    dispatch(loadBulkFields(idsToLoad));
  } catch (e) {
    return dispatch({
      type: 'UPDATE_BULK_FIELDS_ERROR',
      payload: {
        error: e,
      },
    });
  }
};

export default updateBulkFields;
