import React, { Component } from 'react';
import { withFormik, Form, FormikProps } from 'formik';
import { Stack, Card, Layout, TextStyle, Icon } from '@shopify/polaris';
import SCHEMA from './schema';
import { CheckboxField, TextField } from '../FormikPolaris';
import { UserCreateInput, User, Role } from '../../Graph/generatedTypes';

type FormValues = UserCreateInput;

interface UsersFormProps {
  /**
   * If we are editing the current
   * user. Should disable admin select
   */
  me?: boolean;

  /**
   * If the form is loading
   */
  loading?: boolean;

  /**
   * The users currently in the database
   */
  initialUser?: User;

  /**
   * Alert the parent when a change has been made
   */
  onDirtyUpdate?: (dirty: boolean) => void;

  /**
   * Callback for valid submissions
   */
  onSubmit: (values: FormValues) => Promise<void> | void;

  /**
   * This ref allows the parent component to
   * request a submit. Makes having external
   * submit button possible
   */
  submit: (s: () => void) => void;
}

class UsersForm extends Component<UsersFormProps & FormikProps<FormValues>> {
  componentDidMount() {
    // Pass the submission ability to parent
    const { submit } = this.props;
    if (submit) {
      submit(this.submit);
    }
  }

  componentDidUpdate({ dirty: prevDirty }) {
    const { dirty, onDirtyUpdate } = this.props;

    // @todo fix this getting messed up by delete
    if (prevDirty !== dirty) {
      if (onDirtyUpdate) {
        onDirtyUpdate(dirty);
      }
    }
  }

  submit = () => {
    // Submits the form with formik
    // eslint-disable-next-line react/destructuring-assignment
    this.props.submitForm();
  };

  render() {
    const { loading, initialUser, me = false } = this.props;

    return (
      <Form>
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <TextField
                  name="email"
                  label="Email"
                  disabled={loading || !!initialUser}
                  helpText="Must match the Shopify staff account email"
                />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card subdued sectioned>
              {me && (
                <>
                  <Stack spacing="tight">
                    <Icon source="alert" />
                    <TextStyle variation="strong">
                      You may not edit your own role
                    </TextStyle>
                  </Stack>
                  <br />
                </>
              )}
              <CheckboxField<Role>
                name="role"
                label="Admin"
                disabled={loading || me}
                decode={role => role === Role.Admin}
                encode={checked => (checked ? Role.Admin : Role.Staff)}
                helpText="Admins have access to the admin panel and permission to install the app on Shopify stores"
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Form>
    );
  }
}

export default withFormik<UsersFormProps, FormValues>({
  mapPropsToValues: ({ initialUser: i }) => ({
    email: i ? i.email : '',
    role: i ? i.role : Role.Staff,
  }),
  handleSubmit: (values, { props, setSubmitting }) =>
    Promise.resolve(props.onSubmit(values))
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false)),
  validationSchema: SCHEMA,
  isInitialValid: true,
  validateOnChange: false,
  validateOnBlur: true,
  enableReinitialize: true,
})(UsersForm);
