import React, { useCallback } from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';
import { Popover, Button, Stack } from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import styles from '../Fulfillment.module.css';
import { DropDownCellPicker } from '../Pickers';

const DropDownComponent = props => {
  const { initialTextValue, onClear } = props;
  if (!initialTextValue) return <div />;

  return (
    <div className={styles.singleTextCellWithButton}>
      <Stack distribution="equalSpacing" alignment="center">
        <Stack.Item>{initialTextValue}</Stack.Item>
        <Stack.Item>
          <div
            style={{ zIndex: 100 }}
          >
            <Button
              onClick={onClear}
              destructive
              size="slim"
              icon={CancelSmallMinor}
              outline
            />
          </div>
        </Stack.Item>
      </Stack>
    </div>
  );
}

/**
 * @interface DropDownCellProps
 * initialTextValue - Carry the already selected value if any.
 * editing - make sure the cell is editable or not 
 * dropdownValues - Carry all the Selection Option values belongs to this dropdown.
 * allowMultiple - Carry the boolean value for the allowMultiple attribute on this dropdown.
 * onChange - callback function when selection updated.
 * onCancelEdit - callback to inform the cancel operation.
 */
interface DropDownCellProps {
  initialTextValue: string | undefined;
  editing: boolean;
  dropdownValues: string[] | null;
  allowMultiple: boolean | true;
  onChange: (newTextValue: string) => void;
  onCancelEdit: () => void;
}

/**
 * This is a react component for multiselect option on a popover.
 *
 * @param {(ICellProps & DropDownCellProps)} props
 * @returns React component.
 */
function DropDownCell(props: ICellProps & DropDownCellProps) {
  const {
    initialTextValue,
    editing,
    onChange,
    dropdownValues,
    allowMultiple,
    onCancelEdit,
    ...cellProps
  } = props;

  const handleClearDate = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const content = <DropDownComponent initialTextValue={initialTextValue} onClear={handleClearDate} />;

  return (
    <div className="string-cell">
      <Cell {...cellProps} wrapText>
        <Popover
          activator={content}
          active={editing}
          onClose={onCancelEdit}
          preferredPosition="below"
        >
          <DropDownCellPicker
            initialTextValue={initialTextValue}
            onChangeString={onChange}
            dropdownValues={dropdownValues}
            allowMultiple={allowMultiple}
            editing={editing}
          />
        </Popover>
      </Cell>
    </div>
  );
}

export default React.memo(DropDownCell);
