import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from 'react';
import gql from 'graphql-tag';
import { navigate } from '@reach/router';
import { Redirect } from '@shopify/app-bridge/actions';
import * as Sentry from '@sentry/browser';
import { graphqlClient } from '../Graph';
import { User, Role } from '../Graph/generatedTypes';
import config from '../config';
import useCreateApp from '../BridgeHooks/useCreateApp';
import { Loading } from '@shopify/polaris';

interface AuthContext {
  user?: User;
  timezone?: string;
}

export const AuthContext = createContext<AuthContext>({});

const LOGIN_MUTATION = gql`
  mutation Login($shop: String!, $redirect: Redirect!) {
    login(shop: $shop, redirect: $redirect) {
      installed
      authenticated
      redirect
    }
  }
`;

const STATUS_QUERY = gql`
  query Status {
    app {
      setupRequired
      timezone
    }
    me {
      id
      email
      role
    }
  }
`;

async function setup(): Promise<string | AuthContext> {
  if (config.IN_IFRAME) {
    const loginResponse = await graphqlClient.mutate({
      mutation: LOGIN_MUTATION,
      variables: {
        shop: config.SHOPIFY_DOMAIN,
        redirect: {
          path: window.location.pathname,
          target: 'APP',
        },
      },
    });

    // Shop need to be installed
    if (!loginResponse.data.login.installed) {
      return `https://${window.location.host}`;
    }

    if (!loginResponse.data.login.authenticated) {
      return loginResponse.data.login.redirect;
    }
  }

  const statusResponse = await graphqlClient.query({
    query: STATUS_QUERY,
  });

  if (statusResponse.data.app.setupRequired) {
    return `https://${window.location.host}/setup`;
  }

  return {
    user: statusResponse.data.me,
    timezone: statusResponse.data.app.timezone,
  };
}

export function useTimezone() {
  const { timezone } = useContext(AuthContext);

  if (!timezone) {
    throw new Error('No provider');
  }

  return timezone;
}

interface ProtectProps {
  children: any;
  fallback: any;
  requiredRole?: Role;
}

export function Protect(props: ProtectProps) {
  const { children, fallback, requiredRole = Role.Staff } = props;
  const { user } = useContext(AuthContext);

  if (!user || (requiredRole === Role.Admin && user.role !== Role.Admin)) {
    return fallback;
  }

  return children;
}

function AuthProvider({ children }: any) {
  const app = useCreateApp();
  const redirect = Redirect.create(app)
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [timezone, setTimezone] = useState('America/New_York');

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const u = url.searchParams.get('user');

    if (token && u) {
      localStorage.setItem('DEFAULT_USER', u);
      sessionStorage.setItem('USER', u);
      localStorage.setItem(u, token);

      // Clean up the URL
      if (!config.IN_IFRAME) {
        url.searchParams.delete('token');
        url.searchParams.delete('user');
        window.history.replaceState(url.toString(), 'Admin', url.toString());
      }
    } else {
      const uu =
        sessionStorage.getItem('USER') || localStorage.getItem('DEFAULT_USER');

      if (uu) {
        sessionStorage.setItem('USER', u);
      }
    }
  }, []);

  useEffect(() => {
    setup()
      .then(r => {
        if (typeof r !== 'string') {
          setUser(r.user);
          setTimezone(r.timezone);

          Sentry.configureScope(scope => {
            scope.setUser({
              id: r.user.id,
              email: r.user.email,
              role: r.user.role,
            });
          });
          setLoading(false);
        } else if (config.IN_IFRAME) {
          redirect.dispatch(
           Redirect.Action.REMOTE,
           r
          );
        } else {
          navigate(r);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.warn(e);
        throw e;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authContext = useMemo(
    () => ({
      timezone,
      user,
    }),
    [user, timezone],
  );

  // @todo improved loading indicator
  if (loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
