import React, { Component } from 'react';

import {
  ResourceList,
  Heading,
  Stack,
  Card,
  ButtonGroup,
  Tooltip,
  Button,
  Thumbnail,
  Checkbox,
  SkeletonBodyText,
} from '@shopify/polaris';
import { ViewMinor } from '@shopify/polaris-icons';
import { Field, FieldProps } from 'formik';

import { ProductDDProps, ProductDD } from './draggable';

import { BundleProduct as BP } from '../../types';
import BundleVariant from './BundleVariant';
import ConfirmedButton from '../Common/ConfirmedButton';

export interface BundleProductProps {
  parentPath: string;
  index: number;
  product: BP;
  saving: boolean;
  dragging: boolean;
  inSet?: boolean;
  hasSet?: boolean;
  onRequestDelete: () => void;
  onConvertToSet: (index: number) => void;
  setDragging: (dragging: boolean) => void;
  move: (props: { originId: string; targetId: string }) => void;
}

class BundleProduct extends Component<BundleProductProps & ProductDDProps> {
  render() {
    const {
      parentPath,
      index,
      product: { id, title, thumbnail, adminUrl, variants },
      saving,
      dragging,
      inSet,
      hasSet,
      onRequestDelete,
      onConvertToSet,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    const media = (
      <Thumbnail source={thumbnail} alt={`Image for ${title} product`} />
    );

    const variantsMarkup = variants.map((variant, i) => (
      <BundleVariant
        key={variant.id}
        collapsed={dragging}
        variant={variant}
        index={i}
        parent={`${parentPath}.${index}.variants`}
      />
    ));

    const name = `${parentPath}.${index}`;

    return connectDropTarget(
      <div>
        <ResourceList.Item
          id={id}
          media={media}
          onClick={() => undefined}
          accessibilityLabel={`Display details of bundle product ${title}`}
        >
          {isDragging ? (
            <SkeletonBodyText lines={2} />
          ) : (
            <Card>
              {connectDragSource(
                <div>
                  <Card.Section>
                    <div className="dragCursor">
                      <div>
                        <Stack distribution="equalSpacing" alignment="center">
                          <Stack.Item fill>
                            <Heading element="h3">{title}</Heading>
                          </Stack.Item>

                          <Field name={`${name}.reportable`}>
                            {({
                              field,
                              form: { setFieldValue },
                            }: FieldProps<boolean>) => (
                              <Checkbox
                                id={field.name}
                                checked={!field.value}
                                disabled={saving}
                                label="Non Reportable"
                                onChange={newReportable =>
                                  setFieldValue(
                                    field.name as any, // @todo
                                    !newReportable,
                                  )
                                }
                              />
                            )}
                          </Field>
                          <Field name={`${name}.bundleOnly`}>
                            {({
                              field,
                              form: { setFieldValue },
                            }: FieldProps<boolean>) => (
                              <Checkbox
                                id={field.name}
                                checked={field.value}
                                disabled={saving}
                                label="Bundle Only"
                                onChange={newBundleOnly =>
                                  setFieldValue(
                                    field.name as any, // @todo
                                    newBundleOnly,
                                  )
                                }
                              />
                            )}
                          </Field>

                          <ButtonGroup segmented>
                            {!inSet && (
                              <Tooltip
                                content={
                                  hasSet
                                    ? 'Only 1 set per bundle'
                                    : 'Convert product to a set'
                                }
                                active={false}
                                light
                              >
                                <div className="segmentedButtonLeft">
                                  <Button
                                    outline
                                    disabled={hasSet || saving}
                                    onClick={() => onConvertToSet(index)}
                                  >
                                    Convert to Set
                                  </Button>
                                </div>
                              </Tooltip>
                            )}
                            <Tooltip
                              content="Open product in Shopify admin."
                              active={false}
                              light
                            >
                              <div
                                className={
                                  inSet
                                    ? 'segmentedButtonLeft'
                                    : 'segmentedButtonMiddle'
                                }
                              >
                                <Button
                                  icon={ViewMinor}
                                  outline
                                  disabled={saving}
                                  onClick={
                                    (() => {
                                      window.open(adminUrl, '_blank');
                                    }) as () => void
                                  }
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              content="Remove product from bundle."
                              active={false}
                              light
                            >
                              <ConfirmedButton
                                saving={saving}
                                type="PRODUCT"
                                id={id}
                                handleDelete={() => {
                                  onRequestDelete();
                                }}
                              />
                            </Tooltip>
                          </ButtonGroup>
                        </Stack>
                      </div>
                    </div>
                  </Card.Section>
                </div>,
              )}
              {variantsMarkup}
            </Card>
          )}
        </ResourceList.Item>
      </div>,
    );
  }
}

export default ProductDD(BundleProduct as any);
