import React from 'react';
import { Checkbox } from '@shopify/polaris';

interface Props {
  initialValue: boolean;
  onChange: (val: boolean) => void;
}

function BooleanCellPicker(props: Props) {
  const { initialValue, onChange } = props;
  const [checked, setChecked] = React.useState(initialValue);

  return (
    <div style={{ padding: '1em', width: '100%', maxWidth: '275px' }}>
      <Checkbox
        checked={checked}
        label=""
        onChange={value => {
          setChecked(value);
          onChange(value);
        }}
      />
    </div>
  );
}

export default BooleanCellPicker;
