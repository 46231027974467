import { object, string } from 'yup';

export const SCHEMA = object({
  email: string()
    .email('Must be a valid email')
    .lowercase()
    .required('Required'),
});

export default SCHEMA;
