import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';
import { FieldDefinition } from '../Graph/generatedTypes';

export type State = FieldDefinition[];

const defaultState: State = [];

const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_FIELD_DEFINITIONS_COMPLETE': {
      return action.payload.definitions;
    }
    default:
      return state;
  }
};

export default reducer;
