import React, { useState, useEffect, useContext, useCallback } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { Page } from '@shopify/polaris';
import { StatusContext } from '../../Context';
import { User, UserCreateInput } from '../../Graph/generatedTypes';
import { userLoad, userUpdate } from '../../Data';
import { AuthContext } from '../../Components/AuthGate';
import UserForm from '../../Components/UserForm';
import { useAppBridge } from '@shopify/app-bridge-react';

function UserRoute(props: RouteComponentProps<{ id: string }>) {
  const { showToast } = useContext(StatusContext);
  const { user: loggedInUser } = useContext(AuthContext);

  const { id } = props;

  const [form, setForm] = useState({ submit: () => {} });
  const [dirty, setDirty] = useState(false);

  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const shopify = useAppBridge();
  useEffect(() => {
    if(!loading){
      shopify.loading(false);
    }else{
      shopify.loading(true);
    }
  },[loading, shopify]);

  const handleSubmit = useCallback(
    async (u: UserCreateInput) => {
      setSaving(true);
      userUpdate({ id, ...u })
        .then(uu => {
          setSaving(false);
          showToast('User updated');
          setUser(uu);
        })
        .catch(e => {
          setSaving(false);
          // eslint-disable-next-line no-console
          console.warn(e);
          showToast('Error updating user', true);
        });
    },
    [id, showToast],
  );

  useEffect(() => {
    userLoad(id)
      .then(u => {
        setUser(u);
        setLoading(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.warn(e);
        showToast(`Unable to find user with id "${id}"`, true);
      });
  }, [id, showToast]);

  return (
    <Page
      title="Edit User"
      primaryAction={{
        content: 'Save',
        onAction: form.submit,
        disabled: !dirty,
        loading: saving,
      }}
      breadcrumbs={[
        {
          onAction: () => navigate('/admin/users'),
          content: 'Users',
        },
      ]}
    >
      <UserForm
        me={id === loggedInUser.id}
        loading={loading}
        initialUser={user}
        onSubmit={handleSubmit}
        onDirtyUpdate={setDirty}
        submit={submit => setForm({ submit })}
      />
    </Page>
  );
}

export default UserRoute;
