import React, { useMemo, useState, useCallback, useContext } from 'react';
import {
  ResourceList,
  EmptyState,
  Modal,
  TextStyle,
  AppProvider,
} from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import gql from 'graphql-tag';
import { navigate } from '@reach/router';
import FieldsListItem from './FieldsListItem';
import { FieldDefinition } from '../../Graph/generatedTypes';
import { graphqlClient } from '../../Graph';
import { StatusContext } from '../../Context';

export const PAGE_TITLE = 'Field Definitions';

const FIELD_DEF_DELETE_MUTATION = gql`
  mutation DeleteFieldDefinition($id: ID!) {
    fieldDefinitionDelete(id: $id) {
      id
    }
  }
`;

async function fieldDefinitionDelete(id: string) {
  await graphqlClient.mutate({
    mutation: FIELD_DEF_DELETE_MUTATION,
    variables: {
      id,
    },
  });
}

interface FieldListProps {
  error: boolean;
  fields: FieldDefinition[];

  /**
   * When a field has been deleted
   */
  onFieldDeleted: (id: string) => void;
}

function FieldsList(props: FieldListProps) {
  const { error, fields, onFieldDeleted } = props;

  const { showToast } = useContext(StatusContext);

  // Id of field being deleted
  const [deletingField, setDeleting] = useState<FieldDefinition | undefined>();
  const [modalOpen, setModalOpen] = useState(false);

  const handleRequestDelete = useCallback((field: FieldDefinition) => {
    setDeleting(field);
    setModalOpen(true);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleting(undefined);
    setModalOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    setModalOpen(false);
    fieldDefinitionDelete(deletingField.id)
      .then(() => {
        onFieldDeleted(deletingField.id);
        showToast(`${deletingField.title} deleted`);
        setDeleting(undefined);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.warn(e);
        showToast(`Error deleting ${deletingField.title}`, true);
      });
  }, [deletingField, onFieldDeleted, showToast]);

  const errorState = useMemo(
    () => (
      <EmptyState
        heading="Error loading fields"
        action={{
          content: 'Try again',
          onAction: () => window.location.reload(),
        }}
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      />
    ),
    [],
  );

  const emptyState = useMemo(
    () => (
      <EmptyState
        heading="No field definitions"
        action={{
          content: 'Add first definition',
          onAction: () => navigate('/admin/fields/new'),
        }}
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      />
    ),
    [],
  );

  if (error) {
    return errorState;
  }

  if (!fields.length) {
    return emptyState;
  }

  return (
    <>
      <ResourceList
        resourceName={{ singular: 'Field', plural: 'Fields' }}
        items={fields}
        renderItem={field => {
          return (
            <FieldsListItem
              field={field}
              disabled={!!deletingField}
              deleting={deletingField && deletingField.id === field.id}
              onRequestDelete={handleRequestDelete}
            />
          );
        }}
      />
      <AppProvider i18n={en}>
        <Modal
          open={modalOpen}
          primaryAction={{
            destructive: true,
            content: 'Delete field',
            onAction: handleDelete,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCancelDelete,
            },
          ]}
          onClose={handleCancelDelete}
          title="Confirm field deletion"
        >
          <Modal.Section>
            Are you sure you want to delete{' '}
            <TextStyle variation="strong">
              {deletingField ? deletingField.title : ''}
            </TextStyle>
            ?
          </Modal.Section>
        </Modal>
      </AppProvider>
    </>
  );
}

export default FieldsList;
