import React from 'react';
import { Stack, TextStyle, Checkbox } from '@shopify/polaris';
import { BundleItem } from '../../types';
import { Money } from '../Common';

interface PriceRange {
  min: number;
  max: number;
}

const MoneyRange = ({ min, max }: PriceRange): JSX.Element => {
  if (min === max) {
    return <Money amount={min} />;
  }

  return (
    <>
      <Money amount={min} /> - <Money amount={max} />
    </>
  );
};

interface Props {
  items: BundleItem[];
  onSelectionChange(checked: boolean) : any;
  checked?: boolean
}

function BundleSummary({ items, checked, onSelectionChange }: Props): JSX.Element {
  if (items.length === 0) {
    return null;
  }

  const {
    regular: regularPriceRange,
    bundle: bundlePriceRange,
  } = items.reduce<{
    regular: PriceRange;
    bundle: PriceRange;
  }>(
    ({ regular, bundle }, item) => {
      const rRange: number[] = [];
      const bRange: number[] = [];

      if (item.type === 'product') {
        item.variants.forEach(({ salePrice, bundlePrice }) => {
          rRange.push(salePrice);
          bRange.push(bundlePrice);
        });
      } else {
        item.contents.forEach(({ variants }) => {
          variants.forEach(({ salePrice, bundlePrice }) => {
            rRange.push(salePrice);
            bRange.push(bundlePrice);
          });
        });
      }

      // @todo find better way to handle empty set
      if (rRange.length === 0) {
        rRange.push(0);
      }

      if (bRange.length === 0) {
        bRange.push(0);
      }

      return {
        regular: {
          min: regular.min + Math.min(...rRange),
          max: regular.max + Math.max(...rRange),
        },
        bundle: {
          min: bundle.min + Math.min(...bRange),
          max: bundle.max + Math.max(...bRange),
        },
      };
    },
    { regular: { min: 0, max: 0 }, bundle: { min: 0, max: 0 } },
  );

  const minPercentOff = Math.round(
    ((regularPriceRange.min - bundlePriceRange.max) / regularPriceRange.min) *
      100,
  );

  const maxPercentOff = Math.round(
    ((regularPriceRange.max - bundlePriceRange.min) / regularPriceRange.max) *
      100,
  );

  const discount =
    // eslint-disable-next-line no-nested-ternary
    minPercentOff < 0
      ? 'No Discount'
      : minPercentOff === maxPercentOff
      ? `${minPercentOff}%`
      : `${minPercentOff}% - ${maxPercentOff}%`;

  const uniqueItemsCount = items.length;

  return (
    <Stack  distribution="fill"  alignment="center">
      <span>
        Regular Price:
        <TextStyle variation="strong">
          {' '}
          <MoneyRange {...regularPriceRange} />
        </TextStyle>
      </span>
      <span>
        Bundle Price:
        <TextStyle variation="strong">
          {' '}
          <MoneyRange {...bundlePriceRange} />
        </TextStyle>
      </span>
      <span>
        Discount: <TextStyle variation="strong">{discount}</TextStyle>
      </span>
      <span>
        Total Items:
        <TextStyle variation="strong"> {uniqueItemsCount}</TextStyle>
      </span>
      <Stack.Item>
      <Checkbox
              id="showbundlecomponents"
              checked={!!checked}
              label="Show bundle components?"
              onChange={onSelectionChange}
            />
     </Stack.Item>
    </Stack>
  );
}

export default BundleSummary;
