import React, { useState } from 'react';

import {
  Spinner,
  EmptySearchResult as PolarisEmptySearchResult,
} from '@shopify/polaris';

import {
  Column,
  Table,
  SelectionModes,
  ICellInterval,
  RenderMode,
  IRegion,
} from '@blueprintjs/table';
import { FocusStyleManager } from '@blueprintjs/core';

// For some reason this is needed to stop the
// table from greying out?? Could be a dev thing
import '@shopify/polaris/styles.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/core/src/blueprint.scss';

import styles from './Fulfillment.module.css';
import {
  FieldDefinition,
  Field,
  BulkProductRow,
  StringField,
  IntField,
  TimeField,
  DateField,
  BooleanField,
} from '../../Graph/generatedTypes';
import fieldRenderer from './fieldRenderer';
import titleRenderer from './titleRenderer';
import skuRenderer from './skuRenderer';
import {
  normalizeSelections,
  isSingleSelection,
  selectionsContainCell,
} from './Utils';
import { StagedField } from '../../Routes/App/Fulfillment';

interface EmptySearchResultProps {
  show: boolean;
}

function EmptySearchResult({ show }: EmptySearchResultProps) {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.emptySearch}>
      <PolarisEmptySearchResult
        title="No products found"
        description="Try changing the filters or search term"
        withIllustration
      />
    </div>
  );
}

export interface FieldColumn {
  loading: boolean;
  definition: FieldDefinition;
  values: Field[];
}

FocusStyleManager.onlyShowFocusOnTabs();

interface Props {
  rows: BulkProductRow[];
  loading: boolean;
  fieldColumns: FieldColumn[];
  onFieldsUpdate: (fields: StagedField[]) => void;
}

function FulfillmentTable(props: Props) {
  const { fieldColumns, rows, loading, onFieldsUpdate } = props;
  const [selectedRegions, setSelectedRegions] = useState<IRegion[]>([]);
  const [editingCell, setEditingCell] = useState<[number, number] | null>(null);

  const handleSelection = newSelections => {
    // Edit instead
    if (isSingleSelection(newSelections)) {
      const cell: ICellInterval = [
        newSelections[0].cols[0],
        newSelections[0].rows[0],
      ];

      if (selectionsContainCell(selectedRegions, cell)) {
        setEditingCell(cell);
        return;
      }
    }

    setSelectedRegions(normalizeSelections(newSelections));
  };

  const handleDoneEditing = React.useCallback(() => setEditingCell(null), [
    setEditingCell,
  ]);

  const handleFieldUpdate = React.useCallback(
    (field: StringField | IntField | TimeField | DateField | BooleanField) => {
      setEditingCell(null);

      const updatedFields = selectedRegions.reduce<StagedField[]>(
        (acc, { rows: rs, cols }) => {
          const f: StagedField[] = [];

          const colValues = fieldColumns[cols[0] - 2];

          for (let i = rs[0]; i <= rs[1]; i += 1) {
            const v = colValues.values[i];

            if (v.type === field.type) {
              f.push({
                ...v,
                value: (field as any).value,
                fid: `${colValues.definition.id}-${i}`,
                definitionId: colValues.definition.id,
              } as StagedField);
            }
          }

          return acc.concat(f);
        },
        [],
      );

      onFieldsUpdate(updatedFields);
    },
    [setEditingCell, selectedRegions, fieldColumns, onFieldsUpdate],
  );

  // @todo should be extracted / cleaned up
  const fieldColumnsMarkup = fieldColumns.map(
    ({ loading: l, definition, values }) => (
      <Column
        key={definition.id}
        name={definition.title}
        cellRenderer={fieldRenderer({
          loading: l,
          fields: values,
          editingCell,
          definitionId: definition.id,
          // passing the dropdown values as a prop. We will get this value from redux state.
          dropdownValues: definition.dropDownValues,
          onDoneEditing: handleDoneEditing,
          onFieldUpdate: handleFieldUpdate,
        })}
      />
    ),
  );

  if (loading) {
    return (
      <div className="fulfillment-table">
        <div className={styles.spinnerPosition}>
          <Spinner size="large" color="teal" />
        </div>
      </div>
    );
  }

  return (
    <div className="fulfillment-table">
      <Table
        numRows={loading ? 50 : rows.length}
        defaultRowHeight={52}
        enableGhostCells
        enableRowHeader={false}
        onSelection={handleSelection}
        selectedRegions={selectedRegions}
        selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        defaultColumnWidth={275}
        renderMode={RenderMode.NONE}
        numFrozenColumns={1}
      >
        <Column name="Title" cellRenderer={titleRenderer({ rows })} />
        <Column name="SKU" cellRenderer={skuRenderer({ rows })} />
        {fieldColumnsMarkup as any}
      </Table>
      <EmptySearchResult show={rows.length === 0} />
    </div>
  );
}

export default FulfillmentTable;
