interface Persisted {
  id: string;
  exp: number;
}

const KEY = 'DISCARDED_PRODUCTS';
const EXP_OFFSET = 1000 * 60 * 3; // 3min

const expire = (d: Persisted[]): Persisted[] =>
  d.filter(({ exp }) => Date.now() < exp);

const persist = (d: Persisted[]) => {
  window.sessionStorage.setItem(KEY, JSON.stringify(d));
};

const load = (): Persisted[] => {
  const raw = window.sessionStorage.getItem(KEY);

  try {
    const discarded = raw ? JSON.parse(raw) : [];
    return expire(discarded);
  } catch (e) {
    return [];
  }
};

let discarded: Persisted[] = load();

const updateDiscarded = () => {
  discarded = expire(discarded);
  persist(discarded);
};

export const addDiscarded = (id: string) => {
  updateDiscarded();
  discarded = [
    ...discarded.filter(({ id: i }) => i !== id),
    { id, exp: Date.now() + EXP_OFFSET },
  ];
  persist(discarded);
};

export const removeDiscarded = (id: string) => {
  updateDiscarded();
  discarded = [...expire(discarded).filter(({ id: i }) => i !== id)];
  persist(discarded);
};

export const isDiscarded = (id: string): boolean => {
  updateDiscarded();
  return expire(discarded)
    .map(({ id: i }) => i)
    .includes(id);
};
