import React, { useState, useEffect, useCallback, useContext } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { Page } from '@shopify/polaris';
import { StatusContext } from '../../Context';
import UsersList from '../../Components/UsersList';
import { usersLoad, userDelete } from '../../Data';
import { User } from '../../Graph/generatedTypes';
import { useAppBridge } from '@shopify/app-bridge-react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Users(props: RouteComponentProps) {
  const { showToast } = useContext(StatusContext);

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const shopify = useAppBridge();

  const handleDeleteUser = useCallback(
    async (id: string) => {
      await userDelete(id);
      showToast(`User deleted`);
      setUsers(us => us.filter(user => user.id !== id));
    },
    [showToast],
  );

  useEffect(() => {
    usersLoad()
      .then(u => {
        setUsers(u);
        setLoading(false);
        shopify.loading(false);
      })
      .catch(setError);
  }, [shopify]);

  if (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }

  return (
    <Page
      title="Users"
      primaryAction={{
        content: 'New User',
        onAction: () => navigate('/admin/users/new'),
      }}
      breadcrumbs={[
        {
          onAction: () => navigate('/'),
          content: 'Dashboard',
        },
      ]}
    >
      <UsersList loading={loading} users={users} onDelete={handleDeleteUser} />
    </Page>
  );
}

export default Users;
