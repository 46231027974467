import React from 'react';

import { ReadOnlyCell, NeverCell } from './Cells';

import {
  BulkProductRow,
  RowType,
  SingleProductRow,
  VariantRow,
} from '../../Graph/generatedTypes';

interface SKURendererProps {
  rows: BulkProductRow[];
}

const skuRenderer = ({ rows }: SKURendererProps) => (y: number) => {
  const row = rows[y];

  // @todo check the type more
  if (row.type === RowType.Product) {
    return <NeverCell />;
  }

  return (
    <ReadOnlyCell>{(row as SingleProductRow | VariantRow).sku}</ReadOnlyCell>
  );
};

export default skuRenderer;
