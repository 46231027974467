import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';
import { BundleOnly } from '../types';

export interface State {
  currentPage: BundleOnly[];
  nextPage?: string | null;
  prevPage?: string | null;
}

const defaultState: State = {
  currentPage: [],
  nextPage: null,
  prevPage: null,
};

const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_BUNDLES_COMPLETE': {
      return {
        currentPage: action.payload.bundles,
        nextPage: action.payload.nextPage,
        prevPage: action.payload.prevPage,
      };
    }
    default:
      return state;
  }
};

export default reducer;
