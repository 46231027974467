import { useContext, useEffect, useState } from 'react';
import { Button } from '@shopify/app-bridge/actions';
import { BridgeHooksContext } from './Provider';
import { ActionType } from './reducer';

export interface UseTitleBarProps {
  title?: string;
  breadcrumb?: {
    label: string;
    onClick: () => void;
  };
}

function useTitleBar({ title, breadcrumb = {} as any }: UseTitleBarProps) {
  const { dispatch, app } = useContext(BridgeHooksContext);

  const { label, onClick } = breadcrumb;
  const [breadCrumbButton] = useState(
    Button.create(app, { label: label || '' }),
  );

  useEffect(() => {
    breadCrumbButton.set({ label: label || '' });

    const unsubscribe = breadCrumbButton.subscribe(Button.Action.CLICK, () => {
      onClick();
    });

    return () => {
      unsubscribe();
    };
  }, [breadCrumbButton, label, onClick]);

  useEffect(() => {
    dispatch({
      type: ActionType.TitleBarUpdate,
      payload: {
        title,
        breadcrumbs: label ? breadCrumbButton : undefined,
      },
    });
  }, [dispatch, title, label, breadCrumbButton]);

  // Remove on unmount
  useEffect(
    () => () => {
      dispatch({
        type: ActionType.TitleBarUpdate,
        payload: {
          title: '',
          breadcrumbs: undefined,
        },
      });
    },
    [dispatch],
  );
}

export default useTitleBar;
