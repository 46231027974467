// Constants for use in BackInStock
const DESCRIPTIONS = {
    'dotdigital_account_id': 'DotDigital Account ID',
    'confirmation_template_id': 'Confirmation Template ID',
    'backinstock_template_id': 'BackInStock Template ID',
    'instock_threshold': 'InStock Threshold'
};

const METAFIELD_DEFINITION_KEY = 'instock_threshold';

const METAFIELD_DEFINITION_TITLE_PREFIX = 'InStock Threshold';

const METAFIELD_DEFINITION_TITLE_PRODUCT = `${METAFIELD_DEFINITION_TITLE_PREFIX} (Per Product)`;

const METAFIELD_DEFINITION_TITLE_VARIANT = `${METAFIELD_DEFINITION_TITLE_PREFIX} (Per Variant)`;

const METAFIELD_DEFINITIONS_BUTTON_TEXT = 'Create Product/Variant Definitions';

const METAFIELD_DEFINITIONS_MESSAGE = 'Product or Variant specific Thresholds can be set on the product or variant page respectively.';

const NAMESPACE = 'umg__back_in_stock';

const OWNER_RESOURCE = 'shop';

const TOOL_TIP = 'Save Back in Stock metafields';

export default {
    DESCRIPTIONS,
    METAFIELD_DEFINITION_KEY,
    METAFIELD_DEFINITION_TITLE_PRODUCT,
    METAFIELD_DEFINITION_TITLE_VARIANT,
    METAFIELD_DEFINITIONS_BUTTON_TEXT,
    METAFIELD_DEFINITIONS_MESSAGE,
    NAMESPACE,
    OWNER_RESOURCE,
    TOOL_TIP
};
