import { lazy, object, array, number, string } from 'yup';

const PRODUCT_SCHEMA = object({
  variants: array().of(
    object({
      bundlePrice: number()
        .required()
        .min(0, 'Negative')
        .integer()
        .when('salePrice', (salePrice, schema) =>
          schema.max(salePrice, 'Greater than original price'),
        ),
    }),
  ),
});

const SET_SCHEMA = object({
  label: string()
    .min(3, 'Too short')
    .required('Required'),
  contents: array()
    .of(PRODUCT_SCHEMA)
    .min(2, 'A set must contain at least two products'),
});

const ITEM_SCHEMA = lazy(({ type }) =>
  type === 'set' ? SET_SCHEMA : PRODUCT_SCHEMA,
);

const SCHEMA = object({
  contents: array()
    .of(ITEM_SCHEMA)
    .min(2, 'A bundle must contain at least two items'),
});

export default SCHEMA;
