import {
  ConnectDropTarget,
  ConnectDragPreview,
  DropTargetMonitor,
  ConnectDragSource,
  DragSource,
  DropTarget,
} from 'react-dnd';
import { debounce } from 'lodash-es';
import { Component } from 'react';
import { BundleProductProps } from './BundleProduct';
import { DropZoneProps } from './DropZone';

enum DraggableTypes {
  PRODUCT = 'product',
  SET = 'set',
}

/**
 * Handle product dragging
 */
interface ProductSourceCollectedProps {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  isDragging: boolean;
}

const productSource = {
  canDrag(props: BundleProductProps) {
    return !props.saving;
  },

  beginDrag(props: BundleProductProps) {
    // Setting timeout to delay endDrag firing on all but first product;
    setTimeout(() => props.setDragging(true), 100);

    return { id: props.product.id, saving: props.saving };
  },

  endDrag({ setDragging }: BundleProductProps) {
    setDragging(false);
  },
};

const connectProductDrag = (connect, monitor): ProductSourceCollectedProps => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const DragProduct = DragSource(
  DraggableTypes.PRODUCT,
  productSource,
  connectProductDrag,
);

/**
 * Handle product dropping
 */
export interface DropTargetProps {
  connectDropTarget: ConnectDropTarget;
}

const lastHover = { originId: '', id: '', index: -1 };
const debouncedMove = debounce((move, args) => move(args), 50, {
  trailing: true,
});
const productTarget = {
  canDrop() {
    return false;
  },

  hover(
    { move, index, product: { id } }: BundleProductProps,
    monitor: DropTargetMonitor,
  ) {
    const { id: originId } = monitor.getItem();

    const cancel =
      lastHover.originId === originId &&
      lastHover.id === id &&
      lastHover.index === index;

    if (cancel) {
      return;
    }

    lastHover.originId = originId;
    lastHover.id = id;
    lastHover.index = index;

    if (originId !== id) {
      debouncedMove(move, { originId, targetId: id });
    }
  },
};

const connectDrop = (connect): DropTargetProps => ({
  connectDropTarget: connect.dropTarget(),
});

export const DropProduct = DropTarget(
  DraggableTypes.PRODUCT,
  productTarget,
  connectDrop,
);

export type ProductDDProps = ProductSourceCollectedProps & DropTargetProps;

export const ProductDD = (e: Component<BundleProductProps & ProductDDProps>) =>
  DropProduct(DragProduct(e as any));

/**
 * Handle dropzones
 */
const zoneTarget = {
  canDrop() {
    return false;
  },

  hover({ target, move }: DropZoneProps, monitor: DropTargetMonitor) {
    const { id } = monitor.getItem();

    // Debounce
    move(id, target);
  },
};

export const DropZoneDD = DropTarget(
  DraggableTypes.PRODUCT,
  zoneTarget,
  connectDrop,
);
