import React from 'react';
import { ButtonGroup, Button, Tooltip } from '@shopify/polaris';
import { HorizontalDotsMinor, ViewMinor } from '@shopify/polaris-icons';
import './ButtonGroups.css';
import './BundleListItemControls.css';
import ConfirmedButton from '../Common/ConfirmedButton';

interface Props {
  id: string;
  url: string;
  title: string;
  adminUrl: string;
  onClick?: () => void;
  onRequestDiscard: (id: string) => void;
  onRequestEdit: (url: string) => void;
  onRequestView: (e: any, adminUrl: string) => void;
}

const BundleListItemControls = ({
  id,
  url,
  title,
  adminUrl,
  onRequestDiscard,
  onRequestEdit,
  onRequestView,
}: Props): JSX.Element => {
  return (
    <ButtonGroup segmented>
      <Tooltip
        content="Edit bundle configuration."
        active={false}
        light
        preferredPosition="mostSpace"
      >
        <div className="segmentedButtonLeft">
          <Button
            icon={HorizontalDotsMinor}
            accessibilityLabel={`Edit Bundle ${title}`}
            outline
            onClick={() => onRequestEdit(url)}
          />
        </div>
      </Tooltip>
      <Tooltip
        content="View bundle product in Shopify."
        active={false}
        light
        preferredPosition="mostSpace"
      >
        <div className="segmentedButtonMiddle">
          <Button
            icon={ViewMinor}
            outline
            accessibilityLabel={`View ${title} bundle in shopify admin`}
            onClick={
              (e => {
                onRequestView(e, adminUrl);
              }) as () => void
            }
          />
        </div>
      </Tooltip>
      <Tooltip
        content="Remove bundle product configuration."
        active={false}
        light
        preferredPosition="mostSpace"
      >
        <ConfirmedButton
          type="BUNDLE"
          handleDelete={() => onRequestDiscard(id)}
          id={id}
        />
      </Tooltip>
    </ButtonGroup>
  );
};

export default BundleListItemControls;
