import React, { useCallback } from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';
import { Popover, Button, Stack } from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';

import styles from '../Fulfillment.module.css';
import { DateCellPicker } from '../Pickers';
import { formatTZ, 
  getISODateTimestamp, 
  getTimeStampFromISO } from '../../../Utils';
import { useTimezone } from '../../AuthGate';

interface DateProps {
  date?: number;
  onClear: () => void;
}

function DateComponent(props: DateProps) {
  const { date, onClear } = props;
  const timezone = useTimezone();

  if (!date) {
    return (
      <div className={styles.singleTextCellWithButton}>
        <Stack distribution="equalSpacing" alignment="center">
          <Stack.Item />
        </Stack>
      </div>
    );
  }

  const formattedDate = formatTZ(date, timezone);

  return (
    <div className={styles.singleTextCellWithButton}>
      <Stack distribution="equalSpacing" alignment="center">
        <Stack.Item>{formattedDate}</Stack.Item>
        <Stack.Item>
          <div
            style={{ zIndex: 100 }}
            title={`${new Date(date).toDateString()} local time`}
          >
            <Button
              onClick={onClear}
              destructive
              size="slim"
              icon={CancelSmallMinor}
              outline
            />
          </div>
        </Stack.Item>
      </Stack>
    </div>
  );
}

interface DateCellProps {
  date: number | undefined;
  editing: boolean;
  onDateUpdate: (date: number) => void;
  onCancelEdit: () => void;
}

function DateCell(props: DateCellProps & ICellProps) {
  const timezone = useTimezone();

  const { date, editing, onDateUpdate, onCancelEdit, ...cellProps } = props;

  const handleClearDate = useCallback(() => {
    onDateUpdate(undefined);
  }, [onDateUpdate]);

  const handleUpdateDate = useCallback(
    (timestamp: number) => {
      // Removes tz offset
      onDateUpdate(getISODateTimestamp(timestamp, timezone));
    },
    [onDateUpdate, timezone],
  );

  const content = <DateComponent date={getTimeStampFromISO(timezone, date)} onClear={handleClearDate} />;

  return (
    <div className="date-picker-cell">
      <Cell {...cellProps} truncated>
        <Popover
          activator={content}
          active={editing}
          onClose={onCancelEdit}
          preferredPosition="below"
        >
          <DateCellPicker
            date={getTimeStampFromISO(timezone, date)}
            onDateUpdate={handleUpdateDate}
            onCancel={onCancelEdit}
          />
        </Popover>
      </Cell>
    </div>
  );
}

export default React.memo(DateCell);
