import React from 'react';

import { TextField, Select, Button } from '@shopify/polaris';
import { TickSmallMinor } from '@shopify/polaris-icons';
import { differenceInDays } from 'date-fns';
import styles from '../Fulfillment.module.css';

enum TimeUnit {
  Day = 'DAY',
  Week = 'WEEK',
}

const DAY = 24 * 60 * 60 * 1000;
const WEEK = DAY * 7;

function convertToUTC(number: number, unit: TimeUnit): number {
  switch (unit) {
    case TimeUnit.Day:
      return number * DAY;
    case TimeUnit.Week:
      return number * WEEK;
    default:
      throw new Error(`Connot convert unit ${unit} to UTC`);
  }
}

interface Props {
  onTimeUpdate: (interval: number, unit: 'DAY' | 'WEEK') => void;
  initialInterval: number;
  initialUnit: 'DAY' | 'WEEK';
}

function TimeCellPicker(props: Props) {
  const { onTimeUpdate, initialInterval, initialUnit } = props;

  const days = differenceInDays(initialInterval, 0);
  const [number, setNumber] = React.useState(
    initialUnit !== 'WEEK' ? days : days / 7,
  );
  const [unit, setUnit] = React.useState(initialUnit);

  return (
    <div className={styles.popoverContent}>
      <div className={styles.popoverContentInput}>
        <TextField
          label="Time Interval"
          labelHidden
          type="number"
          value={`${number}`}
          onChange={num => setNumber(Number.parseInt(num, 10))}
          connectedRight={
            <Select
              label="Time unit"
              labelHidden
              onChange={u => setUnit(u as TimeUnit)}
              value={unit}
              options={[
                {
                  label: number === 1 ? 'Day' : 'Days',
                  value: TimeUnit.Day,
                },
                {
                  label: number === 1 ? 'Week' : 'Weeks',
                  value: TimeUnit.Week,
                },
              ]}
            />
          }
        />
      </div>
      <div className={styles.confirmPopoverButton}>
        <Button
          primary
          icon={TickSmallMinor}
          onClick={() => {
            onTimeUpdate(
              convertToUTC(
                number,
                unit === 'WEEK' ? TimeUnit.Week : TimeUnit.Day,
              ),
              unit,
            );
          }}
        />
      </div>
    </div>
  );
}

export default TimeCellPicker;
