import React, { useState, useEffect, useCallback, useContext } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Banner, Button, Page, Card, Select } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import { default as FieldForm } from '../../../Components/BackInStockForm';
import { FormValues } from '../../../Components/BackInStockForm/FieldForm';
import { MetafieldType, OwnerType, ShopMetafield } from '../../../Graph/generatedTypes';

import BackInStockGraph from './backInStockGraph';
import Constants from './constants';
import { StatusContext } from '../../../Context';
import VVFieldForm from '../../../Components/BackInStockForm/VVFieldForm';
import backInStockGraph from './backInStockGraph';

const BackInStock = (props: RouteComponentProps) => {
  const { showToast } = useContext(StatusContext);
  const [formFields, setFormFields] = useState<FormValues>();
  // For checking the ID of metafields caching all the metafields
  const [metafields, setMetafields] = useState<Array<ShopMetafield>>([]);
  const [hasMetafieldDefinitions, setHasMetafieldDefinitions] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string>("dot_digital");
  const dispatch = useDispatch();

  const options = [
    {label: 'Dot Digtial', value: 'dot_digital'},
    {label: 'Vice Versa', value: 'vice_versa'},
  ];

  const initializeShopMetafields = useCallback(
    async () => {
      // Attempt to load shopMetafields
      try {
        const shopMetafields = await BackInStockGraph.loadShopMetafields()
        if (!!shopMetafields.edges.length) {
          deconstructShopMetafieldArrayObject(shopMetafields.edges.map(edge => edge.node));
          setLoading(false);
        }
      } catch(e) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to load shopMetafields. ${e}`);
        setLoading(false);
        showToast('Error loading Back in Stock shop metafields', true);
      }
      setLoading(false);
    },
    [showToast],
  );

  const initializeMetafieldDefinitions = useCallback(
    async (ownerType) => {
      // Attempt to load fieldDefintionsByNamespace
      try {
        const metafieldDefintions = await BackInStockGraph.loadMetafieldDefinitions(250, Constants.NAMESPACE, ownerType)
        if (!!metafieldDefintions.edges.length) {
          setHasMetafieldDefinitions(true);
          setLoading(false);
        }
      } catch(e) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to load Back in Stock metafield definitions. ${e}`);
        setHasMetafieldDefinitions(false);
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    setLoading(true);
    initializeShopMetafields();
  }, [initializeShopMetafields]);

  useEffect(() => {
    Object.values(OwnerType).map(ownerType => initializeMetafieldDefinitions(ownerType));
  }, [initializeMetafieldDefinitions]);


  useEffect(() => {
    dispatch({
      type: 'DONE_LOADING',
    });
  },[dispatch]);


  const deconstructShopMetafieldArrayObject = nodes => {
    const obj = {};
    nodes.forEach(node => obj[node.key] = node.value);
    setMetafields(nodes);

    if((obj as FormValues).viceversa_id) {
      setSelectedTag("vice_versa")
      const filterObj = {};
      const filterkeys = Object.keys(obj).filter(key => key === 'viceversa_id' || key === 'instock_threshold')
      filterkeys.forEach(key => filterObj[key] = obj[key])
      setFormFields(filterObj as FormValues);
    }else {
      setSelectedTag("dot_digital")
      const filterObj = {};
      const filterkeys = Object.keys(obj).filter(key => key !== 'viceversa_id')
      filterkeys.forEach(key => filterObj[key] = obj[key])
      setFormFields(filterObj as FormValues);
    }
  };

  const createShopMetafieldArrayObject = fields => {
    const entries = Object.entries(fields).map(field => {
      // Checking metafields are already there. if yes, updating the ID. otherwise create a new metafields
      const filterKey = metafields.filter((metafield) => metafield.key === field[0])
      if (filterKey.length > 0) {
        return {
          id : filterKey[0].id,
          key: field[0],
          description: !!Constants.DESCRIPTIONS[field[0]] ? Constants.DESCRIPTIONS[field[0]] : field[0],
          value: field[1],
          type: field[0] !== "instock_threshold" ? MetafieldType.SingleLineTextField : MetafieldType.NumberInteger,
          namespace: Constants.NAMESPACE,
          owner_resource: Constants.OWNER_RESOURCE
        };
      }
      return {
        key: field[0],
        description: !!Constants.DESCRIPTIONS[field[0]] ? Constants.DESCRIPTIONS[field[0]] : field[0],
        value: field[1],
        type: field[0] !== "instock_threshold" ? MetafieldType.SingleLineTextField : MetafieldType.NumberInteger,
        namespace: Constants.NAMESPACE,
        owner_resource: Constants.OWNER_RESOURCE
      };
    });

    return entries;
  };

  const manageShopMetafield = useCallback(
    async (metafield, isDeleted) => {
      const { id } = metafield;
      if (!id || isDeleted) {
        try {

          await BackInStockGraph.createShopMetafield(metafield);
          setSaving(false);
          showToast('Back in Stock metafields were successfully saved');
        } catch(e) {
          setSaving(false);
          showToast('Error creating Back in Stock metafields', true);
        }
      } else {
        try {
          await BackInStockGraph.updateShopMetafield(metafield);
          setSaving(false);
          showToast('Back in Stock metafields were successfully saved');
        } catch(e) {
          setSaving(false);
          showToast('Error saving Back in Stock metafields', true);
        }
      }
    },
    [showToast],
  );

  const handleSubmit = async (values: FormValues) => {

    setSaving(true);
    setFormFields(values);

    let needDeleteMetafield = false;
    if(selectedTag === 'vice_versa') {
      needDeleteMetafield = metafields.filter(item => item.key === 'dotdigital_account_id').length > 0
    }else {
      needDeleteMetafield = metafields.filter(item => item.key === 'viceversa_id').length > 0
    }

    if (needDeleteMetafield) {

      const deletePromise = metafields.map(mf => backInStockGraph.deleteShopMetafield(mf))
      await Promise.all(deletePromise)

    }
    const promises = createShopMetafieldArrayObject(values).map(mf => manageShopMetafield(mf, needDeleteMetafield));
    await Promise.all(promises)
    await initializeShopMetafields()
  };

  const createProductVariantMetafieldDefinition = async(definition) => {
    // Attempt to create metafield definition
    setLoading(true);
    try {
      await BackInStockGraph.createMetafieldDefinition(definition);
      setHasMetafieldDefinitions(true);
      setLoading(false);
    } catch(e) {
      // eslint-disable-next-line no-console
      console.warn(`Error attempting to create metafield definition ${e}`);
      setLoading(false);
      setHasMetafieldDefinitions(false);
    }
  };

  const handleCreateProductVariantDefinitionsClick = () => {
    const backInStockFieldDefinitions = [
      {
        key: Constants.METAFIELD_DEFINITION_KEY,
        name: Constants.METAFIELD_DEFINITION_TITLE_PRODUCT,
        namespace: Constants.NAMESPACE,
        ownerType: OwnerType.Product,
        type: MetafieldType.NumberInteger
      },
      {
        key: Constants.METAFIELD_DEFINITION_KEY,
        name: Constants.METAFIELD_DEFINITION_TITLE_VARIANT,
        namespace: Constants.NAMESPACE,
        ownerType: OwnerType.Productvariant,
        type: MetafieldType.NumberInteger
      },
    ];

    return backInStockFieldDefinitions.map(definition => createProductVariantMetafieldDefinition(definition));
  };

  const handleSelectChange = useCallback(
    (value: string) => {
      setSelectedTag(value)
    },
    [],
  );
  return (
    <Page
      title="Back in Stock"
      breadcrumbs={[
        {
          onAction: () => navigate('/'),
          content: 'Dashboard',
        },
      ]}
    >
      <Card>
      <Card.Section>
      <Select
      label="Option"
      options={options}
      onChange={handleSelectChange}
      value={selectedTag}
    />
     </Card.Section>
        {selectedTag === 'dot_digital' && (
          <Card.Section>
          <FieldForm
            initialFieldDefinition={formFields}
            onSubmit={handleSubmit}
            loading={saving || loading}
            toolTipContent={Constants.TOOL_TIP}
          />
          </Card.Section>
        )}

        {selectedTag === 'vice_versa' && (
          <Card.Section>
          <VVFieldForm
            initialFieldDefinition={formFields}
            onSubmit={handleSubmit}
            loading={saving || loading}
            toolTipContent={Constants.TOOL_TIP}
          />
          </Card.Section>
        )}

      </Card>
      <Card subdued sectioned>
        {hasMetafieldDefinitions && (
          <Banner>
            <p>{Constants.METAFIELD_DEFINITIONS_MESSAGE}</p>
          </Banner>
        )}
        {!hasMetafieldDefinitions && (
          <Button onClick={handleCreateProductVariantDefinitionsClick}>
            {Constants.METAFIELD_DEFINITIONS_BUTTON_TEXT}
          </Button>
        )}
      </Card>
    </Page>
  );
}

export default BackInStock;
