import { AsyncAction } from './index';

interface Props {
  content: string;
  error?: boolean;
  duration?: number;
}

const delay = (duration: number) =>
  new Promise(resolve => setTimeout(resolve, duration));

export const showToast: AsyncAction = ({
  content,
  error = false,
  duration = 3000,
}: Props) => async dispatch => {
  dispatch({
    type: 'SHOW_TOAST',
    payload: {
      error,
      content,
    },
  });
  await delay(duration);
  return dispatch({
    type: 'HIDE_TOAST',
  });
};

export default showToast;
