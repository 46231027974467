import React, { useEffect, useContext, useMemo } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { connect, DispatchProp } from 'react-redux';
import { Card, DisplayText, Page } from '@shopify/polaris';
import { Action } from '../../Actions/actionTypes';
import { Role } from '../../Graph/generatedTypes';
import { AuthContext } from '../../Components/AuthGate';

import '../../Assets/AppLanding.css';
import { useTitleBar } from '../../BridgeHooks';

interface Props extends RouteComponentProps {
  options: {
    url: string;
    title: string;
    icon: JSX.Element;
    requiredRole?: Role;
  }[];
}

function Home(props: Props & DispatchProp<Action>) {
  const { options, dispatch } = props;
  const { user } = useContext(AuthContext);

  useTitleBar({ title: 'Dashboard' });

  useEffect(() => {
    dispatch({
      type: 'DONE_LOADING',
    });
  }, [dispatch]);

  const role = useMemo(() => (user ? user.role : Role.Staff), [user]);

  const routeChoices = options.map(option => {
    const disabled = option.requiredRole && option.requiredRole !== role;

    return (
      <div
        key={option.url}
        className="appOptions admin"
        style={disabled ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}}
      >
        <Card subdued>
          <Link to={option.url}>
            <div className="appOptions__content">
              <DisplayText size="medium">{option.title}</DisplayText>
            </div>
            <div className="appOptions__content--icon">{option.icon}</div>
          </Link>
        </Card>
      </div>
    );
  });

  return (
    <Page title="Dashboard" titleHidden>
      <Card>
        <div className="appOptions__title">
          <DisplayText>Fame House App Suite</DisplayText>
        </div>

        <div className="routeOptions">{routeChoices}</div>
      </Card>
    </Page>
  );
}

export default connect()(Home);
