import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import gql from 'graphql-tag';

import { graphqlClient } from '../../Graph';
import AuthenticationForm from '../../Components/Common/AuthenticationForm';

const INSTALL = gql`
  mutation Install($shop: String!) {
    install(shop: $shop, redirect: { path: "", target: APP }) {
      redirect
    }
  }
`;

const messaging = {
  title: 'Install Fame House App',
  submitButton: 'Install',
  infoBanner: `Enter the *.myshopify.com domain of 
  the Shopify Store you want to install the app on`,
  errorBannerContent:
    'An error occurred with installation. Please check the domain and try again. If this error persists the server may be down',
  toolTipContent: 'Install App to store',
};

class Install extends Component<RouteComponentProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    loading: false,
    error: false,
    placeholder: '',
    initialDomain: '',
  };

  constructor(props) {
    super(props);

    const url = new URL(props.location.href);
    const d = url.searchParams.get('shop');
    if (d) {
      this.state.initialDomain = d.replace('.myshopify.com', '');
    }
  }

  install = async ({ domain }) => {
    this.setState({ loading: true, error: false, placeholder: domain });

    const fullDomain = domain.endsWith('.myshopify.com')
      ? domain
      : `${domain}.myshopify.com`;

    try {
      const { data } = await graphqlClient.mutate<{
        install: { redirect: string };
      }>({
        mutation: INSTALL,
        variables: {
          shop: fullDomain,
        },
      });

      // Redirect
      window.location.href = data.install.redirect;
    } catch (e) {
      this.setState({ error: true, loading: false });
    }
  };

  render() {
    const { loading, error, placeholder, initialDomain } = this.state;

    return (
      <AuthenticationForm
        initialDomain={initialDomain}
        loading={loading}
        error={error}
        onSubmit={this.install}
        messaging={messaging}
        placeholder={placeholder}
      />
    );
  }
}

export default Install;
