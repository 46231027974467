import React, { useCallback } from 'react';
import { navigate } from '@reach/router';
import {
  DisplayText,
  Stack,
  Button,
  TextStyle,
  ResourceList,
  Tooltip,
} from '@shopify/polaris';
import { HorizontalDotsMinor, DeleteMinor } from '@shopify/polaris-icons';
import { FieldDefinition } from '../../Graph/generatedTypes';

interface Props {
  field: FieldDefinition;

  /**
   * Turns off buttons
   */
  disabled?: boolean;

  /**
   * Spinner on delete button
   */
  deleting?: boolean;

  /**
   * Tries to delete the current definition
   */
  onRequestDelete: (field: FieldDefinition) => void;
}

function FieldsList(props: Props) {
  const { disabled = false, deleting = false, onRequestDelete } = props;
  const { field } = props;
  const { id, title, type } = field;

  const handleRequestDelete = useCallback(() => {
    onRequestDelete(field);
  }, [onRequestDelete, field]);

  return (
    <ResourceList.Item
      id={id}
      accessibilityLabel={`Field definition ${title}`}
      onClick={() => undefined}
    >
      <Stack distribution="equalSpacing">
        <Stack.Item>
          <h3>
            <TextStyle variation="strong">{title}</TextStyle>
          </h3>
          <TextStyle variation="subdued">Type: {type}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <DisplayText size="small">
                <Tooltip
                  content={`Edit ${title} Field Definition`}
                  active={false}
                  light
                >
                  <Button
                    icon={HorizontalDotsMinor}
                    accessibilityLabel={`Edit Field ${title}`}
                    outline
                    onClick={() => navigate(`/admin/fields/${id}`)}
                    disabled={disabled}
                  />
                </Tooltip>
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                <Tooltip
                  content={`Delete ${title} Field Definition`}
                  active={false}
                  light
                >
                  <Button
                    icon={DeleteMinor}
                    accessibilityLabel={`Delete Field ${title}`}
                    outline={!deleting}
                    onClick={handleRequestDelete}
                    destructive
                    disabled={disabled}
                    loading={deleting}
                  />
                </Tooltip>
              </DisplayText>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </ResourceList.Item>
  );
}

export default FieldsList;
