import gql from 'graphql-tag';
import { graphqlClient } from '../Graph';
import {
  User,
  DeleteUserMutationVariables,
  DeleteUserMutation,
} from '../Graph/generatedTypes';

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: String!) {
    userDelete(id: $id) {
      id
      email
      role
    }
  }
`;

async function userDelete(id: string): Promise<User> {
  const { data } = await graphqlClient.mutate<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >({
    mutation: DELETE_USER_MUTATION,
    variables: { id },
  });

  return data.userDelete;
}

export default userDelete;
