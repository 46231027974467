import React, { useState } from 'react';
import { Field, getIn, FieldProps } from 'formik';
import {
  TextField as PolarisTextField,
  TextFieldProps as PolarisTextFieldProps,
  Button,Stack,Tag
} from '@shopify/polaris';
import { Omit } from '@reach/router';
import "./AddTagTextField.css"


/**
 * AddTagTextField is a component to add multiple options for particular Field Definition. 
 * When user select the Filed type is Multi select Dropdown, By using this component, They can add options for that field. 
 * These options values are mapped against the particular Field and User can select these options in the matafields.
 */
interface TextFieldProps {
  /**
   * The field identifier that formik can use to
   * connect this field to the data. Will also be
   * used as the polaris id
   */
  name: string;
}

type PolarisProps = Omit<
  PolarisTextFieldProps,
  'value' | 'onChange' | 'onBlur' | 'error'
>;

function AddTagTextField(props: TextFieldProps & PolarisProps) {

  // Adding A new Option
  const [addOption,setAddOptionText] = useState("");
  const { name, ...polarisProps } = props;

  return (
    <Field
      name={name}
      render={({
        field,
        form: { setFieldValue, setFieldError, errors, touched, values },
      }: FieldProps) => {
        let error;
        try {
          if (getIn(touched, name)) {
            error = getIn(errors, name);
          }
        } catch (e) {
          throw new Error(
            `Formik errors object is in an abnormal state, TextField "${name}" could not check it's error state`,
          );
        }

        const removeTag = (tag) => () => {
           
             const values = field.value.filter((previousTag) => previousTag !== tag)
              setFieldValue(name,values);
          }
      

        const getTags = (values) => {
          if(values.length > 0) {
            return values.map(tag => {
              return (<Tag key={tag} onRemove={removeTag(tag)}>
              {tag}
            </Tag>)
            })
          }
        }
      

        return (
          <div>
          {values.fieldType && (values.fieldType === "DROPDOWN" || values.fieldType === "DROPDOWNMULTI") && (
            <div>
              <div className="add-definition-div ">
            <PolarisTextField
              {...polarisProps}
              id={name}
              value={addOption}
              onFocus={() => setFieldError(name, undefined)}
              onBlur={() => field.onBlur({ target: { name } })}
              onChange={value => {
                setAddOptionText(value);
               
              }}
              error={error}
            />
            <div className="button">
            <Button primary size="medium" onClick={() => {
              if(addOption.length > 0) {
                setFieldValue(name, [...field.value,addOption]);
                setAddOptionText("");
              }
               
            }}>Add Options</Button>
            </div>
            </div>
            {field?.value?.length > 0 && (
              <Stack spacing="tight">{getTags(field.value)}</Stack>
            )}
            </div>
            )}

        </div>
        );
      }}
    />
  );
}

export default AddTagTextField;

