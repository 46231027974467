import React from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';
import { Popover } from '@shopify/polaris';

import styles from '../Fulfillment.module.css';
import { StringCellEditor } from '../Pickers';

interface StringCellProps {
  initialTextValue: string | undefined;
  editing: boolean;
  onChange: (newTextValue: string) => void;
  onCancelEdit: () => void;
}

function StringCell(props: ICellProps & StringCellProps) {
  const {
    initialTextValue,
    editing,
    onChange,
    onCancelEdit,
    ...cellProps
  } = props;

  // This is just used to get the popover to re-render
  // so it will update its size to match the child
  // @todo make this not silly
  const [dummy, setDummy] = React.useState(undefined);

  const content = (
    <div className={styles.cellSpacing}>
      <div className={styles.singleTextCell} security={dummy}>
        {initialTextValue}
      </div>
    </div>
  );

  return (
    <div className="string-cell">
      <Cell {...cellProps} wrapText>
        <Popover
          activator={content}
          active={editing}
          onClose={onCancelEdit}
          preferredPosition="below"
        >
          <StringCellEditor
            initialTextValue={initialTextValue}
            onChangeString={onChange}
            editing={editing}
            onUpdateUI={() => setDummy(Math.random())}
          />
        </Popover>
      </Cell>
    </div>
  );
}

export default React.memo(StringCell);
