import { Bundle } from '../../types';
import { API } from '../types';
import defaultThumbnail from '../../Assets/default.jpg';
import { Config } from '../../config';

const parseAPIProduct = (product: API.BundleProduct, config: Config) => ({
  ...product,
  quantity: 1,
  thumbnail: product.thumbnail || defaultThumbnail,
  adminUrl: `https://admin.shopify.com/store/${config.SHOPIFY_DOMAIN.replace('.myshopify.com', '')}/products/${product.id}`
});

// eslint-disable-next-line import/prefer-default-export
export const parseAPIBundle = (
  rawBundle: API.Bundle,
  config: Config,
): Bundle => ({
  ...rawBundle,
  url: `/bundles/${rawBundle.id}`,
  adminUrl:  `https://admin.shopify.com/store/${config.SHOPIFY_DOMAIN.replace('.myshopify.com', '')}/products/${rawBundle.id}`,
  contents: rawBundle.contents.map(item => {
    if (item.type === 'product') {
      return parseAPIProduct(item, config);
    }

    // Assume set
    return {
      ...item,
      contents: item.contents.map(i => parseAPIProduct(i, config)),
    };
  }),
});
