import React from 'react';
import { ResourceList, EmptyState, TextStyle } from '@shopify/polaris';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import update from 'immutability-helper';

import { BundleItem, BundleProduct as BS } from '../../types';
import BundleProduct from './BundleProduct';

import styles from './BundleItems.module.css';
import Empty from '../../Assets/product.svg';

import BundleSet from './BundleSet';
import { findProduct } from './utils';

interface BundleItemsProps {
  title?: string;
  name: string;
  items: BundleItem[];
  inSet?: boolean;
  dragging: boolean;
  setDragging: (d: boolean) => void;
  requestAddingProducts?: () => void;
}

const performMove = (
  [oX, oY, p]: [number, number, BS],
  [tX, tY]: [number, number, BS] | [number, number],
  items: BundleItem[],
): BundleItem[] => {
  // Here be dragons
  const deleted = update(
    items as any,
    oY
      ? { [oX]: { contents: { $splice: [[oY - 1, 1]] } } }
      : { $splice: [[oX, 1]] },
  );

  return update(
    deleted,
    tY
      ? {
          [tX > oX ? tX - 1 : tX]: {
            contents: { $splice: [[tY - 1, 0, p]] },
          },
        }
      : { $splice: [[tX, 0, p]] },
  ) as BundleItem[];
};

function BundleItems(props: BundleItemsProps) {
  const {
    title,
    items,
    name,
    requestAddingProducts,
    inSet,
    dragging,
    setDragging,
  } = props;

  const resourceItems = items.map<{ item: BundleItem; index: number }>(
    (i, index) => ({ item: i, index }),
  );

  const hasSet = !inSet && !!items.find(({ type }) => type === 'set');

  return (
    <FieldArray name={name}>
      {({ handleRemove, replace, form }: FieldArrayRenderProps) => {
        /* @todo only have one instance of this */

        if ((!items || !items.length) && !inSet) {
          return (
            <>
              <EmptyState
                heading="Empty bundle"
                action={{
                  content: 'Add first component',
                  onAction: () => requestAddingProducts(),
                }}
                image={Empty}
              >
                <p>
                  Bundle &quot;<TextStyle variation="strong">{title}</TextStyle>
                  &quot; contains no components
                </p>
              </EmptyState>
            </>
          );
        }

        return (
          <div className={styles.wrapper}>
            <ResourceList
              resourceName={{ singular: 'product', plural: 'products' }}
              items={resourceItems}
              idForItem={({ item }) =>
                item.type === 'product' ? item.id : 'set'
              }
              renderItem={({
                index,
                item,
              }: {
                index: number;
                item: BundleItem;
              }) => {
                if (item.type === 'product') {
                  const p = {
                    key: item.id,
                    parentPath: name,
                    index,
                    product: item,
                    saving: form.status.saving,
                    dragging,
                    inSet,
                    hasSet,
                    setDragging,
                    onRequestDelete: handleRemove(index),
                    onConvertToSet: i => {
                      replace(i, {
                        type: 'set',
                        label: 'Set',
                        contents: [item],
                      });
                      // Cannot set contents itself to touched or formik errors
                      form.setFieldTouched(`${name}.${index}.contents.0.type`);
                    },
                    move: ({ originId, targetId }) => {
                      const origin = findProduct(originId, form.values);
                      const target = findProduct(targetId, form.values);

                      form.setValues({
                        ...form.values,
                        contents: performMove(
                          origin,
                          target,
                          form.values.contents,
                        ),
                      });
                    },
                  } as any;
                  return <BundleProduct {...p} />;
                }

                return (
                  <BundleSet
                    key="set"
                    parent={name}
                    index={index}
                    last={index + 1 === items.length}
                    set={item}
                    errors={form.errors}
                    dragging={dragging}
                    setDragging={setDragging}
                    onRequestDelete={handleRemove(index)}
                    onCollapse={i => {
                      replace(i, item.contents[0]);
                      // Cannot set product itself to touched or formik errors
                      form.setFieldTouched(`${name}.${index}.type`);
                    }}
                    move={(originId, target) => {
                      const origin = findProduct(originId, form.values);

                      form.setValues({
                        ...form.values,
                        contents: performMove(
                          origin,
                          target,
                          form.values.contents,
                        ),
                      });
                    }}
                  />
                );
              }}
            />
          </div>
        );
      }}
    </FieldArray>
  );
}

export default BundleItems;
