import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { EmptyState } from '@shopify/polaris';
import errorDefinitions from './errorDefinitions';

interface Props extends RouteComponentProps {
  errorCode?: string;
}

function WebError({ errorCode }: Props): JSX.Element {
  const url = new URL(window.location.href);
  const shop = url.searchParams.get('shop');
  const user = url.searchParams.get('user');

  const shopUrl = `https://admin.shopify.com/store/${shop.replace('.myshopify.com', '')}`;
  const verifyUrl = `${shopUrl}/settings/account/${user}`;

  const {
    title,
    message,
    primaryContent,
    secondaryContent,
    secondaryUrl,
  } = errorDefinitions[errorCode];

  let { primaryUrl } = errorDefinitions[errorCode];
  if (primaryUrl === '') {
    primaryUrl = shopUrl;
  }

  if (errorCode === 'verify-email') {
    primaryUrl = verifyUrl;
  }

  return (
    <EmptyState
      heading={title}
      action={{ content: primaryContent, url: primaryUrl }}
      secondaryAction={{
        content: secondaryContent || null,
        url: secondaryUrl || null,
      }}
      image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
    >
      <p>{message}</p>
    </EmptyState>
  );
}

export default WebError;
