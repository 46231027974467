import React from 'react';
import { Cell } from '@blueprintjs/table';

import {
  Field,
  FieldType,
  TimeField,
  StringField,
  IntField,
  DateField,
  BooleanField,
} from '../../Graph/generatedTypes';
import {
  NeverCell,
  TimeCell,
  DateCell,
  StringCell,
  BooleanCell,
  // on the product Table, for multi select component, Dropdown cell we used. 
  DropDownCell
} from './Cells';

interface FieldRendererProps {
  loading: boolean;
  fields: Field[];
  editingCell: [number, number] | null;
  // Will contain all the options of dropdown component.
  dropdownValues: string[] | null;
  definitionId: string;
  onDoneEditing: () => void;
  onFieldUpdate: (
    field: StringField | IntField | TimeField | DateField,
  ) => void;
}

const fieldRenderer = (props: FieldRendererProps) => (
  row: number,
  col: number,
) => {
  const {
    loading,
    fields,
    editingCell,
    definitionId,
    // All the options of the dropdown. This is needed only if the type is dropdown.
    dropdownValues,
    onDoneEditing,
    onFieldUpdate,
  } = props;

  const editingMatch =
    editingCell && editingCell[0] === col && editingCell[1] === row;

  if (loading) {
    return <Cell loading />;
  }

  const field = fields[row];

  if (!field) {
    return <Cell loading />;
  }

  const key = `${definitionId}.${field.parent.type}.${field.parent.id}`;
  switch (field.type) {
    case FieldType.Never:
      return <NeverCell key={key} />;
    case FieldType.Time:
      return (
        <TimeCell
          key={key}
          value={(field as TimeField).value}
          editing={editingMatch}
          onTimeUpdate={(interval, unit) =>
            onFieldUpdate({
              ...(field as TimeField),
              value: { interval, unit },
            })
          }
          onCancelEdit={onDoneEditing}
        />
      );
    case FieldType.Date:
      return (
        <DateCell
          key={key}
          date={(field as DateField).value}
          editing={editingMatch}
          onDateUpdate={date =>
            onFieldUpdate({ ...(field as DateField), value: date })
          }
          onCancelEdit={onDoneEditing}
        />
      );
    case FieldType.Isodate:
      return (
        <DateCell
          key={key}
          date={(field as DateField).value}
          editing={editingMatch}
          onDateUpdate={date =>
            onFieldUpdate({ ...(field as DateField), value: date })
          }
          onCancelEdit={onDoneEditing}
        />
      );
    case FieldType.String:
      return (
        <StringCell
          key={key}
          initialTextValue={(field as StringField).value}
          editing={editingMatch}
          onChange={newTextValue =>
            onFieldUpdate({ ...(field as StringField), value: newTextValue })
          }
          onCancelEdit={onDoneEditing}
        />
      );
    case FieldType.Boolean:
      return (
        <BooleanCell
          initialValue={(field as BooleanField).value}
          key={key}
          editing={editingMatch}
          onCancelEdit={onDoneEditing}
          onChange={bravado => {
            onFieldUpdate({ ...(field as any), value: bravado });
          }}
        />
      );

      // When the selected component is dropdown, We created Dropdown cell.
      // This consists of a popup for single select.
      case FieldType.Dropdown:
        return (
          <DropDownCell
            key={key}
            initialTextValue={(field as StringField).value}
            editing={editingMatch}
            dropdownValues={dropdownValues}
            allowMultiple={false}
            onChange={newTextValue =>
              onFieldUpdate({ ...(field as StringField), value: newTextValue })
            }
            onCancelEdit={onDoneEditing}
          />
        );

      // When the selected component is dropdown multi, We created Dropdown cell.
      // This consists of a popup for multiselect.
      case FieldType.Dropdownmulti:
        return (
          <DropDownCell
            key={key}
            initialTextValue={(field as StringField).value}
            editing={editingMatch}
            dropdownValues={dropdownValues}
            allowMultiple={true}
            onChange={newTextValue =>
              onFieldUpdate({ ...(field as StringField), value: newTextValue })
            }
            onCancelEdit={onDoneEditing}
          />
        );
  
    default:
      throw new Error(
        `No component exists to support field of type ${field.type}`,
      );
  }
};

export default fieldRenderer;
