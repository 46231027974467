import React from 'react';
import { Card, Stack, TextStyle, TextField } from '@shopify/polaris';
import { getIn, useFormikContext, useField } from 'formik';
import { Money, MoneyInput } from '../Common';
import { BundleVariant as BV } from '../../types';
import styles from './ProductVariant.module.css';

interface BundleVariantProps {
  parent: string;
  index: number;
  variant: BV;
  collapsed: boolean;
}

function BundleVariant({
  variant,
  parent,
  index,
  collapsed,
}: BundleVariantProps) {
  const { title, sku, salePrice } = variant;

  const formik = useFormikContext<any>();
  const [field] = useField(`${parent}.${index}.bundlePrice`);
  const percentageDiscount = (
    100 -
    (Number.parseFloat(field.value) / salePrice) * 100
  ).toFixed(2);

  return (
    <div style={{ display: collapsed ? 'none' : 'initial' }}>
      <div className={styles.variant}>
        <Card.Section>
          <Stack
            distribution="trailing"
            spacing="extraLoose"
            alignment="baseline"
          >
            <Stack.Item fill>
              <p style={{ marginBottom: 12, fontWeight: 600 }}>{title}</p>
              <span>
                SKU: <TextStyle variation="strong">{sku}</TextStyle>
              </span>
            </Stack.Item>
            <Stack.Item>
              <p style={{ marginBottom: 12 }}>Original Price</p>
              <span style={{ paddingLeft: 5, fontWeight: 600 }}>
                <Money amount={salePrice} />
              </span>
            </Stack.Item>
            <Stack.Item>
              <div style={{ maxWidth: 150 }}>
                <MoneyInput
                  id={field.name}
                  amount={field.value}
                  onAmountUpdate={price =>
                    formik.setFieldValue(field.name, price)
                  }
                  label="Bundle Price"
                  disabled={formik.status.saving}
                  error={getIn(formik.errors, `${field.name}_error`)}
                  onBlur={() => field.onBlur({ target: { name: field.name } })}
                />
              </div>
            </Stack.Item>
            <Stack.Item>
              <div style={{ maxWidth: 150 }}>
                <TextField
                  value={percentageDiscount}
                  label="Discount"
                  readOnly
                  maxLength={6}
                  prefix="%"
                />
              </div>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </div>
    </div>
  );
}

export default BundleVariant;
