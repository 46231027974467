import { AsyncAction } from './index';

import { isDiscarded } from '../persist';
import defaultThumbnail from '../Assets/default.jpg';
import { API } from './types';
import { BundleOnly } from '../types';
import config from '../config';

interface Props {
  query?: string;
  after?: string;
  before?: string;
}

export const loadBundles: AsyncAction = ({
  query = '',
  after,
  before,
}: Props = {}) => async (dispatch, getState, { serverFetch }) => {
  dispatch({
    type: 'LOAD_BUNDLES_START',
    payload: null,
  });

  const queryParams = new URLSearchParams();

  queryParams.set('query', query);

  if (after) {
    queryParams.set('after', after);
  }
  if (before) {
    queryParams.set('before', before);
  }

  // @todo error handling
  const response = await serverFetch(`/api/bundles?${queryParams.toString()}`);

  if (!response.ok) {
    return dispatch({
      type: 'LOAD_BUNDLES_ERROR',
      payload: null,
    });
  }

  try {
    const {
      bundles: rawBundles,
      nextPage,
      prevPage,
    }: {
      bundles: API.BundleOnly[];
      nextPage?: string;
      prevPage?: string;
    } = await response.json();

    const bundles: BundleOnly[] = rawBundles
      .filter(({ id }) => !isDiscarded(id))
      .map(bundle => ({
        ...bundle,
        // Patch in types
        quantity: 1,
        url: `/bundles/${bundle.id}`,
        thumbnail: bundle.thumbnail || defaultThumbnail,
        adminUrl:  `https://admin.shopify.com/store/${config.SHOPIFY_DOMAIN.replace('.myshopify.com', '')}/products/${bundle.id}`
      }));

    return dispatch({
      type: 'LOAD_BUNDLES_COMPLETE',
      payload: {
        bundles,
        nextPage,
        prevPage,
      },
    });
  } catch (e) {
    return dispatch({
      type: 'LOAD_BUNDLES_ERROR',
      payload: {
        error: e,
      },
    });
  }

  // @todo error handling
};

export default loadBundles;
