import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Page, Card, SkeletonPage, SkeletonBodyText } from '@shopify/polaris';
import gql from 'graphql-tag';

import FieldsList, { PAGE_TITLE } from '../../Components/Admin/FieldsList';

import { graphqlClient } from '../../Graph';
import {
  LoadDefinitionsQuery,
  LoadDefinitionsQueryVariables,
  FieldDefinition,
} from '../../Graph/generatedTypes';
import { useAppBridge } from '@shopify/app-bridge-react';

interface State {
  loading: boolean;
  error: boolean;
  fields: FieldDefinition[];
}

interface Props {
  loadBulkFields?: any;
}

const LOAD_FIELD_DEFINITIONS = gql`
  query loadDefinitions {
    fieldDefinitions {
      edges {
        node {
          id
          type
          title
          resourceType
          namespace
          key
        }
      }
    }
  }
`;

class Fields extends React.Component<RouteComponentProps & Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    loading: true,
    error: false,
    fields: [],
  };

  shopify = useAppBridge();

  async componentDidMount() {
    await this.loadFieldDefinitions();
  }

  loadFieldDefinitions = async () => {
    const { data } = await graphqlClient.query<
      LoadDefinitionsQuery,
      LoadDefinitionsQueryVariables
    >({
      query: LOAD_FIELD_DEFINITIONS,
      fetchPolicy: 'no-cache',
    });
    const fields: FieldDefinition[] = data.fieldDefinitions.edges.map(
      ({ node }) => ({ shownByDefault: false, ...node }),
    );
    this.setState({ loading: false, fields });
    shopify.loading(false);
  };

  render() {
    const { loading, error, fields } = this.state;

    if (loading) {
      return (
        <SkeletonPage title={PAGE_TITLE} primaryAction breadcrumbs>
          <Card>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </SkeletonPage>
      );
    }

    return (
      <Page
        title={PAGE_TITLE}
        primaryAction={{
          content: 'New Field',
          primary: true,
          onAction: () => navigate('/admin/fields/new'),
        }}
        breadcrumbs={[
          {
            onAction: () => navigate('/'),
            content: 'Dashboard',
          },
        ]}
      >
        <Card>
          <FieldsList
            error={error}
            fields={fields}
            onFieldDeleted={(id: string) => {
              this.setState(state => ({
                fields: state.fields.filter(field => field.id !== id),
              }));
            }}
          />
        </Card>
      </Page>
    );
  }
}

export default Fields;
