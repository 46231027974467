import React from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';

import styles from '../Fulfillment.module.css';

interface ReadOnlyCellProps {
  children: React.ReactNode;
}

function ReadOnlyCell(props: ReadOnlyCellProps & ICellProps) {
  const { children, ...cellProps } = props;
  return (
    <Cell
      {...cellProps}
      style={{ ...cellProps.style, background: '#f5f6f7' }}
      truncated
    >
      <>
        <div className={styles.cellSpacing}>
          <div className={styles.singleTextCell}>{children}</div>
        </div>
      </>
    </Cell>
  );
}

export default React.memo(ReadOnlyCell);
