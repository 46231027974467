import { Bundle, BundleProduct } from '../../../types';

// eslint-disable-next-line import/prefer-default-export
export const findProduct = (
  id: string,
  bundle: Bundle,
): [number, number, BundleProduct] => {
  // Naive implementation
  const tree = bundle.contents;

  for (let i = 0; i < tree.length; i += 1) {
    const item = tree[i];

    if (item.type === 'product' && item.id === id) {
      return [i, 0, item];
    }

    if (item.type === 'set') {
      for (let j = 0; j < item.contents.length; j += 1) {
        const product = item.contents[j];

        if (product.id === id) {
          return [i, j + 1, product];
        }
      }
    }
  }
};
