import { number, object, string, ObjectSchema } from 'yup';
import { FormValues } from './FieldForm';

const VVSCHEMA = object({
  viceversa_id: string()
    .required('Required field'),
  instock_threshold: number()
    .required('Required field')
}) as ObjectSchema<FormValues>;

export default VVSCHEMA;
