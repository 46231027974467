import React, { useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router as ReachRouter, Location } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloProvider } from '@apollo/react-hooks';

import { Button } from '@shopify/polaris';
import Layout from './Layout';
import { App as A } from './Routes';

import store from './store';
import config from './config';
import StatusContainer from './StatusContainer';
import AuthProvider from './Components/AuthGate';
import Admin from './Admin';
import { Role } from './Graph/generatedTypes';
import ContextProvider from './Context';
import { Provider } from './BridgeHooks';
import { SentryErrorBoundary } from './sentry';
import { graphqlClient } from './Graph';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SentryTest(props: any) {
  const [error, setError] = useState(false);

  if (error) {
    throw new Error('CLIENT_TEST_ERROR');
  }

  return (
    <Button
      onClick={() => {
        setError(true);
      }}
    >
      Test sentry error
    </Button>
  );
}

const appOptions = [
  {
    url: '/bundles',
    title: 'Bundles',
    icon: <FontAwesomeIcon icon="object-group" />,
  },
  {
    url: '/metafields',
    title: 'Metafields',
    icon: <FontAwesomeIcon icon="edit" />,
  },
  {
    url: '/admin/fields',
    title: 'Field Definitions',
    icon: <FontAwesomeIcon icon="edit" />,
    requiredRole: Role.Admin,
  },
  {
    url: '/admin/users',
    title: 'Users',
    icon: <FontAwesomeIcon icon="cogs" />,
    requiredRole: Role.Admin,
  },
  {
    url: '/backinstock',
    title: 'Back in Stock',
    icon: <FontAwesomeIcon icon="edit" />,
  },
];

function App() {
  return (
    <ApolloProvider client={graphqlClient}>
      <ReduxProvider store={store}>
        <Layout
          apiKey={config.API_KEY}
          shopDomain={config.SHOPIFY_DOMAIN}
          loading={false}
        >
          <Provider>
            <ContextProvider>
              <AuthProvider>
                <Location>
                  {({ location }) => <StatusContainer location={location} />}
                </Location>
                <ReachRouter>
                  <SentryErrorBoundary default>
                    <A.Home path="/" options={appOptions} />
                    <A.ColorSwatch path="/colorswatch" />
                    <A.Bundles path="/bundles" />
                    <A.EditBundle path="/bundles/:bundleId" />
                    <A.BundlesVerify path="/bundles/verify" />
                    <A.BundlesNotification path="/bundles/settings" />
                    <A.Fulfillment path="/metafields" />
                    <A.BackInStock path="/backinstock" />
                    <Admin path="/admin/*" />
                    <A.NotFound default />
                    <SentryTest path="/mh701FVCpcOV" />
                  </SentryErrorBoundary>
                </ReachRouter>
              </AuthProvider>
            </ContextProvider>
          </Provider>
          <div className="version">Version 1.1</div>
        </Layout>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default App;
