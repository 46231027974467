import React from 'react';
import { FormLayout, Button, Tooltip } from '@shopify/polaris';
import { withFormik, Form, FormikProps } from 'formik';
import { TextField } from '../FormikPolaris';
import SCHEMA from './schema';
import { pick } from 'lodash-es';

export interface FormValues {
  dotdigital_account_id?: string;
  confirmation_template_id?: string;
  backinstock_template_id?: string;
  instock_threshold?: number;
  viceversa_id?: string;
}

const DEFAULT_FIELD_DEFINITION: FormValues = {
  dotdigital_account_id: '',
  confirmation_template_id: '',
  backinstock_template_id: '',
  instock_threshold: undefined
};

interface FormProps {
  /**
   * Shows the form loading state
   */
  loading?: boolean;

  /**
   * Initial form values
   */
  initialFieldDefinition?: FormValues;

  /**
   * Callback for valid submissions
   */
  onSubmit: (values: FormValues) => void;
  toolTipContent: string;
}

function FieldForm(props: FormProps & FormikProps<FormValues>) {
  const {
    loading = false,
    dirty,
    toolTipContent,
  } = props;

  return (
    <Form>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            name="dotdigital_account_id"
            autoComplete={false}
            label="DotDigital Account ID"
            placeholder=""
            disabled={loading}
          />
          <TextField
            name="confirmation_template_id"
            autoComplete={false}
            label="Confirmation Template ID"
            placeholder=""
            disabled={loading}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            name="backinstock_template_id"
            autoComplete={false}
            label="BackInStock Template ID"
            placeholder=""
            disabled={loading}
          />
          <TextField
            name="instock_threshold"
            autoComplete={false}
            label="InStock Threshold"
            placeholder=""
            type="number"
            disabled={loading}
          />
        </FormLayout.Group>
        <Tooltip content={toolTipContent} active={false} light>
          <Button submit primary disabled={loading || !dirty}>
            Save
          </Button>
        </Tooltip>
      </FormLayout>
    </Form>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ initialFieldDefinition }) =>
    initialFieldDefinition || DEFAULT_FIELD_DEFINITION,
  handleSubmit: (values, { props }) => {
    const filterValues = pick(values, Object.keys(DEFAULT_FIELD_DEFINITION))
    props.onSubmit(filterValues)
  },
  validationSchema: SCHEMA,
  validateOnChange: false,
  validateOnBlur: true,
  enableReinitialize: true,
})(FieldForm);
