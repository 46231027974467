import React from 'react';
import { ResourceList } from '@shopify/polaris';

import { DropZoneDD, DropTargetProps } from './draggable';

export interface DropZoneProps {
  target: [number, number];
  move: (originId: string, target: [number, number]) => void;
}

function DropZone({ connectDropTarget }: DropZoneProps & DropTargetProps) {
  return connectDropTarget(
    <div>
      <ResourceList.Item
        id="dropzone"
        media={null}
        onClick={() => undefined}
        accessibilityLabel="Dropzone"
      />
    </div>,
  );
}

export default DropZoneDD(DropZone);
