import { createStore, applyMiddleware, compose, Store, AnyAction } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';

import reducer, { State } from './Reducers';
import { graphqlClient, serverFetch } from './Graph';
import config, { Config } from './config';

export interface ExtraArgument {
  graphqlClient: typeof graphqlClient;
  serverFetch: typeof serverFetch;
  config: Config;
}

const thunk: ThunkMiddleware<
  State,
  AnyAction,
  ExtraArgument
> = thunkMiddleware.withExtraArgument({
  graphqlClient,
  serverFetch,
  config,
});

const middleware = [thunk];

function initializeStore({ env }: { env: string }) {
  // Extra debugging tools for development
  if (env === 'development') {
    // eslint-disable-next-line import/no-extraneous-dependencies, global-require
    const loggerMiddlware = require('redux-logger').default;
    // eslint-disable-next-line import/no-extraneous-dependencies, global-require, @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension');

    return createStore(
      reducer,
      composeWithDevTools(applyMiddleware(...middleware, loggerMiddlware)),
    );
  }
  return createStore(reducer, compose(applyMiddleware(...middleware)));
}

const store: Store<State> = initializeStore({ env: process.env.NODE_ENV });

export default store;
