/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect, DispatchProp } from 'react-redux';
import {
  Page,
  PageActions,
  Select,
  FormLayout,
  TextField,
  ColorPicker,
  Form,
  Banner,
} from '@shopify/polaris';
import loadColorSwatchProduct from '../../Actions/loadColorProduct';
import '../../Assets/AppLanding.css';
import { hexToHSL, HSBToHex } from '../../Utils/colorConvertors';
import saveColorSwatchProduct from '../../Actions/saveColorProduct';
import SkeletonPageView from '../../Components/ColorSwatch/skeletonPage';

type Props = RouteComponentProps & DispatchProp;
interface Color {
  hue: number;
  brightness: number;
  saturation: number;
}
interface Options {
  name: string;
  values: string[];
}
interface State {
  selected: string;
  selectedOptions: Array<any>;
  options: Array<{
    label: string;
    value: string;
  }>;
  productOptions: Array<Options>;
  handleColorChange: {};
  handleHexChange: {};
  colorDeployed: boolean;
  emptyState: boolean;
  metaId: string;
  warningState: boolean;
  spinner: boolean;
  oldArray: {};
}
class ColorSwatch extends Component<RouteComponentProps & Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: '',
      selectedOptions: [],
      options: [],
      productOptions: [],
      handleColorChange: {},
      handleHexChange: {},
      colorDeployed: false,
      emptyState: false,
      metaId: '',
      warningState: false,
      spinner: false,
      oldArray: {},
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: 'DONE_LOADING',
    });
    loadColorSwatchProduct(window.localStorage.getItem('id')).then(response => {
      let oldArray = null;
      let selected = response.options[0].name;
      let selectedOptions = response.options[0].values;
      let metaId = '';
      const options = response.options.map(option => {
        return { label: option.name, value: option.name };
      });
      if (response.format !== null) {
        oldArray = JSON.parse(response.format.value);
        const oldSelctedOptionName: Array<any> = Object.keys(oldArray);
        response.options.forEach(option => {
          if (option.name === oldSelctedOptionName[0]) {
            selected = oldSelctedOptionName[0];
            selectedOptions = option.values;
          }
        });
        metaId = response.format.id;
      }
      const colorObjects = this.colorObjectReturner(
        selectedOptions,
        oldArray,
        selected,
      );
      this.setState({
        options,
        selected,
        selectedOptions,
        handleColorChange: colorObjects.handleColorValues,
        handleHexChange: colorObjects.handleHexValues,
        colorDeployed: true,
        productOptions: response.options,
        metaId,
        oldArray,
      });
    });
  }

  colorObjectReturner = (
    arrayValue: Array<any>,
    oldArray: {} | null = null,
    selected?: any,
  ) => {
    const handleColorValues: any = {};
    const handleHexValues: any = {};
    arrayValue.forEach(value => {
      handleColorValues[value] = {
        hue: 120,
        brightness: 1,
        saturation: 1,
        alpha: 1,
      };
      handleHexValues[value] = '#030303';
    });
    if (oldArray !== null) {
      for (const val in oldArray[selected]) {
        handleHexValues[val] = oldArray[selected][val];
        handleColorValues[val] = hexToHSL(oldArray[selected][val]);
      }
    }
    return {
      handleColorValues,
      handleHexValues,
    };
  };

  handleChange = value => {
    const { productOptions, oldArray } = this.state;
    let colorObjects;
    productOptions.forEach(filterValue => {
      if (filterValue.name === value) {
        colorObjects = this.colorObjectReturner(
          filterValue.values,
          oldArray,
          value,
        );
        this.setState({
          selected: value,
          selectedOptions: filterValue.values,
          handleColorChange: colorObjects.handleColorValues,
          handleHexChange: colorObjects.handleHexValues,
        });
      }
    });
  };

  handleSubmit = (): void => {
    const { handleHexChange, selected, metaId, options } = this.state;
    const objJson = {};
    if (selected === '') {
      objJson[options[0].value] = handleHexChange;
    } else {
      objJson[selected] = handleHexChange;
    }
    this.setState({ spinner: true });
    saveColorSwatchProduct(window.localStorage.getItem('id'), {
      id: metaId,
      json: objJson,
    }).then(response => {
      console.log(response);
      if (response.productUpdate.product.id !== '') {
        this.setState({
          emptyState: true,
          warningState: false,
          spinner: false,
        });
      } else {
        this.setState({
          emptyState: false,
          warningState: true,
          spinner: false,
        });
      }
    });
  };

  handleColorChange = (colorValue, optionValue, hsl) => {
    const { handleColorChange, handleHexChange } = this.state;
    const handleColorObject = handleColorChange;
    const handleHexObject = handleHexChange;
    console.log(colorValue, optionValue, hsl);
    if (hsl) {
      handleColorObject[optionValue] = {
        hue: colorValue.hue,
        saturation: colorValue.saturation,
        brightness: colorValue.brightness,
        alpha: 1,
      };
      handleHexObject[optionValue] = HSBToHex(
        colorValue.hue,
        colorValue.saturation,
        colorValue.brightness,
      );
    } else {
      handleColorObject[optionValue] = hexToHSL(colorValue);
      handleHexObject[optionValue] = colorValue;
    }
    this.setState({
      handleColorChange: handleColorObject,
      handleHexChange: handleHexObject,
    });
  };

  renderOptionValues = optionValue => {
    const { colorDeployed, handleColorChange, handleHexChange } = this.state;
    if (colorDeployed) {
      return (
        <FormLayout.Group key={optionValue}>
          <TextField
            label="Option Value"
            value={optionValue}
            onChange={() => {}}
            readOnly
          />
          <TextField
            label="Hex Value"
            placeholder="#000000"
            onChange={value => {
              this.handleColorChange(value, optionValue, false);
            }}
            value={handleHexChange[optionValue]}
            helpText="We’ll use here hex color code with #."
          />
          <ColorPicker
            onChange={colorValue => {
              this.handleColorChange(colorValue, optionValue, true);
            }}
            color={handleColorChange[optionValue]}
          />
        </FormLayout.Group>
      );
    }
    return null;
  };

  render() {
    const {
      options,
      selected,
      selectedOptions,
      emptyState,
      warningState,
      spinner,
    } = this.state;
    if (spinner) {
      return <SkeletonPageView />;
    }
    return (
      <Page title="Color Swatch" titleHidden>
        {emptyState ? (
          <Banner
            title="Saved Color Swatch"
            onDismiss={() => {
              this.setState({
                emptyState: false,
              });
            }}
            status="success"
          >
            <p>Saved Color Swatches in the Metafields</p>
          </Banner>
        ) : null}
        {warningState ? (
          <Banner
            title="Not Saved Color Swatch"
            onDismiss={() => {
              this.setState({
                warningState: false,
              });
            }}
            status="warning"
          >
            <p>
              There is some Problem in Saving Color Swatches in the Metafields
            </p>
          </Banner>
        ) : null}
        <PageActions
          primaryAction={{
            content: 'Save',
            onAction: this.handleSubmit,
          }}
          secondaryActions={[
            {
              content: 'Back',
              onAction: () => {
                window.top.location.href = `https://bundle-store-avinash.myshopify.com/admin/products/${window.localStorage.getItem(
                  'id',
                )}`;
              },
            },
          ]}
        />
        <Form onSubmit={this.handleSubmit}>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Options"
                options={options}
                onChange={this.handleChange}
                value={selected}
                name="optionsName"
              />
            </FormLayout.Group>
            {selectedOptions.map(optionValue =>
              this.renderOptionValues(optionValue),
            )}
          </FormLayout>
        </Form>
      </Page>
    );
  }
}

export default connect()(ColorSwatch);
