import React from 'react';
import { FormLayout, Button, Tooltip, SelectOption } from '@shopify/polaris';
import { withFormik, Form, FormikProps } from 'formik';
import { TextField, SelectField, CheckboxField, AddTagTextField } from '../../FormikPolaris';
import SCHEMA from './schema';

export interface FormValues {
  title: string;
  fieldType: string;
  namespace: string;
  key: string;

  /**
   * What the metafield will be associated with
   * ex Product, Variant
   */
  resourceType: string;

  /**
   * If this field will be shown by default
   */
  shownByDefault: boolean;

  /**
   * if the fieldtype is dropdown, we need an array of values for selection.
   * This field will carry the array of options on the particular dropdown value.
   */
  dropDownValues? : string[];
}

const DEFAULT_FIELD_DEFINITION: FormValues = {
  title: '',
  fieldType: '',
  namespace: '',
  key: '',
  resourceType: '',
  shownByDefault: false,
  dropDownValues:[] // By default its empty array. 
};

interface FormProps {
  /**
   * Shows the form loading state
   */
  loading?: boolean;

  /**
   * Initial form values
   */
  initialFieldDefinition?: FormValues;

  /**
   * List of field types that can be selected
   */
  fieldTypeOptions: SelectOption[];

  /**
   * Callback for valid submissions
   */
  onSubmit: (values: FormValues) => void;
  toolTipContent: string;
}

function FieldForm(props: FormProps & FormikProps<FormValues>) {
  const {
    loading = false,
    fieldTypeOptions,
    initialFieldDefinition,
    dirty,
    toolTipContent,
  } = props;

  // For now only a couple of fields can be changed after creating the definition
  const update = !!initialFieldDefinition;

  return (
    <Form>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            name="title"
            autoComplete={false}
            label="Field Title"
            placeholder="Artist Label"
            disabled={loading}
          />
          <SelectField
            name="fieldType"
            label="Field Type"
            placeholder="Select the Field Type"
            options={fieldTypeOptions}
            disabled={loading}
          />
          <CheckboxField
            name="shownByDefault"
            label="Shown by default"
            disabled={loading}
            helpText="Would you like this field to always show in bulk editor?"
          />
          <AddTagTextField
            name="dropDownValues"
            autoComplete={false}
            label="DropDown Values"
            placeholder="Add Options"
            disabled={loading}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            name="namespace"
            autoComplete={false}
            label="Metafield Namespace"
            placeholder="famehouse"
            disabled={loading || update}
          />
          <TextField
            name="key"
            autoComplete={false}
            label="Metafield Key"
            placeholder="artist_label"
            disabled={loading || update}
          />
          <SelectField
            name="resourceType"
            label="Metafield Type"
            placeholder="Select a Metafield Type"
            options={[
              { label: 'Product', value: 'PRODUCT' },
              { label: 'Variant', value: 'VARIANT' },
            ]}
            disabled={loading || update}
          />
        </FormLayout.Group>
        <Tooltip content={toolTipContent} active={false} light>
          <Button submit primary disabled={loading || !dirty}>
            Save
          </Button>
        </Tooltip>
      </FormLayout>
    </Form>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ initialFieldDefinition }) =>
    initialFieldDefinition || DEFAULT_FIELD_DEFINITION,
  handleSubmit: (values, { props }) => props.onSubmit(values),
  validationSchema: SCHEMA,
  validateOnChange: false,
  validateOnBlur: true,
  enableReinitialize: true,
})(FieldForm);
