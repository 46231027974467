import createApp from '@shopify/app-bridge';
import config from '../config';
function useCreateApp() {
  let host = new URL(window.location.href).searchParams.get("host");
  if (!host) {
    host = window.sessionStorage.getItem('current_host');
  }
  window.sessionStorage.setItem('current_host', host);
  const app = createApp({
    apiKey: config.API_KEY,
    host: host
  })
  return app;
}

export default useCreateApp;
