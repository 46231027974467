import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';
import { BulkProductRow } from '../Graph/generatedTypes';

export interface State {
  rows: BulkProductRow[];
  nextPageCursor: string | undefined;
  previousPageCursor: string | undefined;
}

const defaultState: State = {
  rows: [],
  nextPageCursor: undefined,
  previousPageCursor: undefined,
};

const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_BULK_PRODUCTS_START': {
      return defaultState;
    }
    case 'LOAD_BULK_PRODUCTS_COMPLETE': {
      return {
        ...defaultState,
        ...action.payload,
      };
    }
    case 'LOAD_BULK_PRODUCTS_ERROR': {
      return defaultState;
    }
    default:
      return state;
  }
};

export default reducer;
