import React, { useState } from 'react';
import { Button, TextField } from '@shopify/polaris';
import { TickSmallMinor } from '@shopify/polaris-icons';
import styles from '../Fulfillment.module.css';

interface Props {
  initialTextValue: string | undefined;
  onChangeString: (value: string) => void;
  editing: boolean;

  // This is used to update the
  // parent popover size so it
  // doesn't get out of sync
  // @todo fix this
  onUpdateUI: () => void;
}

function StringCellEditor(props: Props) {
  const { initialTextValue, onChangeString, editing } = props;
  const [newTextValue, setTextValue] = useState(initialTextValue);

  function handleConfirm() {
    onChangeString(newTextValue);
  }

  return (
    <div className={styles.popoverContent}>
      <div className={styles.popoverContentInput}>
        <TextField
          label="Editable string field"
          labelHidden
          type="text"
          multiline
          value={editing ? newTextValue : initialTextValue}
          onChange={val => {
            setTextValue(val);
            props.onUpdateUI();
          }}
          autoComplete="off"
        />
      </div>
      <div className={styles.confirmPopoverButton}>
        <Button icon={TickSmallMinor} primary onClick={handleConfirm} />
      </div>
    </div>
  );
}

export default StringCellEditor;
