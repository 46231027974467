import gql from 'graphql-tag';
import { AsyncAction } from './index';
import {
  LoadBulkFieldsQueryVariables,
  LoadBulkFieldsQuery,
  ResourceReference,
  Field,
  FieldType,
  StringField,
  IntField,
  NeverField,
  TimeField,
  DateField,
  DropdownField, // New Schema field created for single or multi select dropdown.
  RowType,
  ResourceType,
  SingleProductRow,
  BooleanField,
} from '../Graph/generatedTypes';

// DropdownField added in the GQL query for FieldsByRefs query to get all the option value for that dropdown.
const LoadBulkFields = gql`
  query loadBulkFields($fieldIds: [ID!]!, $refs: [InputResourceReference!]!) {
    fieldsByRefs(fieldIds: $fieldIds, refs: $refs) {
      id
      type
      title
      values {
        ... on NeverField {
          id
          parent {
            id
            type
          }
          type
        }
        ... on BooleanField {
          id
          parent {
            id
            type
          }
          type
          value
        }
        ... on IntField {
          id
          parent {
            id
            type
          }
          type
          int: value
        }
        ... on StringField {
          id
          parent {
            id
            type
          }
          type
          str: value
        }
        ... on TimeField {
          id
          parent {
            id
            type
          }
          type
          interval: value
        }
        ... on DateField {
          id
          parent {
            id
            type
          }
          type
          utc: value
        }
        ... on DropdownField {
          id
          parent {
            id
            type
          }
          type
          options: value
        }
      }
    }
  }
`;

export const loadBulkFields: AsyncAction = (fieldIds: string[]) => async (
  dispatch,
  getState,
  { graphqlClient },
) => {
  dispatch({
    type: 'LOAD_BULK_FIELDS_START',
    payload: null,
  });

  // Retrieve the products
  const { rows } = getState().bulkProducts;
  const definitions = getState().fieldDefinitions;

  const refs: ResourceReference[] = rows.reduce<ResourceReference[]>(
    (acc, row) => {
      const type =
        row.type === RowType.Variant
          ? ResourceType.Variant
          : ResourceType.Product;

      acc.push({
        id: row.id,
        type,
      });

      // Really should figure out a better way to do this :(
      if (row.type === RowType.SingleProduct) {
        acc.push({
          id: (row as SingleProductRow).variantId,
          type: ResourceType.Variant,
        });
      }

      return acc;
    },
    [],
  );

  try {
    const { data } = await graphqlClient.query<
      LoadBulkFieldsQuery,
      LoadBulkFieldsQueryVariables
    >({
      query: LoadBulkFields,
      variables: {
        refs,
        fieldIds,
      },
      fetchPolicy: 'no-cache',
    });

    const types = data.fieldsByRefs.reduce<{ [id: string]: Field[] }>(
      (acc, field) => {
        // @todo consider restructuring gql since this is super annoying :(
        const values = field.values.map(rawField => {
          // Prevent later deletion on cached values
          const f = { ...rawField };

          switch (f.type) {
            case FieldType.Never:
              return f as NeverField;
            case FieldType.Boolean: {
              return f as BooleanField;
            }
            case FieldType.String: {
              (f as StringField).value = (f as any).str;
              delete (f as any).str;
              return f as StringField;
            }
            case FieldType.Integer: {
              (f as IntField).value = (f as any).int;
              delete (f as any).int;
              return f as IntField;
            }
            case FieldType.Time: {
              (f as TimeField).value = { ...(f as any).interval };
              delete (f as any).interval;
              return f as TimeField;
            }
            case FieldType.Date: {
              (f as DateField).value = (f as any).utc;
              delete (f as any).utc;
              return f as DateField;
            }
            case FieldType.Isodate: {
              (f as DateField).value = (f as any).utc;
              delete (f as any).utc;
              return f as DateField;
            }
            
            // Dropdown values are consider as string joined by ,
            case FieldType.Dropdown: {
              (f as DropdownField).value = (f as any).options;
              delete (f as any).options;
              return f as DropdownField;
            }

            // Dropdown values are consider as string joined by ,
            case FieldType.Dropdownmulti: {
              (f as DropdownField).value = (f as any).options;
              delete (f as any).options;
              return f as DropdownField;
            }

            default:
              throw new Error(
                `Must implement parsing for metafield of type ${field.type}`,
              );
          }
        });

        const definition = definitions.find(({ id }) => id === field.id);

        if (!definition) {
          throw new Error(
            `Unable to find definition for field id "${field.id}"`,
          );
        }

        // Strip out extra rows from SingleProductRows
        const filteredVals: Field[] = rows.map(row => {
          if (row.type !== RowType.SingleProduct) {
            return values.shift();
          }

          if (definition.resourceType === ResourceType.Product) {
            const val = values.shift();

            // Discard the variant
            values.shift();

            return val;
          }

          // Discard the product
          values.shift();

          return values.shift();
        });

        acc[field.id] = filteredVals;

        return acc;
      },
      {},
    );

    return dispatch({
      type: 'LOAD_BULK_FIELDS_COMPLETE',
      payload: {
        types,
      },
    });
  } catch (e) {
    return dispatch({
      type: 'LOAD_BULK_FIELDS_ERROR',
      payload: {
        error: e,
      },
    });
  }
};

export default loadBulkFields;
