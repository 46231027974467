import React from 'react';
import {
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  Card,
} from '@shopify/polaris';

function SkeletonBundleForm() {
  return (
    <SkeletonPage secondaryActions={2}>
      <SkeletonDisplayText size="large" />
      <br />
      <Card>
        <Card.Section>
          <SkeletonBodyText lines={1} />
        </Card.Section>

        <Card.Section subdued>
          <Card>
            <Card.Section>
              <SkeletonDisplayText />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Card.Section>

        <Card.Section subdued>
          <Card>
            <Card.Section>
              <SkeletonDisplayText />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Card.Section>

        <Card.Section subdued>
          <Card>
            <Card.Section>
              <SkeletonDisplayText />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Card.Section>
      </Card>
    </SkeletonPage>
  );
}

export default SkeletonBundleForm;
