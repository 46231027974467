import gql from 'graphql-tag';
import { graphqlClient } from '.';
import {
  LoadFieldTypeOptionsQuery,
  LoadFieldTypeOptionsQueryVariables,
  FieldTypeOption,
} from './generatedTypes';

const LOAD_FIELD_TYPE_OPTIONS = gql`
  query loadFieldTypeOptions {
    fieldTypes {
      edges {
        node {
          type
          title
          description
        }
      }
    }
  }
`;

async function loadFieldTypeOptions(): Promise<FieldTypeOption[]> {
  const { data } = await graphqlClient.query<
    LoadFieldTypeOptionsQuery,
    LoadFieldTypeOptionsQueryVariables
  >({
    query: LOAD_FIELD_TYPE_OPTIONS,
  });

  return data.fieldTypes.edges.map(({ node }) => node);
}

export default loadFieldTypeOptions;
