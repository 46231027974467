import { Reducer } from 'redux';
import { Action } from '../Actions/actionTypes';

export interface State {
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
}

const defaultState: State = {
  loading: true,
  error: false,
  errorMessage: null,
};

// @todo make this more general and resilient to cancellations
const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case 'NAVIGATE': {
      return defaultState;
    }
    case 'DONE_LOADING': {
      return { ...defaultState, loading: false };
    }
    case 'LOAD_BUNDLES_START': {
      return defaultState;
    }
    case 'LOAD_BUNDLES_COMPLETE': {
      return { ...defaultState, loading: false };
    }
    // @todo pass the message
    case 'LOAD_BUNDLES_ERROR': {
      return { ...defaultState, loading: false, error: true };
    }
    case 'LOAD_BUNDLE_START': {
      return defaultState;
    }
    case 'LOAD_BUNDLE_COMPLETE': {
      return { ...defaultState, loading: false };
    }
    // @todo pass the message
    case 'LOAD_BUNDLE_ERROR': {
      return { ...defaultState, loading: false, error: true };
    }
    case 'UPDATE_BUNDLE_START': {
      return defaultState;
    }
    case 'UPDATE_BUNDLE_COMPLETE': {
      return { ...defaultState, loading: false };
    }
    // @todo pass the message
    case 'UPDATE_BUNDLE_ERROR': {
      return { ...defaultState, loading: false, error: true };
    }
    case 'DISCARD_BUNDLE_START': {
      return defaultState;
    }
    case 'LOAD_BULK_PRODUCTS_START': {
      return defaultState;
    }
    case 'LOAD_BULK_PRODUCTS_COMPLETE': {
      return { ...defaultState, loading: false };
    }
    case 'LOAD_BULK_PRODUCTS_ERROR': {
      return { ...defaultState, loading: false, error: true };
    }
    default:
      return state;
  }
};

export default reducer;
