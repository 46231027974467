import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';
import { Page, EmptyState } from '@shopify/polaris';
import { debounce } from 'lodash-es';

import { useAppBridge } from '@shopify/app-bridge-react';
import { SelectPayload } from '@shopify/app-bridge/actions/ResourcePicker';
import BundlesList from '../../Components/BundlesList';

import {
  loadBundles as loadBundlesAction,
  discardBundle as discardBundleAction,
} from '../../Actions';
import { State as ReduxState } from '../../Reducers';
import { BundleOnly } from '../../types';
import '../../Assets/Bundles.css';
interface Props extends RouteComponentProps {
  loadBundles: typeof loadBundlesAction;
  discardBundle: typeof discardBundleAction;
  items: BundleOnly[];
  nextPage: string | null;
  prevPage: string | null;
  loading: boolean;
  error: boolean;
}

interface State {
  query: string;
  manualSelectorOpen: boolean;
}

class Bundles extends Component<RouteComponentProps & Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    query: '',
    manualSelectorOpen: false,
  };

  shopify = useAppBridge();

  async componentDidMount() {
    await this.loadItems();
  }

  async componentDidUpdate(prevProps, { query: lastQuery }) {
    const { query } = this.state;

    if (query !== lastQuery) {
      await this.debouncedLoadItems();
    }
  }

  // eslint-disable-next-line react/sort-comp
  async loadItems() {
    const { query } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    await this.props.loadBundles({ query });
  }

  bundleSelected = bundles => {
    if (!bundles.length) {
      return;
    }

    navigate(`/bundles/${bundles[0].id.replace('gid://shopify/Product/', '')}`);
  };

  // eslint-disable-next-line react/sort-comp
  debouncedLoadItems = debounce(this.loadItems, 200);

  render() {
    const {
      items,
      nextPage,
      prevPage,
      error,
      loading,
      loadBundles,
      discardBundle,
    } = this.props;
    const { query, manualSelectorOpen } = this.state;
    if(manualSelectorOpen){
      this.shopify.resourcePicker({
        type: "product",
        multiple: false,
        filter: {
          hidden: true,
          variants: false,
        }
      }).then((selected: SelectPayload) => {
        this.setState({ manualSelectorOpen: false });
        if(selected){
          const { selection } = selected;
          this.bundleSelected(selection);
        }
      })
    }

    if (error) {
      return (
        <EmptyState
          heading="Error loading bundles"
          action={{ content: 'Try again', onAction: () => this.loadItems() }}
          secondaryAction={{
            content: 'View usage documentation',
            url: 'https://todo.com',
          }}
          image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          <p>If this persists please contact support.</p>
        </EmptyState>
      );
    }

    return (
      <Page
        title="Bundles"
        primaryAction={{
          content: 'New Bundle',
          primary: true,
          onAction: () => this.setState({ manualSelectorOpen: true }),
        }}
        secondaryActions={[
          {
            content: 'Refresh',
            onAction: () => this.loadItems(),
          },
          {
            content: 'Verify',
            onAction: () => navigate('/bundles/verify'),
          },
          {
            content: 'Settings',
            onAction: () => navigate('/bundles/settings'),
          },
        ]}
        breadcrumbs={[{ onAction: () => navigate('/'), content: 'Dashboard' }]}
        fullWidth
      >
        <BundlesList
          loading={loading}
          items={items}
          query={query}
          hasNextPage={Boolean(nextPage)}
          hasPrevPage={Boolean(prevPage)}
          onQueryChange={newQuery => this.setState({ query: newQuery })}
          onRequestPageChange={previous => {
            this.debouncedLoadItems.cancel();

            if (previous) {
              loadBundles({ query, before: prevPage });
            } else {
              loadBundles({ query, after: nextPage });
            }
          }}
          onRequestDiscard={id => {
            this.debouncedLoadItems.cancel();
            discardBundle({ id });
          }}
          onRequestView={(e, adminUrl) => {
            e.stopPropagation();
            window.open(adminUrl, '_blank');
          }}
          onRequestEdit={url => {
            navigate(url);
          }}
        />
      </Page>
    );
  }
}

const mapStateToProps = ({
  bundles: { currentPage, nextPage, prevPage },
  status: { loading, error },
}: ReduxState) => ({
  items: currentPage,
  nextPage,
  prevPage,
  loading,
  error,
});

const mapDispatch = dispatch => ({
  loadBundles: props => dispatch(loadBundlesAction(props)),
  discardBundle: props => dispatch(discardBundleAction(props)),
});

export default connect(mapStateToProps, mapDispatch)(Bundles);
