import { combineReducers } from 'redux';
import { Action } from '../Actions/actionTypes';

import toast, { State as ToastState } from './toast';
import status, { State as StatusState } from './status';
import bundles, { State as BundlesState } from './bundles';
import bundle, { State as BundleState } from './bundle';
import bulkProducts, { State as BulkProductsState } from './bulkProducts';
import fieldDefinitions, { State as FieldDefsState } from './fieldDefinitions';
import bulkFields, { State as BulkFieldsState } from './bulkFields';

export interface State {
  status: StatusState;
  bundles: BundlesState;
  bundle: BundleState;
  toast: ToastState;
  bulkProducts: BulkProductsState;
  bulkFields: BulkFieldsState;
  fieldDefinitions: FieldDefsState;
}

const reducer = combineReducers<State, Action>({
  status,
  toast,
  bundles,
  bundle,
  bulkProducts,
  bulkFields,
  fieldDefinitions,
});

export default reducer;
