import React, { useState, useEffect } from 'react';
import { Button, OptionList } from '@shopify/polaris';
import { TickSmallMinor } from '@shopify/polaris-icons';
import styles from '../Fulfillment.module.css';

/**
 * Multiselect option helper component.
 *
 * @interface Props
 * initialTextValue - Carry the already selected value if any.
 * editing - make sure the cell is editable or not 
 * dropdownValues - Carry all the Selection Option values belongs to this dropdown.
 * allowMultiple - Determines whether user may select more than one option from dropdown.
 */
interface Props {
  initialTextValue: string | undefined;
  onChangeString: (value: string) => void;
  editing: boolean;
  dropdownValues: string[] | null;
  allowMultiple: boolean;
}

function DropDownCellPicker(props: Props) {
  const { initialTextValue, onChangeString, dropdownValues, allowMultiple } = props;
  const [selected, setSelected] = useState([]);

  useEffect(()=> {
    /** 
     * When already selected values are there, then popup opened, we need to select the already selected value. 
     * for this, we are getting intitial value and split it to get actual value. then  updating the Options selected state
     */
    if(initialTextValue && initialTextValue.length > 0) {
      setSelected(initialTextValue.split(","));
    }
  },[initialTextValue])

  function handleConfirm() {
    // We get all the selected values array and join as a string. then passing it to table.
    onChangeString(selected.reverse().join(","));
  }

  const getDropDownValues  = () => {
    return dropdownValues.map(dropdown => {
     return {value:dropdown, label:dropdown}
    });
  }

  return (
    <div className={styles.popoverContent}>
      <div className={styles.popoverContentInput}>
        <OptionList 
          title={allowMultiple ? 'Multi select Option' : 'Select option'}
          onChange={setSelected} 
          options={getDropDownValues()} 
          selected={selected}
          allowMultiple={allowMultiple} 
        />

      </div>
      <div className={styles.confirmPopoverButton}>
        <Button icon={TickSmallMinor} primary onClick={handleConfirm} />
      </div>
    </div>
  );
}

export default DropDownCellPicker;
