import NotFound from './NotFound';
import Users from './Users';
import User from './User';
import NewUser from './UserNew';
import Fields from './Fields';
import EditField from './EditField';
import NewField from './NewField';

const Admin = {
  Users,
  User,
  NewUser,
  Fields,
  EditField,
  NewField,
  NotFound,
};

export default Admin;
