import gql from 'graphql-tag';
import { AsyncAction } from './index';
import {
  LoadFieldDefinitionsQuery,
  LoadFieldDefinitionsQueryVariables,
} from '../Graph/generatedTypes';

// dropDownvalues are added in the fieldDefinition query to fetch all the options. 
// This will return array of string if the type is dropdown.
const LoadFieldDefinitions = gql`
  query loadFieldDefinitions {
    fieldDefinitions {
      edges {
        node {
          id
          type
          title
          resourceType
          key
          namespace
          shownByDefault
          dropDownValues
        }
      }
    }
  }
`;

export const loadBulkProducts: AsyncAction = () => async (
  dispatch,
  getState,
  { graphqlClient },
) => {
  try {
    const { data } = await graphqlClient.query<
      LoadFieldDefinitionsQuery,
      LoadFieldDefinitionsQueryVariables
    >({
      query: LoadFieldDefinitions,
    });

    const definitions = data.fieldDefinitions.edges.map(edge => edge.node);

    return dispatch({
      type: 'LOAD_FIELD_DEFINITIONS_COMPLETE',
      payload: {
        definitions,
      },
    });
  } catch (e) {
    throw exports;
  }
};

export default loadBulkProducts;
