import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Page, Banner, Card, SelectOption } from '@shopify/polaris';

import gql from 'graphql-tag';
import FieldForm from '../../Components/Admin/FieldForm';
import {
  FieldDefinition,
  LoadDefinitionQuery,
  LoadDefinitionQueryVariables,
  UpdateFieldDefinitionMutation,
  UpdateFieldDefinitionMutationVariables,
  FieldType,
} from '../../Graph/generatedTypes';
import { graphqlClient, loadFieldTypeOptions } from '../../Graph';
import { FormValues } from '../../Components/Admin/FieldForm/FieldForm';
import { StatusContext } from '../../Context';
import { useAppBridge } from '@shopify/app-bridge-react';

const TOOL_TIP = 'Save changes to this Field Definition';

const LOAD_FIELD_DEFINITION = gql`
  query loadDefinition($id: ID!) {
    fieldDefinition(id: $id) {
      id
      type
      title
      resourceType
      namespace
      key
      shownByDefault
      dropDownValues
    }
  }
`;

const UPDATE_FIELD_DEFINITION = gql`
  mutation updateFieldDefinition($fieldDefinition: FieldDefinitionUpdate!) {
    fieldDefinitionUpdate(fieldDefinition: $fieldDefinition) {
      id
      type
      title
      resourceType
      namespace
      key
      shownByDefault
      dropDownValues
    }
  }
`;

interface State {
  loading: boolean;
  saving: boolean;
  field: FieldDefinition | undefined;
  fieldTypeOptions: SelectOption[];
}

class EditField extends React.Component<
  RouteComponentProps<{ id: string }>,
  State
> {
  static contextType = StatusContext;
  shopify = useAppBridge();

  // eslint-disable-next-line react/state-in-constructor
  state = {
    loading: true,
    saving: false,
    field: undefined,
    fieldTypeOptions: [],
  };

  async componentDidMount() {
    shopify.loading(this.state.loading);
    const { id } = this.props;

    try {
      const fieldTypeOptions = await loadFieldTypeOptions();
      this.setState({
        fieldTypeOptions: fieldTypeOptions.map(option => ({
          label: option.title,
          value: option.type,
        })),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to load fieldTypeOptions. ${e}`);
      this.setState({ loading: false });
      shopify.loading(false);
      return;
    }

    try {
      const field = await this.loadFieldDefinition(id);
      this.setState({ loading: false, field });
      shopify.loading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to load field definition with id "${id}". ${e}`);
      this.setState({ loading: false });
      shopify.loading(false);
    }
  }

  loadFieldDefinition = async (id: string): Promise<FieldDefinition> => {
    const { data } = await graphqlClient.query<
      LoadDefinitionQuery,
      LoadDefinitionQueryVariables
    >({
      query: LOAD_FIELD_DEFINITION,
      variables: { id },
      fetchPolicy: 'no-cache',
    });

    return { shownByDefault: false, ...data.fieldDefinition };
  };

  handleSubmit = (values: FormValues) => {
    const { showToast } = this.context;
    this.setState({ saving: true });

    // eslint-disable-next-line react/destructuring-assignment
    this.updateFieldDefinition(this.props.id, values)
      .then(field => {
        this.setState({ saving: false, field }, () =>
          showToast('Field was successfully saved'),
        );
      })
      .catch(() =>
        this.setState({ saving: false }, () =>
          showToast('Error saving field', true),
        ),
      );
  };

  updateFieldDefinition = async (
    id: string,
    values: FormValues,
  ): Promise<FieldDefinition> => {
    const { data } = await graphqlClient.mutate<
      UpdateFieldDefinitionMutation,
      UpdateFieldDefinitionMutationVariables
    >({
      mutation: UPDATE_FIELD_DEFINITION,
      variables: {
        fieldDefinition: {
          id,
          title: values.title,
          type: values.fieldType as FieldType,
          shownByDefault: values.shownByDefault || false,
          options: values.dropDownValues
        },
      },
    });

    return { shownByDefault: false, ...data.fieldDefinitionUpdate };
  };

  render() {
    const { loading, saving, field, fieldTypeOptions }: State = this.state;

    const initialFieldDefinition: FormValues = !loading && {
      title: field.title,
      fieldType: field.type,
      resourceType: field.resourceType,
      namespace: field.namespace,
      key: field.key,
      shownByDefault: field.shownByDefault || false,
      dropDownValues: field.dropDownValues || []
    };

    return (
      <Page
        title="Edit Field Definition"
        breadcrumbs={[
          {
            content: 'Field Definitions',
            onAction: () => navigate('/admin/fields'),
          },
        ]}
      >
        <Banner
          title="Create a new column in your FH-App Metafield Bulk Editor"
          status="info"
        >
          <p>Use the following form to add a metafield to your store.</p>
        </Banner>
        <Card>
          <Card.Section>
            <FieldForm
              loading={loading || saving}
              onSubmit={this.handleSubmit}
              initialFieldDefinition={initialFieldDefinition}
              fieldTypeOptions={fieldTypeOptions}
              toolTipContent={TOOL_TIP}
            />
          </Card.Section>
        </Card>
      </Page>
    );
  }
}

export default EditField;
