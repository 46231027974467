import React from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';

import styles from '../Fulfillment.module.css';

function NeverCell({ ...cellProps }: ICellProps) {
  return (
    <Cell
      {...cellProps}
      style={{ ...cellProps.style, background: '#f5f6f7' }}
      truncated
    >
      <>
        <div className={styles.cellSpacing}>
          <div className={styles.singleTextCell}>---</div>
        </div>
      </>
    </Cell>
  );
}

export default React.memo(NeverCell);
