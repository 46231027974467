import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useAppBridge } from '@shopify/app-bridge-react';
import { State } from './Reducers';
import { Redirect } from '@shopify/app-bridge/actions';
import useCreateApp from './BridgeHooks/useCreateApp';

const mapStateToProps = ({
  status: { loading },
  toast: { open, content, error },
}: State) => ({
  loading,
  toastOpen: open,
  toastMessage: content,
  toastShowsError: error,
});

const StatusComponent = ({
  loading,
  toastOpen,
  toastMessage,
  toastShowsError,
  dispatch,
  location,
}: {
  loading: boolean;
  toastOpen: boolean;
  toastMessage: string;
  toastShowsError: boolean;
  dispatch: any;
  location: any;
}) => {
  const app = useCreateApp();
  const shopify = useAppBridge();
  const redirect = Redirect.create(app);

  useEffect(() => {
    redirect.dispatch(Redirect.Action.APP, location);
  }, [redirect, location]);

  useEffect(() => {
    if(loading){
      shopify.loading(true)
    }else{
      shopify.loading(false)
    }
  }, [shopify, loading]);

  useEffect(() => {
    if (toastOpen) {
      shopify.toast.show(toastMessage, {
        isError:toastShowsError,
        onDismiss:() => dispatch({ type: 'HIDE_TOAST' })
      })
    }
  }, [shopify,toastOpen,dispatch,toastMessage,toastShowsError]);

  return null;
};

const StatusContainer = connect(mapStateToProps)(StatusComponent);

export default StatusContainer;
