import React from 'react';
import { Router as ReachRouter } from '@reach/router';
import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';

import { Web as W } from './Routes';
import ContextProvider from './Context';
import SetupRoute from './Routes/Web/Setup';

function Web() {
  return (
    <AppProvider i18n={en}>
      <ContextProvider>
        <ReachRouter>
          <W.Install path="/" />
          <SetupRoute path="/setup" />
          <W.WebError path="/error/:errorCode" />
          <W.Error default />
        </ReachRouter>
      </ContextProvider>
    </AppProvider>
  );
}

export default Web;
