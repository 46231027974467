import React from 'react';

import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import Link from './Link';
import { Loading } from '@shopify/polaris';

interface Props {
  children: React.ReactNode;
  apiKey: string;
  shopDomain: string;
  loading: boolean;
}

function Layout({ children, apiKey, shopDomain, loading }: Props): JSX.Element {
  const shopOrigin = `admin.shopify.com/store/${shopDomain.replace('.myshopify.com', '')}`;
  const markup = loading ? <Loading/> : children;
  return (
    <AppProvider
      linkComponent={Link}
      i18n={en}
      apiKey={apiKey}
      shopOrigin={shopOrigin}
    >
      {markup}
    </AppProvider>
  );
}

export default Layout;
