import gql from 'graphql-tag';
import { graphqlClient } from '../Graph';
import {
  User,
  UserUpdateInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../Graph/generatedTypes';

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($user: UserUpdateInput!) {
    userUpdate(user: $user) {
      id
      email
      role
    }
  }
`;

async function userUpdate(user: UserUpdateInput): Promise<User> {
  const { data } = await graphqlClient.mutate<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >({
    mutation: UPDATE_USER_MUTATION,
    variables: { user },
  });

  return data.userUpdate;
}

export default userUpdate;
