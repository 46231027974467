/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
function hsvToHsl(h: number, s: number, v: number) {
  // both hsv and hsl values are in [0, 1]
  const l = ((2 - s) * v) / 2;

  if (l !== 0) {
    if (l === 1) {
      s = 0;
    } else if (l < 0.5) {
      s = (s * v) / (l * 2);
    } else {
      s = (s * v) / (2 - l * 2);
    }
  }

  return { h, s, l };
}

function hsl2hsv(hue, sat, light) {
  sat *= light < 0.5 ? light : 1 - light;

  return [
    // [hue, saturation, value]
    // Range should be between 0 - 1

    hue, // Hue stays the same
    (2 * sat) / (light + sat), // Saturation
    light + sat, // Value
  ];
}
export function HSBToHex(hue: number, sat: number, brightness: number) {
  const hslValues = hsvToHsl(hue, sat, brightness);
  const { h } = hslValues;
  const { s } = hslValues;
  const { l } = hslValues;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
}

export function hexToHSL(H: any) {
  // Convert hex to RGB first
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;
  if (H.length === 4) {
    r = `0x${H[1]}${H[1]}`;
    g = `0x${H[2]}${H[2]}`;
    b = `0x${H[3]}${H[3]}`;
  } else if (H.length === 7) {
    r = `0x${H[1]}${H[2]}`;
    g = `0x${H[3]}${H[4]}`;
    b = `0x${H[5]}${H[6]}`;
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +s.toFixed(1);
  l = +l.toFixed(1);
  const hsvValues = hsl2hsv(h, s, l);
  return {
    hue: h,
    saturation: hsvValues[1],
    brightness: hsvValues[2],
    alpha: 1,
  };
}
