import React, { useCallback, useContext } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Page, Banner, TextStyle, Card } from '@shopify/polaris';
import { object, string } from 'yup';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import gql from 'graphql-tag';
import { TextField } from '../../Components/FormikPolaris';
import { graphqlClient } from '../../Graph';
import { StatusContext } from '../../Context';

const MUTATION = gql`
  mutation UserCreateInitial($email: String!) {
    userCreateInitial(email: $email) {
      id
      email
      role
    }
  }
`;

async function userCreateInitial(email: string) {
  await graphqlClient.mutate({
    mutation: MUTATION,
    variables: {
      email,
    },
  });
}

const SCHEMA = object({
  email: string()
    .email()
    .required(),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SetupRoute(props: RouteComponentProps): JSX.Element {
  const { showToast } = useContext(StatusContext);

  const handleSubmit = useCallback(
    (values: { email: string }, formik: FormikActions<{ email: string }>) => {
      userCreateInitial(values.email)
        .then(() => {
          showToast('Admin user created');
          navigate('/');
        })
        .catch(e => {
          formik.setSubmitting(false);
          // eslint-disable-next-line no-console
          console.warn(e);
          showToast('Failed to create admin user', true);
        });
    },
    [showToast],
  );

  return (
    <Page title="Setup">
      <Banner title="Add an Admin User">
        <p>
          The app does not yet have a configured admin user. Please add the
          initial admin user below. This can be updated at any time in the admin
          panel
        </p>
        <p>
          <TextStyle variation="strong">Note: </TextStyle> email of user must
          match email of a Shopify staff account
        </p>
      </Banner>

      <br />

      <Formik
        initialValues={{ email: '' }}
        validationSchema={SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Card
            sectioned
            primaryFooterAction={{
              content: 'Save',
              onAction: submitForm,
              disabled: !isValid,
              loading: isSubmitting,
            }}
          >
            <Form>
              <TextField name="email" label="Email" disabled={isSubmitting} />
            </Form>
          </Card>
        )}
      </Formik>
    </Page>
  );
}

export default SetupRoute;
