import serverFetch from '../Graph/serverFetch';
import { Product } from '../types';

export const loadColorSwatchProduct = async (id: string): Promise<Product> => {
  // @todo use a URL object instead of string coercion
  // @todo add the status checking and parsing to server fetch
  // eslint-disable-next-line no-console
  console.log('id for this product', id);
  const response = await serverFetch(`/api/colorswatch/${id}`);
  try {
    return await response.json();
  } catch (error) {
    return error;
  }
};

export default loadColorSwatchProduct;
