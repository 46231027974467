import React from 'react';
import {
  ResourceList,
  Thumbnail,
  TextStyle,
  Stack,
  Badge,
} from '@shopify/polaris';

import { BundleOnly } from '../../types';
import BundleListItemControls from './BundleListItemControls';

interface Props {
  onRequestDiscard: (id: string) => void;
  onRequestEdit: (url: string) => void;
  onRequestView: (adminUrl: string, e: any) => void;
}

function BundleListItem({
  id,
  url,
  title,
  thumbnail,
  publishedAt,
  adminUrl,
  onRequestDiscard,
  onRequestEdit,
  onRequestView,
}: BundleOnly & Props) {
  const media = (
    <Thumbnail source={thumbnail} alt={`Thumbnail for ${title} bundle`} />
  );

  return (
    <ResourceList.Item
      id={id}
      media={media}
      persistActions
      onClick={() => {}}
      accessibilityLabel={`View details for bundle ${title}`}
    >
      <Stack>
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{title}</TextStyle>
          </h3>
        </Stack.Item>
        {!publishedAt && <Badge status="warning">Not available</Badge>}
        <BundleListItemControls
          id={id}
          url={url}
          title={title}
          adminUrl={adminUrl}
          onRequestView={onRequestView}
          onRequestEdit={onRequestEdit}
          onRequestDiscard={onRequestDiscard}
        />
      </Stack>
    </ResourceList.Item>
  );
}

export default BundleListItem;
