export interface Config {
  API_KEY: string;
  IN_IFRAME: boolean;
  SHOPIFY_DOMAIN: string | null;

  ENVIRONMENT: string;
  SENTRY_DSN?: string;
}

let API_KEY = process.env.REACT_APP_SHOPIFY_API_KEY || '';

if (process.env.NODE_ENV === 'production') {
  const { REACT_APP_ENV } = (window as unknown) as any;
  API_KEY = REACT_APP_ENV.SHOPIFY_API_KEY;
}

if (!API_KEY) {
  throw new Error('REACT_APP_SHOPIFY_API_KEY enviroment variable required');
}

let ENVIRONMENT = process.env.NODE_ENV || '';

if (process.env.NODE_ENV === 'production') {
  const { REACT_APP_ENV } = (window as unknown) as any;
  ENVIRONMENT = REACT_APP_ENV.ENVIRONMENT;
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const IN_IFRAME = inIframe();

function getDomain() {
  let domain = new URL(window.location.href).searchParams.get("shop");

  if (!domain) {
    domain = window.sessionStorage.getItem('current_domain');
  }

  if (!domain) {
    // @todo
    throw new Error('Unable to load domain');
  }

  window.sessionStorage.setItem('current_domain', domain);

  return domain;
}

const SHOPIFY_DOMAIN = IN_IFRAME ? getDomain() : null;

const config: Config = {
  API_KEY,
  IN_IFRAME,
  SHOPIFY_DOMAIN,
  ENVIRONMENT,
  SENTRY_DSN: 'https://85f6cb038a07407a8941e6517d02b21d@sentry.satel.ca/3', // @todo dynamic
};

export default config;
