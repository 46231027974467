import React, { useState, useCallback, ReactNode } from 'react';
import { Toast, Frame } from '@shopify/polaris';

interface StatusContext {
  showToast: (content: string, error?: boolean) => void;
}

export const StatusContext = React.createContext<StatusContext>({
  showToast: () => {},
});

function ContextProvider({ children }: { children: ReactNode }) {
  const [toast, setToast] = useState({
    show: false,
    content: '',
    error: false,
  });

  const handleDismiss = useCallback(() => {
    setToast(t => ({ ...t, show: false }));
  }, []);

  const status = React.useMemo<StatusContext>(
    () => ({
      showToast: (content, error = false) =>
        setToast({ show: true, content, error }),
    }),
    [],
  );

  return (
    <Frame>
      <StatusContext.Provider value={status}>
        {toast.show && (
          <Toast {...toast} duration={2000} onDismiss={handleDismiss} />
        )}
        {children}
      </StatusContext.Provider>
    </Frame>
  );
}

export default ContextProvider;
