import Install from './Install';
import Error from './Error';
import WebError from './error/web-error';

const Web = {
  Install,
  Error,
  WebError,
};

export default Web;
