import { AsyncAction } from './index';

import { API } from './types';
import { parseAPIBundle } from './utils';

interface Props {
  id: string;
}

export const loadBundle: AsyncAction = ({ id }: Props) => async (
  dispatch,
  getState,
  { serverFetch, config },
) => {
  dispatch({
    type: 'LOAD_BUNDLE_START',
    payload: null,
  });

  // @todo use a URL object instead of string coercion
  // @todo add the status checking and parsing to server fetch
  const response = await serverFetch(`/api/bundles/${id}`);

  if (!response.ok) {
    return dispatch({
      type: 'LOAD_BUNDLE_ERROR',
      payload: null,
    });
  }

  try {
    const { bundle: rawBundle }: { bundle: API.Bundle } = await response.json();

    const bundle = parseAPIBundle(rawBundle, config);

    return dispatch({
      type: 'LOAD_BUNDLE_COMPLETE',
      payload: {
        bundle,
      },
    });
  } catch (e) {
    return dispatch({
      type: 'LOAD_BUNDLE_ERROR',
      payload: {
        error: e,
      },
    });
  }
};

export default loadBundle;
