import React, { useEffect, useMemo, useCallback } from 'react';
import {
  Page,
  SkeletonBodyText,
  Card,
  FormLayout,
  Button,
} from '@shopify/polaris';
import { RouteComponentProps, navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { TextField } from '@satel/formik-polaris';
import { useFormik, FormikProvider, Form } from 'formik';
import { object, string } from 'yup';

const VERIFY_STATUS = gql`
  query VerifyStatus {
    bundlesVerifyStatus {
      running
    }
  }
`;

const VERIFY_START = gql`
  mutation VerifyStart($email: String) {
    bundlesVerifyStart(email: $email) {
      running
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BundlesVerify(props: RouteComponentProps) {
  // Hook into old redux
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'DONE_LOADING',
    });
  });

  const { data } = useQuery(VERIFY_STATUS, {
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });
  const [start, { loading }] = useMutation(VERIFY_START);
  const running = data?.bundlesVerifyStatus.running || loading;

  const handleSubmit = useCallback(
    async (values: any) => {
      await start({
        variables: {
          email: values.email,
        },
      });
    },
    [start],
  );

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: handleSubmit,
    validationSchema: object({
      email: string()
        .label('Email')
        .email()
        .required(),
    }),
  });

  const markup = useMemo(() => {
    if (!data) {
      return <SkeletonBodyText lines={2} />;
    }

    if (!formik.isSubmitting) {
      if (running) {
        return (
          <Card title="Bundle verification started" sectioned>
            Verification is running and should complete in a few minutes. Once
            done, the results will be emailed to {formik.values.email}
          </Card>
        );
      }

      if (!running && formik.submitCount > 0) {
        return (
          <Card
            title="Bundle verification completed"
            sectioned
            secondaryFooterActions={[
              { content: 'Run again', onAction: () => formik.resetForm() },
            ]}
          >
            The results have been emailed to {formik.values.email}
          </Card>
        );
      }
    }

    return (
      <Card title="Verify bundles" sectioned>
        <FormLayout>
          <TextField name="email" type="email" label="Email" />
          <Button submit primary loading={formik.isSubmitting}>
            Start
          </Button>
        </FormLayout>
      </Card>
    );
  }, [data, formik, running]);

  return (
    <Page
      title="Verify bundles"
      breadcrumbs={[
        { onAction: () => navigate('/bundles'), content: 'Bundles' },
      ]}
    >
      <FormikProvider value={formik}>
        <Form>{markup}</Form>
      </FormikProvider>
    </Page>
  );
}

export default BundlesVerify;
