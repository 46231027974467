import React from 'react';
import { DatePicker, Button } from '@shopify/polaris';
import { MobileCancelMajorMonotone } from '@shopify/polaris-icons';
import { getMonth, getYear } from 'date-fns';

interface Props {
  date: number | undefined;
  onDateUpdate: (date: number | undefined) => void;
  onCancel: () => void;
}

function DateCellPicker(props: Props) {
  const { date, onDateUpdate, onCancel } = props;
  const month = getMonth(date || Date.now());
  const year = getYear(date || Date.now());

  const [editingDate, setEditingDate] = React.useState(date);
  const [selectedMonth, setSelectedMonth] = React.useState(month);
  const [newYear, setNewYear] = React.useState(year);

  const handleMonthChange = (monthVal, yearVal) => {
    setSelectedMonth(monthVal);
    setNewYear(yearVal);
  };

  return (
    <div className="date-picker-cal">
      <Button
        onClick={() => onCancel()}
        icon={MobileCancelMajorMonotone}
        outline
      />
      <DatePicker
        month={selectedMonth}
        year={newYear}
        onChange={val => {
          const ms = val.start.getTime();
          onDateUpdate(ms);
          setEditingDate(ms);
        }}
        onMonthChange={handleMonthChange}
        selected={new Date(editingDate)}
      />
    </div>
  );
}

export default DateCellPicker;
