import React from 'react';
import { Stack, Button, Tag, DisplayText } from '@shopify/polaris';

import ModalAddMeta from './Modal/ModalAddMeta';

import styles from './Fulfillment.module.css';

export interface FieldSelectionOption {
  id: string;
  title: string;
  selected: boolean;
}

interface FieldSelectionHeaderProps {
  loading: boolean;
  fieldOptions: FieldSelectionOption[];
  onUpdateSelection: (fields: FieldSelectionOption[]) => void;
}

function FieldSelectionHeader(props: FieldSelectionHeaderProps) {
  const { loading, fieldOptions = [], onUpdateSelection } = props;

  const [selectingFields, setSelectingFields] = React.useState(false);

  const selectedFields = fieldOptions.filter(({ selected }) => selected);

  const title = selectedFields.length
    ? 'Current Metafields to Edit'
    : 'Select a Metafield to Edit';

  const handleDeselectField = (id: string) => () => {
    const newFields = fieldOptions.map(field => {
      if (field.id === id) {
        return {
          ...field,
          selected: false,
        };
      }
      return field;
    });

    onUpdateSelection(newFields);
  };

  const tagsMarkup = selectedFields.map(field => (
    <div key={field.id} className={styles.tagSpacing}>
      <Tag disabled={loading} onRemove={handleDeselectField(field.id)}>
        {field.title}
      </Tag>
    </div>
  ));

  function handleUpdatingSelectedFields(selectedIds: string[]) {
    const newFields = fieldOptions.map(fieldOption => ({
      ...fieldOption,
      selected: selectedIds.includes(fieldOption.id),
    }));

    onUpdateSelection(newFields);
    setSelectingFields(false);
  }

  return (
    <div className={styles.headerSpacing}>
      <Stack distribution="equalSpacing">
        <Stack.Item>
          <DisplayText size="small">{title}</DisplayText>
          {tagsMarkup}
        </Stack.Item>
        <Stack.Item>
          <Button
            primary
            disabled={loading}
            onClick={() => setSelectingFields(true)}
          >
            Add Fields
          </Button>
        </Stack.Item>
      </Stack>
      {/* @todo likely refactor the modal to use the new data structure */}
      <ModalAddMeta
        open={selectingFields}
        options={fieldOptions.map(field => ({
          value: field.id,
          label: field.title,
        }))}
        selected={selectedFields.map(({ id }) => id)}
        onCancel={() => setSelectingFields(false)}
        onSelect={handleUpdatingSelectedFields}
      />
    </div>
  );
}

export default FieldSelectionHeader;
