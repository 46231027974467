import React, { useState, useContext, useCallback, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { Page } from '@shopify/polaris';
import { StatusContext } from '../../Context';
import { UserCreateInput, User } from '../../Graph/generatedTypes';
import { userCreate } from '../../Data';
import UserForm from '../../Components/UserForm';
import { useAppBridge } from '@shopify/app-bridge-react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function UserNew(props: RouteComponentProps) {
  const { showToast } = useContext(StatusContext);

  const [form, setForm] = useState({ submit: () => {} });
  const [dirty, setDirty] = useState(false);

  const shopify = useAppBridge();
  useEffect(() => {
    shopify.loading(false);
  },[shopify]);

  const handleSubmit = useCallback(
    (user: UserCreateInput) => {
      userCreate(user)
        .then(({ id }: User) => {
          showToast('User created');
          navigate(`/admin/users/${id}`);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.warn(e);
          showToast('Failed to create user', true);
        });
    },
    [showToast],
  );

  return (
    <Page
      title="New User"
      primaryAction={{
        content: 'Create',
        disabled: !dirty,
        onAction: form.submit,
      }}
      breadcrumbs={[
        {
          onAction: () => navigate('/admin/users'),
          content: 'Users',
        },
      ]}
    >
      <UserForm
        onSubmit={handleSubmit}
        onDirtyUpdate={setDirty}
        submit={submit => setForm({ submit })}
      />
    </Page>
  );
}

export default UserNew;
